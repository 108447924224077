<template>
<div v-if="Access('ProductsAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <DialogDisabler v-if="Loading"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Product -------------------------------------- -->
      <div class="CookiesRow FlexBreaker">
        <div class="CookiesRow D-Flex" style="width: 65%;">
          <input v-model="Name" :CookiesInvalidInput="!Name" :class="(ErrorsVisibilty & !Name) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="Name" :placeholder="Translate('Product Name')" autocomplete="off">
          <input v-model="Code" :CookiesInvalidInput="!Code" :class="(ErrorsVisibilty & !Code) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="Code" :placeholder="Translate('Code')" autocomplete="off">
          <CookiesDropInputObject :CookiesInvalidInput="MEMRead('ProductCategory') === ''" :Default="DefaultCategory" :ErrorData="ErrorsVisibilty && MEMRead('ProductCategory') === ''" @changed="CategorySelected" @selected="CategorySelected" :Res="'ProductCategory'" :Name="Translate('Category')" :Selectors="['Name']" :Options="Categories"></CookiesDropInputObject>
        </div>
        <div class="CookiesRow W-25 D-Flex ProductImageHolder">
          <label class="ProductImage M-Auto" :style="'background-image: url(' + DefaultImageHandler(SelectedProductImage, DefaultImage) + ');'">
            <input @change="ProductImageChanged()" name="ProductImage" type="file" accept="image/*">
          </label>
        </div>
        <div class="CookiesRowSplitter"/>
        <div :CookiesInvalidInput="!MEMRead('ProductPreOrder')" :class="(ErrorsVisibilty & !MEMRead('ProductPreOrder')) && 'ErrorBorder'" class="EvenContainer Rad2 FlexBreaker W-100">
          <div style="margin: 0.5em 1em;">
            <div class="W-100 Pad10" style="text-align: start;">
              <CookiesRadioButton :Value="'Manufacturer'" :Res="'ProductPreOrder'" :Name="Translate('To be ordered from manufacturer')"></CookiesRadioButton>
            </div>
            <div class="CookiesRow D-Flex F1em" style="min-width: 65%;">
              <CookiesDropInputObject :CookiesInvalidInput="MEMRead('ProductPreOrder') === 'Manufacturer' && MEMRead('ProductManufacturer') === ''" :ErrorData="ErrorsVisibilty && MEMRead('ProductPreOrder') === 'Manufacturer' && MEMRead('ProductManufacturer') === ''" @selected="ManufacturerSelected" :Disabled="MEMRead('ProductPreOrder') !== 'Manufacturer'" :RowHeight="'3em'" :Res="'ProductManufacturer'" :Name="Translate('The Manufacturer')" :Selectors="[['Name'], ['PhoneNumber']]" :Options="Manufacturers"></CookiesDropInputObject>
            </div>
          </div>
          <div style="margin: 0.5em 1em;">
            <div class="W-100 Pad10" style="text-align: start;">
              <CookiesRadioButton :Value="'Supplier'" :Res="'ProductPreOrder'" :Name="Translate('From supplier')"></CookiesRadioButton>
            </div>
            <div class="CookiesRow D-Flex F1em" style="min-width: 65%;">
              <CookiesDropInputObject :CookiesInvalidInput="MEMRead('ProductPreOrder') === 'Supplier' && MEMRead('ProductSupplier') === ''" :ErrorData="ErrorsVisibilty && MEMRead('ProductPreOrder') === 'Supplier' && MEMRead('ProductSupplier') === ''" @selected="SupplierSelected" :Disabled="MEMRead('ProductPreOrder') !== 'Supplier'" :RowHeight="'3em'" :Res="'ProductSupplier'" :Name="Translate('The Supplier (Optional)')" :Selectors="[['Name'], ['PhoneNumber']]" :Options="Suppliers"></CookiesDropInputObject>
            </div>
          </div>
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="W-100">
        <div class="Pad10">{{Translate('Types')}}</div>
        <CookiesTable @DeleteRow="DeleteUnitCatalog" class="Shadow0" :Header="false" :PrintControls="false" :Footer="false" :Fields="UnitCatalogsFields" :Data="UnitCatalogs"></CookiesTable>
        <div class="CookiesRow Pad10"><div :CookiesInvalidInput="UnitCatalogs.length === 0" :class="(ErrorsVisibilty & UnitCatalogs.length === 0) && 'ErrorBorder'" @click="NewUnitCatalog()" class="D-Flex M-Auto CookiesButton BG8 Shadow0 HoverUp Transition0_25 Rad2 OutLineTrans"><span class="color2" style="margin-top: auto; margin-bottom: auto;">{{Translate('Add new option')}}</span><CookiesIcon style="width: 2em; height: 2em;" :ID="'Add'"></CookiesIcon></div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('ProductsAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
    <button @click="SaveProduct()" :style="'background: ' + Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Save')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpGender = {}
  },
  created () {},
  data () {
    return {
      Editing: false,
      OriginalData: {},
      Name: '',
      Code: '',
      ErrorsVisibilty: false,
      selectedFile: '',
      SelectedProductImage: '',
      ProductImage: '',
      Supplier: [],
      Manufacturer: [],
      Category: '',
      DefaultCategory: '',
      DefaultManufacturer: '',
      DefaultSupplier: '',
      Manufacturers: [
        {
          ID: 1,
          Name: 'Al naseem factory',
          PhoneNumber: '0797810807',
          Email: 'Alnaseem@email.com',
          CanLogIn: 'true',
          LogInUser: 'Alnaseem@email.com',
          AddressL1: 'String',
          AddressL2: 'String',
          NotificationMethod: 'NotificationMethode',
          imgUrl: 'string',
          ProfileID: 551
        },
        {
          ID: 2,
          Name: 'factory 101',
          PhoneNumber: '0798531221',
          Email: 'factory@mail.com',
          CanLogIn: 'false',
          LogInUser: 'factory@mail.com',
          AddressL1: 'String',
          AddressL2: 'String',
          NotificationMethod: 'NotificationMethode',
          imgUrl: 'string',
          ProfileID: 324
        }
      ],
      Suppliers: [
        {
          ID: 1,
          Name: 'MR Online',
          PhoneNumber: '0797810807',
          Email: 'Online@email.com',
          CanLogIn: 'true',
          LogInUser: 'Online@email.com',
          AddressL1: 'String',
          AddressL2: 'String',
          NotificationMethod: 'NotificationMethode',
          imgUrl: 'string',
          ProfileID: 551
        },
        {
          ID: 2,
          Name: 'No one',
          PhoneNumber: '0798531221',
          Email: 'NoOne@mail.com',
          CanLogIn: 'false',
          LogInUser: 'NoOne@mail.com',
          AddressL1: 'String',
          AddressL2: 'String',
          NotificationMethod: 'NotificationMethode',
          imgUrl: 'string',
          ProfileID: 324
        }
      ],
      Categories: [
        {
          ID: 1,
          Name: 'Bed Rooms'
        },
        {
          ID: 2,
          Name: 'Bath Room'
        },
        {
          ID: 3,
          Name: 'Living Room'
        }
      ],
      UnitCatalogs: [],
      Saving: false,
      Loading: false,
      UnitsCatolgsCount: 1
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    if (!this.Access('ProductsAdd')) {
      return
    }
    this.Loading = true
    // this.ReadCategories()
    this.LoadEditingData()
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    LoadEditingData () {
      if (this.DialogData.Editing) {
        this.Editing = true
        this.OriginalData = this.DeepCloner(this.DialogData.Data)
        var DataImage = this.DeepCloner(this.DialogData.Data)
        this.OriginalData = {
          ID: DataImage.ID,
          Code: DataImage.Code,
          Name: DataImage.Name,
          ImgUrl: DataImage.ImgUrl,
          IsPreOrder: DataImage.IsPreOrder,
          CategoryID: DataImage.CategoryID,
          ManufacturerID: DataImage.ManufacturerID,
          SupplierID: DataImage.SupplierID,
          Units: []
        }
        DataImage.Units.forEach(unit => {
          this.OriginalData.Units.push({
            ID: unit.ID,
            Name: unit.Name,
            Code: unit.Code,
            Price: unit.Price,
            Cost: unit.Cost
          })
        })
        this.SelectedProductImage = this.DeepFetcher(this.OriginalData, 'ImgUrl')
        this.Name = this.DeepFetcher(this.OriginalData, 'Name')
        this.Code = this.DeepFetcher(this.OriginalData, 'Code')
        // reading manufacturer / supplier
        if (this.OriginalData.IsPreOrder) {
          this.MEMWrite('ProductPreOrder', 'Manufacturer')
          var LoadedManufacturer = this.ObjectFinder(this.Manufacturers, 'ID', this.DeepFetcher(this.OriginalData, ['ManufacturerID']))
          this.MEMWrite('ProductManufacturer_Value', LoadedManufacturer)
        } else {
          this.MEMWrite('ProductPreOrder', 'Supplier')
          var LoadedSupplier = this.ObjectFinder(this.Suppliers, 'ID', this.DeepFetcher(this.OriginalData, ['SupplierID']))
          this.MEMWrite('ProductSupplier_Value', LoadedSupplier)
        }
        // reading Category
        var LoadedCategory = this.ObjectFinder(this.Categories, 'ID', this.DeepFetcher(this.OriginalData, ['CategoryID']))
        this.MEMWrite('ProductCategory_Value', LoadedCategory)
        // reading Unit Catalogs
        try {
          this.OriginalData.Units.forEach(unit => {
            this.UnitCatalogs.push({
              ID: unit.ID,
              Name: unit.Name,
              Code: unit.Code,
              index: this.UnitsCatolgsCount,
              Price: unit.Price,
              Cost: unit.Cost,
              TypeRes: 'Type_' + this.UnitsCatolgsCount,
              CodeRes: 'Code_' + this.UnitsCatolgsCount,
              CostRes: 'Cost_' + this.UnitsCatolgsCount,
              PriceRes: 'Price_' + this.UnitsCatolgsCount
            })
            this.MEMWrite('Type_' + this.UnitsCatolgsCount, unit.Name)
            this.MEMWrite('Code_' + this.UnitsCatolgsCount, unit.Code)
            this.MEMWrite('Cost_' + this.UnitsCatolgsCount, unit.Cost)
            this.MEMWrite('Price_' + this.UnitsCatolgsCount, unit.Price)
            this.UnitsCatolgsCount += 1
          })
        } catch (error) {
        }
        this.Loading = false
      } else {
        this.MEMWrite('ProductPreOrder', '')
        this.Loading = false
      }
    },
    ReadCategories () {
      this.Get('Category').then(response => {
        this.Categories = response
        this.ReadManufacturers()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadManufacturers () {
      this.Get('Manufacturer').then(response => {
        this.Manufacturers = response
        this.ReadSuppliers()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Manufacturers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSuppliers () {
      this.Get('Supplier').then(response => {
        this.Suppliers = response
        this.LoadEditingData()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Suppliers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveProduct () {
      const _this = this
      if (this.Requirments(this).Incomplete) { // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      // image handling
      if (!this.selectedFile || this.selectedFile === null || this.selectedFile === undefined) {
        PostNow()
      } else {
        this.PostImage(this.selectedFile).then(response => {
          this.SelectedProductImage = response
          PostNow()
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Uploading Image'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      // Post // Patch
      function PostNow () {
        let Method = 'POST'
        let API = 'Product'
        let Data = _this.ProductData
        let SavingError = 'Error in Adding New Product'
        if (_this.Editing) {
          Method = 'PATCH'
          API = 'Product/Edit'
          Data = _this.CookiesPatcher(_this.OriginalData, _this.ProductData)
          SavingError = 'Error in Saving Product'
          if (Data.length === 0) {
            _this.Saving = false
            _this.$router.go(-1)
            return
          }
        }
        _this.Post(Method, API, Data).then(response => {
          _this.Saving = false
          _this.CloseDialog(_this.DialogIndex)
        }, error => {
          _this.Saving = false
          error.CookiesError = SavingError
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    ProductImageChanged () {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        this.SelectedProductImage = ''
        return
      }
      this.SelectedProductImage = URL.createObjectURL(file)
    },
    DeleteUnitCatalog (UnitCatalog) {
      this.UnitCatalogs.forEach(unit => {
        unit.Name = this.MEMRead(unit.TypeRes)
        unit.Code = this.MEMRead(unit.CodeRes)
      })
      var ToBeDeleted = null
      this.UnitCatalogs.forEach((element, i) => {
        if (element.index === UnitCatalog.index) {
          ToBeDeleted = i
        }
      })
      if (ToBeDeleted !== null) {
        this.UnitCatalogs.splice(ToBeDeleted, 1)
      }
    },
    NewUnitCatalog () {
      this.UnitCatalogs.push({
        Name: this.Translate('Type') + '_' + this.UnitsCatolgsCount,
        Code: '-',
        index: this.UnitsCatolgsCount,
        TypeRes: 'Type_' + this.UnitsCatolgsCount,
        CodeRes: 'Code_' + this.UnitsCatolgsCount,
        CostRes: 'Cost_' + this.UnitsCatolgsCount,
        PriceRes: 'Price_' + this.UnitsCatolgsCount
      })
      this.UnitsCatolgsCount += 1
    },
    CategorySelected (Category) {
      this.Category = Category
    },
    ManufacturerSelected (Manufacturer) {
      this.Console('Manufacturer')
      this.Console(Manufacturer)
      this.Manufacturer = Manufacturer
    },
    SupplierSelected (Supplier) {
      this.Supplier = Supplier
    }
  },
  computed: {
    ProductData () {
      var CurrentData = {
        ID: this.OriginalData.ID,
        Code: this.Code,
        Name: this.Name,
        ImgUrl: this.SelectedProductImage,
        IsPreOrder: this.MEMRead('ProductPreOrder') === 'Manufacturer',
        Units: []
      }
      if (this.Category !== '') { CurrentData.CategoryID = this.DeepFetcher(this.Category, 'ID') }
      if (CurrentData.IsPreOrder) { CurrentData.ManufacturerID = this.DeepFetcher(this.Manufacturer, 'ID') }
      if (!CurrentData.IsPreOrder) { CurrentData.SupplierID = this.DeepFetcher(this.Supplier, 'ID') }
      this.UnitCatalogs.forEach(unit => {
        CurrentData.Units.push({
          ID: unit.ID,
          Name: this.MEMRead(unit.TypeRes),
          Code: this.MEMRead(unit.CodeRes),
          Price: this.MEMRead(unit.PriceRes),
          Cost: this.MEMRead(unit.CostRes)
        })
      })
      return CurrentData
    },
    UnitCatalogsFields () {
      var Fields = []
      Fields.push({ type: 'index' })
      Fields.push({ type: 'editableText', name: this.Translate('Type'), value: ['Name'], res: ['TypeRes'] })
      Fields.push({ type: 'editableText', name: this.Translate('Code'), value: ['Code'], res: ['CodeRes'] })
      Fields.push({ name: this.Translate('Price'), value: ['Price'], type: 'numberInput', default: 'Price', min: 0, res: ['PriceRes'], InputUnit: this.PriceUnit })
      Fields.push({ name: this.Translate('Cost'), value: ['Cost'], type: 'numberInput', default: 'Cost', min: 0, res: ['CostRes'], InputUnit: this.PriceUnit })
      Fields.push({ type: 'delete', value: ['index'] })
      return Fields
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 750px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .ProductImageHolder{
    order: -1;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

.ProductImage{
  width: 10em;
  aspect-ratio: 1;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: var(--rad1);
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.ProductImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
