import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17b67a78"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "M-Auto DialogContainer" }
const _hoisted_3 = { class: "DialogBody D-Flex" }
const _hoisted_4 = { class: "CookiesRow F-C2" }
const _hoisted_5 = { class: "M-Auto F-Bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogDisabler = _resolveComponent("DialogDisabler")!
  const _component_CookiesNormalInput = _resolveComponent("CookiesNormalInput")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'CookiesDialog_' + _ctx.DialogIndex,
    class: "CookiesDialogdBody"
  }, [
    (_ctx.Saving)
      ? (_openBlock(), _createBlock(_component_DialogDisabler, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CookiesNormalInput, { Res: 'D_Inventory_Transfer_InvoiceNO' }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
              style: _normalizeStyle('background: ' + _ctx.Theme.Shade3),
              class: "F-Bold CookiesButton DialogFooterButton"
            }, _toDisplayString(_ctx.Translate('Cancel')), 5),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Confirm())),
              class: "F-Bold CookiesButton DialogFooterButton BGMain"
            }, _toDisplayString(_ctx.Translate('Save')), 1)
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}