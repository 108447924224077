import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args))),
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6770.983112315448!2d35.83903059603272!3d31.947558767677773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ca1c13188aa83%3A0xce6b853227f3cb0e!2z2YXYt9i52YUg2LTZh9ix2LLYp9ivINin2YTYqNmK2KfYr9ix!5e0!3m2!1sen!2sjo!4v1670643100378!5m2!1sen!2sjo",
    class: "CookiesMap",
    frameborder: "0",
    style: {"border":"0"},
    allowfullscreen: "",
    "aria-hidden": "false",
    tabindex: "0"
  }, null, 32))
}