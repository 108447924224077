import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "D-Flex M-Auto H-100 W-100 P-Abs",
  style: {"justify-content":"center","align-items":"center","min-width":"100%","z-index":"700"}
}
const _hoisted_2 = {
  class: "BGShade1 Shadow1 D-Flex M-Auto W-100",
  style: {"min-height":"2.7em"}
}
const _hoisted_3 = { class: "M-Auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CookiesFunctionalTable, {
      onViewInvoice: _ctx.ViewInvoice,
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, null, 8, ["onViewInvoice", "Loading", "CookiesData", "TableSettings"])
  ], 64))
}