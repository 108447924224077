
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Res: { type: String },
    ErrorsVisibilty: { default: false, type: Boolean },
    Editing: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean }
  },
  emits: ['ChangePassword']
})
