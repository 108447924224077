
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as CatalogItem,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      OriginalConfig: {} as any
    }
  },
  methods: {
    ReadConfigData () {
      this.Get('Config').then(response => {
        if (response === null) {
          return
        }
        response.forEach((element :any) => {
          this.OriginalConfig[element.Name] = element.Value
          this.OriginalConfig[element.Name + 'ID'] = element.ID
          if (element.Name === 'CategoriesImages') {
            this.OriginalConfig[element.Name] = JSON.parse(element.Value)
          }
        })
      }, error => {
        error.CookiesError = 'Error in reading Cookies Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditItem (attr :string, val :any) {
      if (val === undefined) {
        val = {}
      }
      var GetItem = this.OriginalConfig[attr]
      if (GetItem === null || GetItem === undefined) {
        this.SaveItem(attr, val)
        return
      }
      var ClonedItem = this.DeepCloner(GetItem)
      var OriginalItem = {
        Value: ClonedItem
      }
      var NewItem = {
        Value: JSON.stringify(val)
      }
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      // if (Patch.length === 0) {
      //   return
      // }
      this.Post('PATCH', 'Config', Patch, '?id=' + this.OriginalConfig[attr + 'ID']).then(response => {
        this.$router.go(-1)
      }, error => {
        error.CookiesError = 'Error in Editing Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    UploadImage (index :number, category :any) {
      this.Saving = true
      this.PostImage(this.MEMRead('category_' + index), 'catalogs').then((response :any) => {
        // this.MEMWrite('category_' + index, response)
        if (this.$store.state.CookiesConfig.CategoriesImages === undefined) {
          this.$store.state.CookiesConfig.CategoriesImages = {}
        }
        this.$store.state.CookiesConfig.CategoriesImages[category.Name] = response
        this.Saving = false
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveItem (attr :string, val :any) {
      var newItem = {
        Name: attr,
        Value: JSON.stringify(val),
        updatedBy: this.$store.state.UserManagment.CurrentUser.Email ? this.$store.state.UserManagment.CurrentUser.Email : ''
      }
      this.Post('POST', 'Config', newItem).then(() => {
        this.$router.go(-1)
      }, error => {
        error.CookiesError = 'Error in Saving Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    this.ReadConfigData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_CatalogItem')
  },
  computed: {
    CategoriesImagesAray () {
      var ComputedArray = [] as Array<any>
      this.DialogData.Data.forEach((category :any) => {
        var AddedCat = category
        AddedCat.ImgUrl = ''
        if (this.$store.state.CookiesConfig.CategoriesImages && this.$store.state.CookiesConfig.CategoriesImages[category.Name]) {
          AddedCat.ImgUrl = this.$store.state.CookiesConfig.CategoriesImages[category.Name]
        }
        ComputedArray.push(AddedCat)
      })
      return ComputedArray
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "78144b0b": (_ctx.Theme.Light1),
  "7431297c": (_ctx.Theme.BG1),
  "73204df9": (_ctx.Theme.BGDimmed),
  "7431297d": (_ctx.Theme.BG2),
  "74312981": (_ctx.Theme.BG6),
  "7431297f": (_ctx.Theme.BG4),
  "64065f7c": (_ctx.Theme.UserColors.main)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__