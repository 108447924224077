

import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  created () {
    this.ReadCookiesData()
    this.SelectedCatalogItems = this.LocalRead('CookiesCatalog_' + this.$route.params.CatalogID)
  },
  data () {
    return {
      CatalogID: null,
      Loading: ['Loading'] as Array<string>,
      CookiesData: [] as Array<Catalog>,
      CatalogAvailable: false,
      ErrorText: 'Cannot Find Catalog',
      SelectedCatalogItems: [] as Array<CatalogItem>,
      ViewType: 'GRID' as ViewType,
      OnlySelected: false
    }
  },
  computed: {
    FilteredItems () {
      if (this.OnlySelected) {
        return this.DeepFetcher(this.CookiesData, 'Items').filter((Item :CatalogItem) => {
          return this.SelectedCatalogItems.some((SelectedItem) => {
            return Item.ID === SelectedItem.ID
          })
        })
      } else {
        return this.DeepFetcher(this.CookiesData, 'Items')
      }
    },
    DesignLayout () {
      var Design :any = {
        LogoSize: '',
        Padding: '0px'
      }
      var Res = 'CatalogsMainView_'
      var LogoSize = this.MEMRead('CatalogsMainView_LogoSize') + '%'
      var Padding = this.MEMRead('CatalogsMainView_Padding') !== undefined ? this.MEMRead('CatalogsMainView_Padding') / 100 * 6 + 'vw' : '4vw'
      // var background = 'url("https://www.google.jo/images/branding/googlelogo/1x/googlelogo_light_color_272x92dp.png")'
      var Radius = this.MEMRead('CatalogsMainView_Radius') !== undefined ? this.MEMRead('CatalogsMainView_Radius') / 10 * 4 + 'em' : '1em'
      var ValueMapper = {
        Top: 'top',
        Bottom: 'bottom',
        Left: 'left',
        Right: 'right',
        PTop: 'paddingTop',
        PBottom: 'paddingTop',
        PLeft: 'paddingTop',
        PRight: 'paddingTop',
        MTop: 'marginTop',
        MBottom: 'marginTop',
        MLeft: 'marginTop',
        MRight: 'marginTop',
        Width: 'width',
        Height: 'height',
        FontSize: 'FontSize',
        BGColor: 'backgroundColor',
        BGImage: 'backgroundImage'
      } as any
      Object.keys(ValueMapper).forEach((value: any, index, array) => {
        if (this.MEMRead(`Res_${value}`)) Design[value] = this.MEMRead(`Res_${ValueMapper[value]}`)
      })
      return {
        Padding: Padding,
        Radius: Radius,
        LogoSize: LogoSize
      }
      function MapValue (attr :keyof typeof ValueMapper) {
        return ValueMapper[attr] ? ValueMapper[attr] : attr
      }
    },
    BakedLogo () {
      return this.ImageToObjURL(this.MEMRead('CatalogsMainView_Logo'))
    },
    AdminPanelSettings () {
      return [
        {
          Title: 'Background',
          ImageSelectors: [
            {
              Res: 'BGImage',
              Default: ''
            }
          ]
        },
        {
          Title: 'Logo',
          ImageSelectors: [
            {
              Res: 'CatalogsMainView_Logo'
            }
          ],
          Sliders: [
            {
              Res: 'CatalogsMainView_LogoSize',
              Min: 50,
              Max: 100,
              Default: 75,
              Name: 'Size',
              Visible: this.MEMRead('CatalogsMainView_Logo') !== undefined && this.MEMRead('CatalogsMainView_Logo') !== ''
            }
          ]
        },
        {
          Sliders: [{ Min: 0, Max: 10, Default: 0, Res: 'CatalogsMainView_Radius', Name: 'Curves' }]
        },
        {
          Title: 'Name',
          TextInputs: [
            {
              Res: 'CatalogsMainView_Title',
              Name: 'Name'
            },
            {
              Res: 'CatalogsMainView_Description',
              Name: 'Subtitle'
            }
          ],
          RadioButtons: [
            {
              Default: true,
              Name: 'Design',
              Value: 'Design',
              Res: 'CatalogsMainView_Description'
            },
            {
              Name: 'csc',
              Value: 'cssxc',
              Res: 'CatalogsMainView_Description'
            },
            {
              Name: 'csc',
              Value: 'cssxxc',
              Res: 'CatalogsMainView_Description'
            }
          ],
          Sliders: [
            {
              Res: 'CatalogsMainView_Padding',
              Min: 50,
              Max: 100,
              Default: 75,
              Name: 'Size',
              Visible: this.MEMRead('CatalogsMainView_Logo') !== undefined && this.MEMRead('CatalogsMainView_Logo') !== ''
            }
          ]
        }
      ] as Array<AdminPanelFields>
    },
    BGDesign () {
      return {
        backgroundImage: `url(${this.MEMRead('BGImage_ObjectURL')})`
      }
    }
  },
  methods: {
    ReadCatalogs () {
      this.Get('Catalogs').then(response => {
        this.CookiesData = response.filter(function (catalog :Catalog) { // handle multiple prop for multi selector strings into arrays arrays |||| [['selector1'], ['selector2a', 'selector2b']] => [['selector1'], ['selector2a', 'selector2b']]
          return !catalog.IsHidden
        })
        this.Console(response)
        this.CatalogAvailable = true
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.CatalogAvailable = false
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    IsItemSelected (ItemID: string) {
      return this.SelectedCatalogItems.some(item => {
        return item.ID === ItemID
      })
    },
    CatalogItemSelected (Item :CatalogItem) {
      this.SelectedCatalogItems.push(Item)
      this.LocalSave('CookiesCatalog_' + this.$route.params.CatalogID, this.SelectedCatalogItems)
    },
    CatalogItemDeSelected (Item :CatalogItem) {
      this.SelectedCatalogItems = this.SelectedCatalogItems.filter(item => {
        return item.ID !== Item.ID
      })
      if (this.SelectedCatalogItems.length === 0) {
        this.OnlySelected = false
      }
    },
    ReadCookiesData () {
      this.ReadCatalogs()
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewUser':
          this.OpenDialog('Security_User_Dialog', this.Translate('New User'), {})
          break
      }
    },
    ImageBaker (Image: any) {
      if (typeof Image === 'string') return Image
      if (Image === undefined) return ''
      return this.ImageToObjURL(Image)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // this.ReadCookiesData()
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cb539f64": (_ctx.DesignLayout.Padding),
  "ef70809e": (_ctx.DesignLayout.background),
  "212a7cb5": (_ctx.DesignLayout.Radius)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__