<template>
  <div class="BigBody">
    <div class="TableBodyContainer">
      <div class=" CookiesRow">
        <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
          <tr>
            <th></th>
            <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('Area Name', 'اسم المنطقة')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
            <CookiesSortingTH @click="SortTable(['Fees'])" :Name="LanguageSelector('Delivery Fees', 'عمولة التوصيل')" :Value="['Fees']" :sortColumn="sortElment" ></CookiesSortingTH>
            <CookiesSortingTH @click="SortTable(['DriverExtraFees'])" :Name="LanguageSelector('Driver Fees', 'عمولة السائق')" :Value="['DriverExtraFees']" :sortColumn="sortElment" ></CookiesSortingTH>
            <th v-if="Access('AreasEdit')"></th>
          </tr>
          <tr v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Main Areas', 'لا يوجد مناطق رئيسية')}}</td></tr>
          <tr v-if="Loading"><td colspan="10" class="" style="padding: 1.5em 0.2em;"><div class="D-Flex W-100 H-100"><CookiesLoading class="M-Auto" style="place-content: center;"></CookiesLoading></div></td></tr>
          <tr v-for='(Area, Index) in FilterdTable' :key='(Area, Index)' class="CookiesGeneralTableRow">
            <td style="padding: 1em;">{{Index + 1}}</td>
            <td style="cursor: text; padding:0.2em; direction: ltr" @blur="ValueChanged(Area, 'Name')" :ref="Area['ID'] + '_Name'" class="RowNoClick" :class="Access('AreasEdit') ? 'EditableCell' : ''" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{Area['Name'] ? Area['Name']: ''}}</td>
            <td @keypress="OnlyNumbersKeyPress()" oncopy="return false" oncut="return false" onpaste="return false" style="cursor: text; direction: ltr;" @blur="ValueChanged(Area, 'Fees', true)" :ref="Area['ID'] + '_Fees'" class="RowNoClick" :class="Access('AreasEdit') ? 'EditableCell' : ''" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{Area['Fees'] ? Area['Fees']: 0}}</td>
            <td @keypress="OnlyNumbersKeyPress()" oncopy="return false" oncut="return false" onpaste="return false" style="cursor: text; direction: ltr;" @blur="ValueChanged(Area, 'DriverExtraFees', true)" :ref="Area['ID'] + '_DriverExtraFees'" class="RowNoClick" :class="Access('AreasEdit') ? 'EditableCell' : ''" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{Area['DriverExtraFees'] ? Area['DriverExtraFees']: 0}}</td>
            <td>
              <div class="D-Flex">
                <CookiesIcon class="M-Auto HoverUp" :ToolTip="LanguageSelector('Delete', 'حذف')" v-if="Access('AreasDelete')" @click="RemoveArea(Area)" style="width: 1.6em; height: 1.6em;" :ID="'Delete'"></CookiesIcon>
              </div>
            </td>
          </tr>
          <tr><td style="height:12em;"></td></tr> <!-- Last Item Drop Down Place Holder --------------------- -->
        </table> <!-- TABLE END --------------------- -->
      </div>
    </div>
</div>
</template>

<script scoped>
export default {
  mounted () {
    this.ReadAreas()
  },
  props: {
    Search: { default: '', Type: String }
  },
  data () {
    return {
      CookiesDataLines: [],
      CookiesDataAreas: [],
      sortElment: {
        name: ['Name'],
        value: 1
      },
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedTable.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // if (this.$store.state.CookiesMemory.Areas_Filter_Type !== item.Area_Type && this.$store.state.CookiesMemory.Areas_Filter_Type !== 'All') {
        //   FilterIt = false
        // }
        // try {
        //   if (this.$store.state.CookiesMemory.Areas_Filter_Page.Page_Name !== item.Area_Page.Page_Name && this.$store.state.CookiesMemory.Areas_Filter_Page.Page_Name !== 'All') {
        //     FilterIt = false
        //   }
        // } catch (error) {
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Reserved && item.Area_Status === 'Reserved') { // ////// Reserved
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Packaging && item.Area_Status === 'Packaging') { // ////// packaging
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Delivery && item.Area_Status === 'Delivery') { // ////// Delivery
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Delivered && item.Area_Status === 'Delivered') { // ////// Delivered
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Fixing && item.Area_Status === 'NotPaid') { // ////// Fixing
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Done && item.Area_Status === 'Done') { // ////// Done
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Area_Filter_Canceled && item.Area_Status === 'Canceled') { // ////// Done
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Area_Filter_PriceHigh && item.Area_Total_Ammount < this.$store.state.CookiesMemory.Area_Filter_PriceHigh_Ammount) {
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Area_Filter_PriceLow && item.Area_Total_Ammount > this.$store.state.CookiesMemory.Area_Filter_PriceLow_Ammount) {
        //   FilterIt = false
        // }
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedTable () {
      var Sorted = this.CookiesDataAreas
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadAreas () {
      this.Loading = true
      this.Get('Area').then(response => {
        this.CookiesDataAreas = response
        this.Loading = false
      }, error => {
        error.CookiesError = 'Error in reading Areas'
        this.Loading = false
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddArea () {
      this.OpenDialog('NewArea', this.LanguageSelector('New Area', 'منطقة جديدة'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesEditLines () {
      this.OpenDialog('EditLines', this.LanguageSelector('Edit Lines', 'الخطوط الموجودة'), this.CookiesDataLines) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesAddLine () {
      this.OpenDialog('NewLine', this.LanguageSelector('New Line', 'خط جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenArea (Area) {
      if (event.target.classList.length > 0) {
        if ((event.target.classList.includes('RowNoClick'))) {
          return
        }
      }
      this.OpenDialog('EditArea', 'Edit Area ' + Area.PlateNum, Area) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ValueChanged (Area, Attribute, Num = false) {
      if (!this.Access('AreasEdit')) {
        return
      }
      this.Console('ValueChanged', 'color: red; font-weight: 800; font-size: 2em')
      var value = this.$refs[Area.ID + '_' + Attribute].innerText
      if (Num) {
        value = parseFloat(value)
      }
      var originalArea = this.DeepCloner(Area)
      var NewArea = this.DeepCloner(Area)
      this.DeepChanger(NewArea, [Attribute], value)
      var Patch = this.CookiesPatcher(originalArea, NewArea)
      this.Post('PATCH', 'Area/EditArea?ID=' + Area.ID, Patch).then(response => {
        this.ReadAreas()
      }, error => {
        error.CookiesError = 'Error in updating Area Name'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveArea (Area) {
      this.OpenDialog('Delete_Dialog', 'Deleting Area', ['DeleteArea?ID=' + Area.ID, Area.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadAreas()
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 1rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  margin: auto;
  height: unset;
}
@media (max-width: 500px) {
  .BigHeader>>>*{
    font-size: 1.5em !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
.TableBodyContainer{
  width: 100%;
  overflow-y: unset;
  margin-top: 0em;
  min-width: 60vw;
  padding: 0;
}
td {
  padding: 0;
}
.CookiesGeneralTableRow td{
  padding: 0.8em 0;
}
</style>
