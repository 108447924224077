import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["D-Flex FlexWrap DateContainer P-Abs", _ctx.$route.meta.ReportsDateSelector || _ctx.ForceEnabled ? '': 'Disabled']),
    style: {"user-select":"none"}
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.Translate('From')), 1),
      _withDirectives(_createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.DateChanged(_ctx.$store.state.CurrentDate.From, _ctx.$store.state.CurrentDate.To))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.CurrentDate.From) = $event)),
        class: "CookiesInput color2 P_25em",
        type: "date"
      }, null, 544), [
        [_vModelText, _ctx.$store.state.CurrentDate.From]
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.Translate('To')), 1),
      _withDirectives(_createElementVNode("input", {
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.DateChanged(_ctx.$store.state.CurrentDate.From, _ctx.$store.state.CurrentDate.To))),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$store.state.CurrentDate.To) = $event)),
        class: "CookiesInput color2 P_25em",
        type: "date"
      }, null, 544), [
        [_vModelText, _ctx.$store.state.CurrentDate.To]
      ])
    ])
  ], 2))
}