
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ShowFilter: false,
      Search: ''
    }
  },
  computed: {
    Filters () :any {
      var AccountsFilter :FilterObject = {
        Lists: [
          {
            Name: 'ssss',
            Visible: true,
            Options: [{ name: 'khaled' }, { name: 'mohammed' }],
            Selectors: ['name'],
            Res: 'Manufacturersc',
            DataPath: ['Notes'],
            ListPath: ['name']
          }
        ],
        Switches: [
          {
            Name: this.Translate('Manufacturer = 2'),
            Visible: true,
            Icon: 'Factory',
            Res: 'FinanceFilter_Manufacturers2',
            Value: 2,
            DataPath: ['ManufacturerID']
          }
        ]
      }
      var InvoicesFilter :FilterObject = {
        Lists: [
          {
            Name: 'ssss',
            Visible: true,
            Options: [{ name: 'khaled' }, { name: 'mohammed' }],
            Selectors: ['name'],
            Res: 'Manufacturersc',
            DataPath: ['Notes'],
            ListPath: ['name']
          }
        ],
        Switches: [
          {
            Name: this.Translate('Manufacturer = 2'),
            Visible: true,
            Icon: 'Factory',
            Res: 'FinanceFilter_Manufacturers2',
            Value: 2,
            DataPath: ['ManufacturerID']
          }
        ]
      }
      var TransactionsFilter :FilterObject = {
        Lists: [
          {
            Name: 'ssss',
            Visible: true,
            Options: [{ name: 'khaled' }, { name: 'mohammed' }],
            Selectors: ['name'],
            Res: 'Manufacturersc',
            DataPath: ['Notes'],
            ListPath: ['name']
          }
        ],
        Switches: [
          {
            Name: this.Translate('Manufacturer = 2'),
            Visible: true,
            Icon: 'Factory',
            Res: 'FinanceFilter_Manufacturers2',
            Value: 2,
            DataPath: ['ManufacturerID']
          }
        ]
      }
      return {
        Accounts: AccountsFilter,
        Invoices: InvoicesFilter,
        Transactions: TransactionsFilter
      }
    },
    Tabs () {
      return [
        { Name: this.Translate('Accounts'), Link: 'Accounts' },
        { Name: this.Translate('Invoices'), Link: 'Invoices' },
        { Name: this.Translate('Transactions'), Link: 'Transactions' }
      ]
    }
  },
  methods: {
    NewInvoice () {
      if (!this.Access('Finance_Invoices_Create')) {
        return
      }
      this.OpenDialog('Finance_Invoice_View', this.Translate('New Invoice'), {}, { header: false }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  }
})
