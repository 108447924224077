import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca76c666"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["Tooltip"]
const _hoisted_2 = ["Key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "CookiesBar D-Flex",
    Tooltip: _ctx.Tooltip
  }, [
    (_ctx.Bar)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.Bar.Values, (val) => {
          return (_openBlock(), _createElementBlock("span", {
            Key: val,
            class: _normalizeClass(["BarColorsItems BGMain", (val.Value > _ctx.Bar.Max) ? 'BGRed' : '']),
            style: _normalizeStyle(['width:' + ((val.Value/ (_ctx.Bar.Max ? _ctx.Bar.Max: val.Value)) * 100) + '%', val.CustomStyle ? val.CustomStyle : ''])
          }, null, 14, _hoisted_2))
        }), 256))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}