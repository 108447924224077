<template>
<div v-if="Access('FinancialRead')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto DialogContainer">
    <div class="DialogBody color2">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Customer -------------------------------------- -->
      <div class="D-Flex W-100 FlexWrap">
        <div class="DefaultTextAlign W-25" style="padding: 3em; flex: 1;">
          <div class="W-100">
            <span class="color2">{{LanguageSelector('Customer: ', 'العميل: ')}}</span>
          </div>
          <div class="W-100">
            <span class="F1_25em F-Bold color2">{{DeepFetcher(TheUser, ['Company'])}}</span>
          </div>
          <div class="W-100">
            <span class="F1_25em F-Bold color2">{{DeepFetcher(TheUser, ['PhoneNumber'])}}</span>
          </div>
          <div class="CookiesRowSplitter"/>
          <div v-if="Access('CustomersEdit')" class="W-100">
            <button style="margin: 0.5em;" :class="(DeepFetcher(CookiesCashLedger, ['Credited']) === 0 & DeepFetcher(CookiesCashLedger, ['Debited']) === 0) ? 'Disabled' : ''" class="CookiesButton BGThird F-Bold" v-if="Access('Settlement')" @click="Settlement()"><span>{{LanguageSelector('Settlement', 'تسوية الحساب')}}</span><CookiesIcon :Color="'Black'" class="CookiesButtonIcon" :ID="'Settlement'"></CookiesIcon></button>
            <!-- <button style="margin: 0.5em;" class="CookiesButton BGThird F-Bold" v-if="Access('FinancialAdd')" @click="NewTrans()"><span>{{LanguageSelector('Add Transaction', 'حركة جديدة')}}</span></button> -->
          </div>
        </div>
        <div class="M-Auto W-75" style="flex: 2;">
          <div v-if="CookiesCashLedger" class="M-Auto D-Flex W-100" style="filter: drop-shadow(var(--shadow0)); border-radius: 0.5em;">
            <div class="W-100 BG2 D-Flex M-Auto" style="text-align: center; padding: 0.8em 1.5em; filter: drop-shadow(var(--shadow0)); height: 90%" :style="$store.getters.Direction === 'ltr' ? 'border-radius: 0.5em 0 0 0.5em;' : 'border-radius: 0 0.5em 0.5em 0;'">
              <div class="M-Auto O-85">
                <span style="">{{LanguageSelector('Balance In', 'المستحقات')}}</span>
                <div class="CookiesRowSpacer"/>
                <span style="display: inline;" class="F1_5em">{{DeepFetcher(CookiesCashLedger, ['Credited'])}}</span><br><span> {{$store.state.CookiesConfig.PriceUnit}}</span>
                <CookiesUpDownArrow class="F1_5em" :Direction="'UP'" :Value="DeepFetcher(CookiesCashLedger, ['Credited'])" style="margin-top: 0.5em; margin-bottom: -0.5em;"></CookiesUpDownArrow>
                <div class="CookiesRowSpacer"/>
              </div>
            </div>
            <div class="W-100 BG6 D-Flex" style="text-align: center; padding: 0.8em 1.5em; border-radius: var(--rad2); filter: drop-shadow(var(--shadow0)); z-index:5">
              <div class="M-Auto">
                <span style="">{{LanguageSelector('Net Balance', 'الاجمالي')}}</span>
                <div class="CookiesRowSpacer"/>
                <span style="display: inline;" class="F2_5em F-Bold" :style="DeepFetcher(CookiesCashLedger, ['NetBalance']) < 0 ? 'color: var(--colorr1)': ''"> {{DeepFetcher(CookiesCashLedger, ['NetBalance']) ? DeepFetcher(CookiesCashLedger, ['NetBalance']) : '0' }}</span><br><span :style="DeepFetcher(CookiesCashLedger, ['NetBalance']) < 0 ? 'color: var(--colorr1)': ''" class="F-Bold"> {{$store.state.CookiesConfig.PriceUnit}}</span>
                <div class="CookiesRowSpacer"/>
              </div>
            </div>
            <div class="W-100 BG2 D-Flex M-Auto" style="text-align: center; padding: 0.8em 1.5em; filter: drop-shadow(var(--shadow0)); height: 90%" :style="$store.getters.Direction === 'ltr' ? 'border-radius: 0 0.5em 0.5em 0;' : 'border-radius: 0.5em 0 0 0.5em;'">
              <div class="M-Auto O-85">
                <span style="">{{LanguageSelector('Balance Out', 'المصاريف')}}</span>
                <div class="CookiesRowSpacer"/>
                <span style="display: inline;" class="F1_5em">{{DeepFetcher(CookiesCashLedger, ['Debited'])}}</span><br><span> {{$store.state.CookiesConfig.PriceUnit}}</span>
                <CookiesUpDownArrow class="F1_5em" :Direction="'DOWN'" :Value="DeepFetcher(CookiesCashLedger, ['Debited'])" style="margin-top: 0.5em; margin-bottom: -0.5em;"></CookiesUpDownArrow>
                <div class="CookiesRowSpacer"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Data Block >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->
      <div id="TableToPrint1" class="W-100 F_75em Shadow1" style="padding:5px;">
        <div><span class="F1_5em F-Bold JustPrint D-None">{{LanguageSelector('Customer: ', 'العميل: ')}}{{DialogData.Name}}</span></div>
        <div class="CookiesRowSplitter NOPRINT"/>
        <div :class="CookiesDataLedgerOrders.length === 0 ? 'Disabled' : ''" @click="printIT(1)" class="Shadow2 PrintButton D-Flex RowMargin NOPRINT">
          <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
          <span class="F-Bold M-Auto">{{LanguageSelector('Print', 'طباعة')}}</span>
        </div>
        <div class="M-Auto" style="">
          <!-- Orders Table /////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <CookiesTable :Loading="OrdersLoading" :PrintControls="false" :Footer="false" :TableName="LanguageSelector('Orders','الطلبات')" :Data="CookiesDataLedgerOrders" :Fields="Fields"></CookiesTable>
          <template v-if="CookiesDataJournalEntries.length > 0">
            <div class="CookiesRowSpacer"/>
            <CookiesTable :Loading="JournalLoading" :PrintControls="false" :TableName="LanguageSelector('Other Transactions','الحركات الاخرى')" :Data="CookiesDataJournalEntries" :Fields="OtherRecordsFields"></CookiesTable>
          </template>
        </div>
        <div class="CookiesRowSpacer"/>
        <div class="P_5em D-Flex"><span style="font-size: 17pt !important">{{LanguageSelector('Total amount for delivered orders: ', 'مجموع قيم الطلبات المحصلة: ')}} {{TotalOf(CookiesDataLedgerOrders, 'AmountTotalCollected')}} {{PriceUnit}}</span></div>
        <div class="P_5em D-Flex"><span style="font-size: 17pt !important">{{LanguageSelector('Total amount for Delivery Fees: ', 'مجموع قيم التوصيل: ')}} {{TotalOf(CookiesDataLedgerOrders, 'Fees')}} {{PriceUnit}}</span></div>
        <div class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Total amount owed to the customer: ', 'مجموع القيم المستحقة للعميل: ')}} <span class="F-Bold" style="font-size: 20pt !important">{{DeepFetcher(CookiesCashLedger, ['NetBalance']) ? DeepFetcher(CookiesCashLedger, ['NetBalance']) : '0' }} {{PriceUnit}}</span></span></div>
      </div>
      <div class="W-100" style="padding:5px">
        <div class="CookiesRowSplitter"/>
        <div class="W-100 F-Bold">
          <span>{{LanguageSelector('Account History', 'تاريخ الحساب')}}</span>
        </div>
        <div class="M-Auto" style="max-height:400px; overflow-y:scroll;">
          <table class="Cookies_Table1 Cookies_TableButtons" style="position: relative; border-radius:var(--rad2); min-width: 75%; margin: auto;"  cellspacing="0" cellpadding="0">
            <tr class="Disabled" v-if="!CookiesDataBalanceHis.length"><td colspan="10">{{LanguageSelector('No Entries','لا يوجد')}}</td></tr>
            <tr @click="OpenOlderBalance(entry)" v-for="entry in GeneralSort(CookiesDataBalanceHis, ['CloseDate'], -1)" :key="entry">
              <td><span>{{JsonDateParser(entry.OpenDate) + ' - '}}</span><strong>{{JsonTimeParser(entry.OpenDate)}}</strong></td>
              <td><strong>-</strong></td>
              <td><span>{{JsonDateParser(entry.CloseDate) + ' - '}}</span><strong>{{JsonTimeParser(entry.CloseDate)}}</strong></td>
              <td class="Arrow"><CookiesUpDownArrow class="F_75em O-75" :Direction="'right'" :Value="1"></CookiesUpDownArrow></td>
            </tr>
          </table>
        </div>
        <div class="CookiesRowSpacer"/>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('FinancialRead')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$store.commit('CloseDialog', DialogIndex)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Close', 'خروج')}}</button>
  </div>
</div>

</template>

<script>
export default {
  components: {},
  data () {
    return {
      CookiesDataTransactions: [],
      CookiesDataBalanceHis: [],
      CookiesDataOrders: [],
      CookiesDataLedgerOrders: [],
      CookiesDataJournalEntries: [],
      TheUser: {},
      TheUserAccount: {},
      CookiesCashLedger: {},
      OrdersLoading: true,
      ReturnedLoading: true,
      JournalLoading: true,
      Fields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Name'],
          sort: true
        },
        {
          name: this.LanguageSelector('Order ID', 'رقم الطلب'),
          value: ['ID'],
          sort: true
        },
        {
          name: this.LanguageSelector('Policy', 'البوليصة'),
          value: ['BarCode'],
          sort: true
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Time', 'الوقت'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'time'
        },
        {
          name: this.LanguageSelector('Location', 'العنوان'),
          value: ['SubArea']
        },
        {
          name: this.LanguageSelector('Phone Number', 'الهاتف'),
          value: ['Client', 'PhoneNum']
        },
        {
          name: this.LanguageSelector('Order Ammuont', 'قيمة الطلب'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Collected Amount', 'القيمة المحصلة'),
          value: ['AmountTotalCollected'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
          value: ['Fees'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Status', 'الحالة'),
          value: ['EncodedStatus'],
          sort: true
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: ['Notes']
        }
      ],
      ReturnedFields: [
        {
          selector: true,
          Res: 'DR_ReturnedItems_',
          Res_ID: ['ID'],
          DefaultValue: true
        },
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Name'],
          sort: true
        },
        {
          name: this.LanguageSelector('Order ID', 'رقم الطلب'),
          value: ['ID'],
          sort: true
        },
        {
          name: this.LanguageSelector('Policy', 'البوليصة'),
          value: ['BarCode'],
          sort: true
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Time', 'الوقت'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'time'
        },
        {
          name: this.LanguageSelector('Location', 'العنوان'),
          value: ['SubArea']
        },
        {
          name: this.LanguageSelector('Phone Number', 'الهاتف'),
          value: ['Client', 'PhoneNum']
        },
        {
          name: this.LanguageSelector('Order Ammuont', 'قيمة الطلب'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Collected Amount', 'القيمة المحصلة'),
          value: ['AmountTotalCollected'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
          value: ['Fees'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Status', 'الحالة'),
          value: ['EncodedStatus'],
          sort: true
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: ['Notes']
        }
      ],
      OtherRecordsFields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['Date'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: this.LanguageSelector(['Description'], ['DescriptionAr']),
          sort: true
        },
        {
          name: this.LanguageSelector('Ammuont', 'القيمة'),
          value: ['Amount'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        }
      ]
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadUserOders () {
      this.ReturnedLoading = true
      this.Get('Order?CustomerID=' + this.TheUser.Id).then(response => {
        this.CookiesDataOrders = response.map(element => {
          element.EncodedStatus = this.StatusMapper(element.Status)
          return element
        })
        this.ReturnedLoading = false
      }, error => {
        this.ReturnedLoading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadReturnedOrders (LedgerID) {
      this.ReturnedLoading = true
      if (LedgerID === undefined) {
        this.ReturnedLoading = false
        return
      }
      this.Get('ReturnedOrders?ledgerId=' + LedgerID).then(response => {
        this.CookiesDataOrders = response.map(element => {
          element.EncodedStatus = this.StatusMapper(element.Status)
          return element
        })
        this.ReturnedLoading = false
      }, error => {
        this.ReturnedLoading = false
        error.CookiesError = 'Error in reading Returned Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadLedgerOders (LedgerID) {
      this.OrdersLoading = true
      if (LedgerID === undefined) {
        this.OrdersLoading = false
        return
      }
      this.Get('SuttelmentOrders?ledgerId=' + LedgerID).then(response => {
        this.CookiesDataLedgerOrders = response.map(element => {
          element.EncodedStatus = this.StatusMapper(element.Status)
          return element
        })
        this.OrdersLoading = false
      }, error => {
        this.OrdersLoading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadJournalEntry (LedgerID) {
      this.JournalLoading = true
      if (LedgerID === undefined) {
        this.JournalLoading = false
        return
      }
      this.Get('Financial/JournalEntry?LedgerId=' + LedgerID).then(response => {
        this.Console('response')
        this.Console(response)
        var filtered = response.filter(element => (
          element.RefId.length === 0
        ))
        this.Console('filtered')
        this.Console(filtered)
        this.CookiesDataJournalEntries = filtered.map(element => {
          element.Amount = this.CreditMapper(element.Type, element.Amount)
          return element
        })
        this.JournalLoading = false
      }, error => {
        this.JournalLoading = false
        error.CookiesError = 'Error in reading Journal Entries'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBalances () {
      this.Get('Financial/GetBalanceHis?ID=' + this.TheUser.Id).then(response => {
        this.CookiesDataBalanceHis = response
      }, error => {
        error.CookiesError = 'Error in reading Balances'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAccount (ID) {
      this.Get('Financial/GetAccount?ID=' + ID).then(response => {
        this.ApplyUserData(response[0])
      }, error => {
        error.CookiesError = 'Error in reading Customer Account'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewTrans () {
      var TData = {}
      TData.Category = 'Customer'
      TData.Account = this.TheUserAccount
      TData.Header = this.DeepFetcher(this.TheUser, ['Name'])
      this.OpenDialog('Finance_Transaction_Dialog', this.LanguageSelector('New Transaction', 'حركة جديدة'), TData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Settlement () {
      var TData = {}
      TData.Category = 'Customer'
      TData.APIURL = 'Financial/SuttleBalance' + '?BalanceId=' + this.TheUser.Balance.ID
      TData.Header = this.DeepFetcher(this.TheUser, ['Name'])
      TData.NetAmmount = this.DeepFetcher(this.CookiesCashLedger, ['NetBalance'])
      TData.BalanceType = 'Debiter'
      this.OpenDialog('Finance_Settelment_Dialog', this.LanguageSelector('Settlement', 'تسوية حساب'), TData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenOlderBalance (Account) {
      this.Console(Account, 'background: blue;')
      var CashLedger = this.LedgerFinder(Account.ledgerHis, this.LedgersMapper.C_Collected, 1)
      if (CashLedger === null || CashLedger === undefined) {
        return
      }
      Account.LedgerID = CashLedger.ID
      Account.UserName = this.TheUser.Name
      Account.AccountID = this.TheUser.Balance.ID
      Account.UserType = 'Customer'
      this.OpenDialog('BalanceHis', this.LanguageSelector('Account History', 'تاريخ الحساب'), Account) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ApplyUserData (UserAccount) {
      this.TheUserAccount = this.DeepCloner(UserAccount)
      var CashLedger = this.LedgerFinder(this.DeepFetcher(this.TheUserAccount, ['ledgers']), this.LedgersMapper.C_Collected, 2)
      this.CookiesCashLedger = CashLedger
      if (CashLedger === null || CashLedger === undefined) {
        return
      }
      this.ReadReturnedOrders(CashLedger.ID)
      this.ReadLedgerOders(CashLedger.ID)
      this.ReadBalances()
      this.ReadJournalEntry(CashLedger.ID)
    },
    printIT (x) {
      var bodd = document.getElementById('TableToPrint' + x)
      this.$store.state.Printing = bodd.cloneNode([true])
    }
  },
  mounted () {
    this.TheUser = this.DialogData
    if (!this.Access('FinancialRead')) {
      return
    }
    this.ReadAccount(this.DialogData.Balance.ID)
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {}
  },
  computed: {
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    ReturnedOrders () {
      var Returned = []
      this.CookiesDataOrders.forEach(item => {
        var ReturnedFilterIt = true
        // ////////////////////////////////////////
        try {
          if (!this.DeepFetcher(item, ['ReturnedItemReceivedFromDriver']) || this.DeepFetcher(item, ['ReturnedItemDelivaredToCustomer'])) {
            ReturnedFilterIt = false
          }
        } catch (error) {
        }
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (ReturnedFilterIt) {
          Returned.push(item)
        }
      })
      return Returned
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadAccount(this.DialogData.Balance.ID)
      }
    }
  }
}
</script>

<style scoped>
.DialogBody{
  /* width: 800px; */
  max-width: 95vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}

.barcode{
  display: flex;
  justify-content: center;
  width:300px;
  max-width:100%;
  height:100px;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.Cookies_TableButtons > tr:hover{
  background: v-bind('Theme.BG6');
  filter: drop-shadow(var(--shadow1));
}
.Cookies_TableButtons > tr{
  cursor: pointer;
  height: 3em;
  transition-duration: 0.2s;
  transition-property: filter, background;
}
.Cookies_TableButtons > tr > td.Arrow{
  transition-duration: 0.2s;
  transition-property: transform;
  padding-right: 1em !important;
}
.Cookies_TableButtons > tr:hover > td.Arrow{
  transform: translateX(0.2em);
}
.Cookies_TableButtons{
  padding: 1em;
}
.PrintButton{
  width: 6em;
  border-radius: 5em;
  background: v-bind('Theme.BG6');
  padding: 0.4em 0.4em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
</style>
