
const routes :Array<CookiesRouter> = [
  {
    path: '/Security',
    name: 'Security',
    meta: {
      Access: 'Security_View' as AccessFeature
    },
    component: () => import('@/Apps/Security/views/Security.vue'),
    children: [
      {
        path: '/Security/Users',
        name: 'Users',
        component: () => import('@/Apps/Security/views/Users.vue'),
        meta: {
          Access: 'Security_Public',
          LoginHeader: true
        }
      },
      {
        path: '/Security/Profiles',
        name: 'Profiles',
        component: () => import('@/Apps/Security/views/Roles.vue'),
        meta: {
          Access: 'Security_Public'
        }
      }
    ]
  }
]

export const SecurityRoutes = routes
