
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Warehouse>,
      ItemsLocations: [] as Array<any>,
      Branches: [] as Array<Category>,
      Sections: [] as Array<Section>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Branch',
            Visible: true,
            Options: this.Branches,
            Selectors: ['Name'],
            Res: 'Branches_Filter',
            DataPath: ['BranchID'],
            ListPath: ['ID']
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Warehouse',
          this: this,
          Function: 'NewWarehouse',
          Icon: 'Add',
          Visible: this.Access('Inventory_Warehouses_Create')
        }
        // {
        //   Name: 'Fill Warehouses',
        //   this: this,
        //   Function: 'FillWarehouse',
        //   Icon: 'Arrow',
        //   Visible: this.Access('SuperAdmin')
        // }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: 1, Path: ['Code'] },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Code',
            Path: ['Code'],
            Sort: ['Code'],
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name']
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description'],
            Sort: ['Description'],
            Class: 'F_75em'
          },
          {
            Type: 'Normal',
            Name: 'Branch',
            Path: ['Branch'],
            Sort: ['Branch'],
            Visible: this.Access('Branches_Read')
          },
          {
            Type: 'Normal',
            Name: 'Volume',
            Path: 'VolumeMax',
            Sort: ['VolumeMax'],
            Bold: true,
            Unit: this.Translate('m3')
          },
          {
            Type: 'Bar',
            Name: 'Used',
            Path: ['AvailableQuantityVolume'],
            Sort: ['AvailableQuantityVolume'],
            BarMinFixed: 0,
            BarMaxPath: 'VolumeMax',
            BarValues: [
              {
                Name: this.Translate('Used'),
                ValuePath: 'AvailableQuantityVolume'
              },
              {
                Name: this.Translate('Empty'),
                ValuePath: 'EmptyVolume',
                CustomStyle: 'background: none; width: 0;'
              }
            ],
            Style: 'min-width: 4em;'
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Shelves',
                Visible: this.Access('Inventory_Warehouses_Read'),
                TooltTip: this.Translate('Sections'),
                Emits: 'EditWarehouseSections'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Warehouses_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditWarehouse'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteWarehouse',
                Visible: this.Access('Inventory_Warehouses_Remove'),
                TooltTip: this.Translate('Remove')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      var FilteredData = this.CookiesData.map(warehouse => {
        var Max = 0
        var InnerSections = this.LastLevelSections.filter((sec :any) => {
          return sec.WarehouseID === warehouse.ID
        })
        if (InnerSections.length > 0) {
          InnerSections.forEach(inner => {
            Max += inner.Volume
          })
        }
        var Filtered = this.ItemsLocations.filter((loc :any) => {
          return loc.WarehouseID === warehouse.ID
        })
        var Available = 0
        var Reserved = 0
        var Ordered = 0
        var AvailableVolume = 0
        var ReservedVolume = 0
        var OrderedVolume = 0
        Filtered.forEach((lo :any) => {
          Available += lo.TotalAvailableQuantity
          Ordered += lo.TotalOrderedQuantity
          Reserved += lo.TotalReservedQuantity
          AvailableVolume += lo.TotalAvailableQuantity * lo.ItemVolume
          OrderedVolume += lo.TotalOrderedQuantity * lo.ItemVolume
          ReservedVolume += lo.TotalReservedQuantity * lo.ItemVolume
        })
        return {
          AvailableQuantity: Available + Ordered + Reserved,
          AvailableQuantityVolume: AvailableVolume + OrderedVolume + ReservedVolume,
          EmptyVolume: Max - AvailableVolume - OrderedVolume - ReservedVolume,
          VolumeMax: Max,
          ...warehouse
        }
      })
      return this.CookiesFilter(FilteredData, this.Filter, this.Search)
    },
    LastLevelSections () {
      return this.Sections.filter((sec :any) => {
        var Found = this.Sections.some(innersec => {
          return innersec.SectionLevel1ID === sec.ID || innersec.SectionLevel2ID === sec.ID
        })
        return !Found
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.ReadBranches()
      this.ReadItemsLocations()
      this.ReadSections()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Warehouses').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSections () {
      this.Loading.push('Loading')
      this.Get('Inventory_Sections').then(response => {
        this.Sections = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sections'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBranches () {
      this.Loading.push('Loading')
      this.Get('Branches').then(response => {
        this.Branches = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItemsLocations () {
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation').then(response => {
        this.ItemsLocations = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditWarehouse (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Warehouse_Dialog', this.Translate('Edit Warehouse') + ' (' + Warehouse.Name + ') ', { Data: Warehouse, Editing: true }, {})
    },
    EditWarehouseSections (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Read')) {
        return
      }
      this.OpenDialog('Inventory_WarehouseSections_Dialog', this.Translate('Edit Sections'), { Data: Warehouse, Editing: true }, {})
    },
    DeleteWarehouse (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Warehouses', AddQuery: '?ID=' + Warehouse.ID, Text: 'Are you sure you want to delete <strong>' + Warehouse.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewWarehouse':
          this.OpenDialog('Inventory_Warehouse_Dialog', this.Translate('New Warehouse'), { Data: {} })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
