
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      Roles: [],
      ErrorsVisibilty: false,
      ProfileData: {} as any,
      TableSettings: {
        CookiesID: 'D_Profiles_Roles_Table',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true
          },
          {
            Name: 'System',
            Type: 'normal',
            Value: 'SystemCode',
            Sort: 'System'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Bold: true
          },
          {
            Name: 'Description',
            Type: 'normal',
            Value: 'Description',
            Style: 'max-width: 6em;',
            Class: 'F_75em'
          },
          {
            Type: 'selector',
            Name: '',
            Value: '',
            Res_ID: 'Code',
            Bold: true,
            Visible: this.DialogData.Editing ? this.Access('Security_Profiles_Edit') : this.Access('Security_Profiles_Create')
          }
        ],
        Footer: true,
        Header: true,
        TableSelectDefaults: false
      } as CookiesTable,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Profile_Name' },
        { Path: 'Roles', Res: 'D_Profiles_Roles_Table', IDPath: 'ID', Array: [['Code'], ['ID']] },
        { Path: 'Description', Res: 'D_Profiles_Description' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadRoles()
      if (this.DialogData.Editing || this.DialogData.Data?.ClonedID) {
        this.ReadProfile()
      }
    },
    ReadRoles () {
      this.Loading.push('Loading')
      this.Get('Security_Roles').then(response => {
        this.Roles = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Roles'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadProfile () {
      this.Loading.push('Loading')
      this.Get('Security_Profiles', '?ProfileID=' + ((this.DialogData && this.DialogData.Data.ID) ? this.DialogData.Data.ID : this.DialogData.Data.ClonedID)).then(response => {
        var OriginalData = this.DialogData.Data
        OriginalData.Roles = response.Roles
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, OriginalData)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Profile Roles'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Security_Profiles',
        OriginalData: this.OriginalData,
        PatchAPI: 'Security_Profiles',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?id=' + this.DialogData.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    ChangePassword () {
      this.OpenDialog('Security_ChangePassword_Dialog', 'Change Password', this.MEMRead('D_Employee_Email')) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintTable () {
      const TableDom = document.getElementById('D_Profiles_Print')
      this.PrintIT(TableDom, { PageSize: 'A4 portrait', Header: true })
    }
  },
  mounted () {
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  }
})
