
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Field: { default: () => { return {} }, type: Object as PropType<FunctionalTableGeneralField> },
    item: { type: Object as PropType<any> }
  },
  methods: {
    Clicked () {
      if (this.Field.Emits) {
        this.$emit('ContentClicked')
      } else if (this.Field.Whatsapp) {
        this.OpenWhatsapp(this.DeepFetcher(this.item, this.Field.Whatsapp))
      }
    }
  }
})
