import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-670f833b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"pointer-events":"none"},
  class: "CardHeaderTXT V-Auto"
}
const _hoisted_2 = {
  class: "P-Rel F-Bold M-Auto F_85em CardHoverButton",
  style: {"margin-inline-end":"1em"}
}
const _hoisted_3 = { class: "CookiesCardBody" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CookisCardPlacer())),
    style: _normalizeStyle(_ctx.CardOnHold ? _ctx.CardSizeNow : _ctx.FixedStyle),
    OnHoldNow: "true",
    class: _normalizeClass(["CookiesDragableCard BG2", {CookiesCardOnHold : _ctx.CardOnHold}])
  }, [
    _createElementVNode("div", {
      OnHoldNow: "true",
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CookisCardHolder($event))),
      class: "CookiesCardHeader D-Flex color3"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.Translate(_ctx.CookiesData.CardName)), 1),
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_CookiesIcon, {
          onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.OpenCardSettings($event))),
          ID: 'Settings',
          class: "SQR1_5em HoverGlow",
          ToolTip: _ctx.Translate('Settings')
        }, null, 8, ["ToolTip"])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CookiesData.CardComponent), _mergeProps(_ctx.CookiesData.CardData, {
        CookiesData: {..._ctx.CookiesData.CardData},
        CookiesSettings: _ctx.CookiesData.CardSettings
      }), null, 16, ["CookiesData", "CookiesSettings"]))
    ])
  ], 38))
}