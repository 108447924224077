<template>
<div v-if="Access('FinancialRead')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody P-Rel">
  <div @click="print" class="F_85em P-Abs D-Flex BG3 HoverUp Transition0_25 Shadow0" style="left: 0; width: 6em; border-radius: 10em; margin: 0.4em; padding: 0.2em; z-index: 50"><span class="M-Auto F-Bold">{{LanguageSelector('Print', 'طباعة')}}</span><CookiesIcon style="padding: 0.4em; width: 1.2em; height: 1.2em;" :ID="'Printer'"></CookiesIcon></div>
  <div class="M-Auto">
    <div class="DialogBody D-Flex Shadow1" style="flex-direction: column; text-align:center; padding: 1em;" id="PrintArea">
      <div class="" style="padding: 1em;"><span>{{LanguageSelector('Account History For ', 'كشف حساب ')}}</span><span class="F-Bold">{{DialogData.UserName}}</span><span>{{LanguageSelector(' From ', ' من ')}}</span><span class="F-Bold">{{JsonDateParser(DialogData.OpenDate) + ' | ' + JsonTimeParser(DialogData.OpenDate)}}</span><span>{{LanguageSelector(' To ', ' الى ')}}</span><span class="F-Bold">{{JsonDateParser(DialogData.CloseDate) + ' | ' + JsonTimeParser(DialogData.CloseDate)}}</span></div>
      <CookiesTable :Loading="OrdersLoading" class="HisTable F_75em" :PrintControls="false" :Footer="false" :TableName="''" :Data="CookiesDataLedgerOrders" :Fields="TableFieldsOld"></CookiesTable>
      <template v-if="CookiesDataJournalEntries.length > 0">
        <div class="CookiesRowSpacer"/>
        <CookiesTable class="HisTable F_75em" :Loading="JournalLoading" :PrintControls="false" :TableName="LanguageSelector('Other Transactions','الحركات الاخرى')" :Data="CookiesDataJournalEntries" :Fields="OtherRecordsFields"></CookiesTable>
      </template>
      <div class="CookiesRowSpacer"/>
      <div v-if="CookiesDataLedgerOrders.length > 0" class="P_5em D-Flex"><span style="font-size: 17pt !important">{{LanguageSelector('Total amount for delivered orders: ', 'مجموع قيم الطلبات المحصلة: ')}} {{TotalOf(CookiesDataLedgerOrders, 'AmountTotalCollected')}} {{PriceUnit}}</span></div>
      <div v-if="CookiesDataLedgerOrders.length > 0" class="P_5em D-Flex"><span style="font-size: 17pt !important">{{LanguageSelector('Total amount for Delivery Fees: ', 'مجموع قيم التوصيل: ')}} {{TotalOf(CookiesDataLedgerOrders, 'Fees')}} {{PriceUnit}}</span></div>
      <div v-if="CookiesDataLedgerOrders.length > 0 && DialogData.UserType === 'Customer'" class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Total amount owed to the customer: ', 'مجموع القيم المستحقة للعميل: ')}} <span class="F-Bold" style="font-size: 20pt !important">{{FixedTotals.Total}} {{PriceUnit}}</span></span></div>
      <div v-if="CookiesDataLedgerOrders.length > 0 && DialogData.UserType === 'Driver'" class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Total amount owed to the customer: ', 'مجموع القيم المطلوبه من السائق: ')}} <span class="F-Bold color1" style="font-size: 20pt !important">{{FixedTotals.Total}} {{PriceUnit}}</span></span></div>
      <div v-if="CookiesDataLedgerOrders.length > 0 && DialogData.UserType === 'Customer'" class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Amount Paid: ', 'القيمة المدفوعة: ')}} <span class="F-Bold color1" style="font-size: 20pt !important">{{FixedTotals.Paid}} {{PriceUnit}}</span></span></div>
      <div v-if="CookiesDataLedgerOrders.length > 0 && DialogData.UserType === 'Driver'" class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Amount Collected: ', 'القيمة المقبوضة: ')}} <span class="F-Bold color1" style="font-size: 20pt !important">{{FixedTotals.Paid}} {{PriceUnit}}</span></span></div>
      <div v-if="CookiesDataLedgerOrders.length && FixedTotals.Delayed !== 0" class="P_5em D-Flex"><span style="font-size: 20pt !important">{{LanguageSelector('Residual Amount: ', 'القيمة المؤجلة: ')}} <span class="F-Bold color1" style="font-size: 20pt !important">{{FixedTotals.Delayed}} {{PriceUnit}}</span></span></div>
      <template v-if="ReturnedOrders.length > 0">
        <div class="CookiesRowSpacer"/>
        <CookiesTable class="HisTable F_75em" :Loading="ReturnedLoading" :PrintControls="false" :TableName="LanguageSelector('Ruternrd Orders','المرتجعات')" :Data="ReturnedOrders" :Fields="ReturnedFields"></CookiesTable>
      </template>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      OrdersLoading: false,
      JournalLoading: false,
      ReturnedLoading: false,
      TableFieldsOld: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Name'],
          sort: true
        },
        {
          name: this.LanguageSelector('Order ID', 'رقم الطلب'),
          value: ['ID'],
          sort: true
        },
        {
          name: this.LanguageSelector('Policy', 'البوليصة'),
          value: ['BarCode'],
          sort: true
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Time', 'الوقت'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'time'
        },
        {
          name: this.LanguageSelector('Location', 'العنوان'),
          value: ['SubArea']
        },
        {
          name: this.LanguageSelector('Phone Number', 'الهاتف'),
          value: ['Client', 'PhoneNum']
        },
        {
          name: this.LanguageSelector('Order Ammuont', 'قيمة الطلب'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Collected Amount', 'القيمة المحصلة'),
          value: ['AmountTotalCollected'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
          value: ['Fees'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Status', 'الحالة'),
          value: ['EncodedStatus'],
          sort: true
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: ['Notes']
        }
      ],
      OtherRecordsFields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['Date'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: this.LanguageSelector(['Description'], ['DescriptionAr']),
          sort: true
        },
        {
          name: this.LanguageSelector('Ammuont', 'القيمة'),
          value: ['Amount'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true,
          coloredNum: true
        }
      ],
      CookiesDataLedgerOrders: [],
      ReturnedOrders: [],
      CookiesDataJournalEntries: [],
      ReturnedFields: [
        {
          selector: true,
          Res: 'DR_ReturnedItems_',
          Res_ID: ['ID'],
          DefaultValue: true
        },
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Name'],
          sort: true
        },
        {
          name: this.LanguageSelector('Order ID', 'رقم الطلب'),
          value: ['ID'],
          sort: true
        },
        {
          name: this.LanguageSelector('Policy', 'البوليصة'),
          value: ['BarCode'],
          sort: true
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Time', 'الوقت'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'time'
        },
        {
          name: this.LanguageSelector('Location', 'العنوان'),
          value: ['SubArea']
        },
        {
          name: this.LanguageSelector('Phone Number', 'الهاتف'),
          value: ['Client', 'PhoneNum']
        },
        {
          name: this.LanguageSelector('Order Ammuont', 'قيمة الطلب'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Collected Amount', 'القيمة المحصلة'),
          value: ['AmountTotalCollected'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
          value: ['Fees'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Status', 'الحالة'),
          value: ['EncodedStatus'],
          sort: true
        },
        {
          name: this.LanguageSelector('Description', 'ملاحظات'),
          value: ['Notes']
        }
      ]
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    if (!this.Access('FinancialRead')) {
      return
    }
    this.Console('this.DialogData.LedgerID')
    this.Console(this.DialogData.LedgerID)
    this.ReadLedgerOders(this.DialogData.LedgerID)
    this.ReadReturnedOrders(this.DialogData.LedgerID)
    this.ReadJournalEntry(this.DialogData.LedgerID)
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    ReadLedgerOders (LedgerID) {
      this.OrdersLoading = true
      this.Get('OldSuttelmentOrders?ledgerId=' + LedgerID).then(response => {
        this.CookiesDataLedgerOrders = response.map(element => {
          element.EncodedStatus = this.StatusMapper(element.Status)
          return element
        })
        this.OrdersLoading = false
      }, error => {
        this.OrdersLoading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Sorting (a, b) {
      var SortElement = ['Date']
      var SortDirection = 1
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    ReadReturnedOrders (LedgerID) {
      this.ReturnedLoading = true
      if (LedgerID === undefined) {
        this.ReturnedLoading = false
        return
      }
      this.Get('ReturnedOrders?ledgerId=' + LedgerID).then(response => {
        this.CookiesDataOrders = response.map(element => {
          element.EncodedStatus = this.StatusMapper(element.Status)
          return element
        })
        this.ReturnedLoading = false
      }, error => {
        this.ReturnedLoading = false
        error.CookiesError = 'Error in reading Returned Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadJournalEntry (LedgerID) {
      this.JournalLoading = true
      this.Get('Financial/JournalEntry?LedgerId=' + LedgerID).then(response => {
        var filtered = response.filter(element => (
          element.RefId.length === 0
        ))
        this.CookiesDataJournalEntries = filtered.map(element => {
          element.Amount = this.CreditMapper(element.Type, element.Amount)
          return element
        })
        this.JournalLoading = false
      }, error => {
        this.JournalLoading = false
        error.CookiesError = 'Error in reading Journal Entries'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    print () {
      var bodd = document.getElementById('PrintArea')
      this.$store.state.PrintingOptions = {
        MinWidth: '',
        MaxWidth: '',
        FontSize: '',
        Padding: '',
        Margin: ''
      }
      var ToPrint = bodd.cloneNode([true])
      ToPrint.style.borderRadius = 0
      this.$store.state.Printing = ToPrint
    }
  },
  computed: {
    TableFields () {
      if (this.DialogData.UserType === 'Customer') {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Policy', 'البوليصة'),
            value: ['BarCode'],
            sort: true
          },
          {
            name: this.LanguageSelector('Date', 'التاريخ'),
            value: ['Date'],
            sort: true,
            type: 'date'
          },
          {
            name: this.LanguageSelector('Time', 'الوقت'),
            value: ['Date'],
            sort: true,
            type: 'time'
          },
          {
            name: this.LanguageSelector('Location', 'العنوان'),
            value: ['Location']
          },
          {
            name: this.LanguageSelector('Phone Number', 'الهاتف'),
            value: ['PhoneNumber']
          },
          {
            name: this.LanguageSelector('Amount Collected', 'القيمة المحصلة'),
            value: ['Amount'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
            value: ['Fees'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Type', 'النوع'),
            value: ['Type'],
            sort: true
          },
          {
            name: this.LanguageSelector('Description', 'الوصف'),
            value: ['Description']
          }
        ]
      } else {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Policy', 'البوليصة'),
            value: ['BarCode'],
            sort: true
          },
          {
            name: this.LanguageSelector('Date', 'التاريخ'),
            value: ['Date'],
            sort: true,
            type: 'date'
          },
          {
            name: this.LanguageSelector('Time', 'الوقت'),
            value: ['Date'],
            sort: true,
            type: 'time'
          },
          {
            name: this.LanguageSelector('Location', 'العنوان'),
            value: ['Location']
          },
          {
            name: this.LanguageSelector('Phone Number', 'الهاتف'),
            value: ['PhoneNumber']
          },
          {
            name: this.LanguageSelector('Amount Collected', 'القيمة المحصلة'),
            value: ['Amount'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Delivery Fees', 'عمولة التوصيل'),
            value: ['Fees'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Type', 'النوع'),
            value: ['Type'],
            sort: true
          },
          {
            name: this.LanguageSelector('Description', 'الوصف'),
            value: ['Description']
          }
        ]
      }
    },
    BalanceHisDebitCreditFix () {
      var EditedData = this.CookiesDataJournalEntries.map(entry => {
        entry.Amount = -1 * this.CreditMapper(entry.Type, entry.Amount)
        entry.Type = this.CreditMapper(entry.Type)
        return entry
      })
      return EditedData
    },
    SortedEntries () {
      var ToBeSorted = this.CookiesDataJournalEntries
      return ToBeSorted.sort(this.Sorting)
    },
    FixedTotals () {
      var Total = 0
      this.Console('ssssssssssssssssssssssssssssssssssssss')
      if (this.CookiesDataJournalEntries.length === 0) {
        return {
          Paid: 0,
          Total: 0,
          Delayed: 0
        }
      }
      var JournalEntries = this.DeepCloner(this.SortedEntries)
      var AllwithoutLastElement = this.DeepCloner(JournalEntries.splice(-1, 1))
      var lastElement = this.SortedEntries[this.SortedEntries.length - 1]
      Total = this.TotalOf(AllwithoutLastElement, ['Amount'])
      var Paid = lastElement.Amount
      var Delayed = 0
      try {
        Delayed = Total - Paid
      } catch (error) {
      }
      this.Console('ssssssssssssssssssssssssssssssssssssss')
      this.Console(Paid)
      this.Console(Total)
      this.Console(Delayed)
      return {
        Paid: lastElement.Amount,
        Total: lastElement.Amount,
        Delayed: Delayed
      }
    }
  }
}
</script>
<style scoped>
.HisTable{
  max-width: 90vw;
}
@media (max-width: 1000px) {
  .CookiesDialogdBody{
    font-size: 1.8vw;
  }
}
</style>
