
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {}
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    Tabs () {
      return [
        { Name: this.Translate('Users'), Link: 'Users', Access: this.Access('Security_Users_Read') },
        { Name: this.Translate('Profiles'), Link: 'Profiles', Access: this.Access('Security_Profiles_Read') }
      ] as Array<CookiesTabs>
    }
  },
  methods: {},
  watch: {}
})
