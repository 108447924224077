<template>
  <div class="BigBody">
    <div class="TableBodyContainer">
      <div class=" CookiesRow">
        <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
          <tr>
            <th></th>
            <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('Line Name', 'اسم الخط')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
            <CookiesSortingTH @click="SortTable(['DefultDriver', 'Employee', 'Name'])" :Name="LanguageSelector('Driver', 'السائق')" :Value="['DefultDriver', 'Employee', 'Name']" :sortColumn="sortElment" ></CookiesSortingTH>
            <th v-if="Access('AreasEdit')"></th>
          </tr>
          <tr v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Lines', 'لا يوجد خطوط')}}</td></tr>
          <tr v-if="Loading"><td colspan="10" class="" style="padding: 1.5em 0.2em;"><div class="D-Flex W-100 H-100"><CookiesLoading class="M-Auto" style="place-content: center;"></CookiesLoading></div></td></tr>
          <tr v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesGeneralTableRow">
            <td style="padding: 1em;">{{index + 1}}</td>
            <td :class="Access('AreasEdit') ? 'EditableCell' : ''" @blur="ValueChanged(value, ['Name'], 'Line_' + value.ID)" :ref="'Line_' + value.ID" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{value['Name'] ? value['Name']: 'N/A'}}</td>
            <td><CookiesDropDown :class="Access('AreasEdit') ? '' : 'Disabled'" :IndexSelect="DriverSelector(value)" :Type="'Object'" :Selector="['Employee', 'Name']" @slection_changed="DriverChanged(value, ['DefaultDriver'], $store.state.CookiesMemory['LineDriver_' + value.ID])" :Radio="true" :Options="CookiesDriversOptions" :Res="'LineDriver_' + value.ID"></CookiesDropDown></td>
            <td><CookiesIcon v-if="Access('AreasDelete')" @click="RemoveLine(value)" class="HoverUp M-Auto" style="width:2em; height:2em;" :ID="'Delete'"></CookiesIcon></td>
          </tr>
          <tr><td style="height:12em;"></td></tr> <!-- Last Item Drop Down Place Holder --------------------- -->
        </table> <!-- TABLE END --------------------- -->
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  mounted () {
    this.ReadLines()
  },
  props: {
    Search: { default: '', Type: String }
  },
  data () {
    return {
      CookiesLines: [],
      // Search: '',
      sortElment: {
        name: ['Name'],
        value: 1
      },
      CookiesDataDrivers: [],
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedTable.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedTable () {
      var Sorted = this.CookiesLines
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    },
    CookiesDriversOptions () {
      var WithOption = [
        {
          Employee: {
            Name: this.LanguageSelector('Not Specified', 'غير محدد')
          }
        }
      ]
      // WithOption.concat(this.CookiesDataDrivers)
      this.CookiesDataDrivers.forEach(driver => {
        WithOption.push(driver)
      })
      return WithOption
    }
  },
  methods: {
    ReadLines () {
      this.Loading = true
      this.Get('Area/getLine').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.CookiesLines = response
        this.ReadDrivers()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.CookiesDataDrivers = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ValueChanged (Line, Attribute, ref, Num = false) {
      if (!this.Access('AreasEdit')) {
        return
      }
      var value = this.$refs[ref].innerText
      if (Num) {
        value = parseFloat(value)
      }
      var originalLine = this.DeepCloner(Line)
      var NewLine = this.DeepCloner(Line)
      this.DeepChanger(NewLine, [Attribute], value)
      var Patch = this.CookiesPatcher(originalLine, NewLine)
      this.Post('PATCH', 'Area/EditLine?ID=' + Line.ID, Patch).then(response => {
        this.ReadLines()
      }, error => {
        error.CookiesError = 'Error in updating Line Name'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DriverChanged (Line, Attribute, value, Num = false) {
      if (Num) {
        value = parseFloat(value)
      }
      if (this.DeepFetcher(value, ['Employee', 'Name']) === this.LanguageSelector('Not Specified', 'غير محدد')) {
        value = { Id: 0 }
      }
      // var originalLine = this.DeepCloner(Line.ID)
      // var NewLine = this.DeepCloner(Line.ID)
      // this.DeepChanger(NewLine, [Attribute], value)
      // var Patch = this.CookiesPatcher(originalLine, NewLine)
      this.Post('POST', 'Area/AssginLineDriver?LineID=' + Line.ID + '&DriverID=' + value.Id, {}).then(response => {
        this.ReadLines()
      }, error => {
        error.CookiesError = 'Error in updating Driver Line'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveLine (Line) {
      this.OpenDialog('Delete_Dialog', this.LanguageSelector('Deleting Line', 'حذف خط'), ['DeleteLine?ID=' + Line.ID, Line.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    DriverSelector (Line) {
      var DriverID = 0
      var I = 0
      try {
        DriverID = this.DeepFetcher(Line, ['DefultDriver', 'Employee', 'Id'])
        I = this.IndexFinder(this.CookiesDriversOptions, ['Employee', 'Id'], DriverID)
      } catch (error) {
        console.log(error)
      }
      if (I < 0) {
        I = 0
      }
      return I
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadLines()
    }
  }
}
</script>
<style scoped>
.BigBody{
  font-size: 1rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  margin: auto;
  height: unset;
}

@media (max-width: 500px) {
  .BigBody>>>*{
    font-size: 3vw !important;
  }
  .TableBodyContainer{
    margin-top: 0em!important;
  }
}
.TableBodyContainer{
  width: 100%;
  overflow-y: unset;
  margin-top: 0em;
  min-width: 60vw;
  padding: 0;
}
td {
  padding: 0;
}
</style>
