
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      DeleteConfirmed: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<{Name :string, API: API_NAME, AddQuery: string, CloseMeAfter :boolean}> }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  methods: {
    ConfirmDelete () {
      if (!this.DialogData) {
        return
      }
      this.Saving = true
      this.Console(this.DialogData.API + this.DialogData.AddQuery, 'color: red')
      this.Post('DELETE', this.DialogData.API, {}, this.DialogData.AddQuery).then(response => {
        this.DeleteConfirmed = true
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  beforeUnmount () {
    if (this.DeleteConfirmed && this.DialogData && this.DialogData.CloseMeAfter) {
      this.$router.go(-1)
    }
  }
})
