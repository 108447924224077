
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      Loading: false,
      CookiesData: [] as any,
      InventoryItems: [] as any,
      CardOverNow: null as any,
      DraggedCard: null as any,
      CardIsDragging: false,
      ChangingCards: false,
      LastSwappedCard: -1,
      EditingCard: -1,
      // gh: {} as InstanceType<DocuntsDashCard>['$props'],
      CardsContent: [] as Array<{Data: DashCardData, Settings: DashCardSettings}>,
      DefaultCardsContent: [
        {
          Data: {
            CardComponent: 'TicketsCard',
            CardName: 'Transfers'
          },
          Settings: {
            Size: 'Medium'
          }
        },
        {
          Data: {
            CardComponent: 'CookiesLineCard',
            CardName: 'Categories',
            CardData: {
              Type: 'Export',
              Unit: 'Volume'
            }
          },
          Settings: {
            Size: 'Medium'
          }
        },
        {
          Data: {
            CardComponent: 'CookiesDonutCard',
            CardName: 'Items',
            CardData: {
              Type: 'Export',
              GroupBy: 'TransferItem.ItemID',
              OrderBy: 'Volume',
              Unit: 'Volume'
            }
          },
          Settings: {
            Size: 'Small'
          }
        },
        {
          Data: {
            CardComponent: 'CookiesDonutCard',
            CardName: 'Customers',
            CardData: {
              Type: 'Export',
              GroupBy: 'Transfer.CustomerName',
              OrderBy: 'Volume',
              Similarity: 0.6,
              Unit: 'Volume'
            }
          },
          Settings: {
            Size: 'Medium'
          }
        },
        {
          Data: {
            CardComponent: 'CookiesDonutCard',
            CardName: 'Branches Transfer',
            CardData: {
              Type: 'BranchesTransfer',
              GroupBy: 'Transfer.CustomerName',
              OrderBy: 'Volume',
              Unit: 'Volume'
            }
          },
          Settings: {
            Size: 'Medium'
          }
        },
        {
          Data: {
            CardComponent: 'CookiesDonutCard',
            CardName: 'Imports',
            CardData: {
              Type: '[Import,ExternalImport]',
              GroupBy: 'Transfer.SupplierName',
              OrderBy: 'Volume',
              Unit: 'Volume'
            }
          },
          Settings: {
            Size: 'Medium'
          }
        }
      ] as Array<{Data: DashCardData, Settings: DashCardSettings}>,
      AvailableCards: {
        DonutChart: {
          Name: 'CookiesDonutCard',
          Image: '',
          component: 'CookiesDonutCard',
          APIUrl: '',
          Settings: {
            Type: [{ Name: 'Exports', Value: 'Export' }, { Name: 'Imports', Value: '[Import,ExternalImport]' }, { Name: 'Branches Transfer', Value: 'BranchesTransfer' }],
            GroupBy: [{ Name: 'Customer', Value: 'Transfer.CustomerName', Dialog: 'Inventory_CustomerTransfersPrint_Dialog' }, { Name: 'Supplier', Value: 'Transfer.SupplierName' }, { Name: 'Category', Value: 'Category.Name' }, { Name: 'Items', Value: 'TransferItem.ItemID', CustomLabel: [{ Name: 'Code', Value: 'Item.Code' }] }],
            OrderBy: [{ Name: 'Volume', Value: 'Volume' }, { Name: 'Quantity', Value: 'Quantity' }, { Name: 'Customer', Value: 'Transfer.CustomerName' }, { Name: 'Supplier', Value: 'Transfer.SupplierName' }, { Name: 'Category', Value: 'Category.Name' }]
          }
        }
      }
    }
  },
  computed: {
    AdminPanelSettings () {
      var Settings :Array<AdminPanelFields> = []
      if (this.EditingCard !== -1) {
        Settings = [
          {
            Title: 'Name',
            TextInputs: [
              {
                Res: 'DashBoard_EditingCard_CardName'
              }
            ]
          },
          {
            Title: 'Size',
            Selectors: [
              {
                Options: ['Small', 'Medium', 'Large'],
                Res: 'DashBoard_EditingCard_CardSize',
                Radio: true,
                Visible: !this.MEMRead('DashBoard_EditingCard_CardFixedSizeEnabled')
              }
            ],
            Switches: [
              {
                Res: 'DashBoard_EditingCard_CardFixedSizeEnabled',
                Name: this.Translate('Custom'),
                Type: 'Switch'
              }
            ],
            Sliders: [
              {
                Res: 'DashBoard_EditingCard_CardFixedSize',
                Max: 1200,
                Min: 250,
                Visible: this.MEMRead('DashBoard_EditingCard_CardFixedSizeEnabled')
              }
            ]
          }
        ]
        // Donut Card Settings --------------------------------------------------------------
        switch (this.CardsContent[this.EditingCard].Data.CardComponent) {
          case 'CookiesDonutCard':
            var GroupBy = this.AvailableCards.DonutChart.Settings.GroupBy.find((value, index, array) => { return this.MEMRead('DashBoard_EditingCard_GroupBy') === value.Name })
            var CustomLabels :Array<any> | undefined
            if (GroupBy) CustomLabels = GroupBy.CustomLabel
            if (CustomLabels) CustomLabels = CustomLabels.map((value) => { return value.Name })
            Settings.push({
              Title: 'Settings',
              Selectors: [
                {
                  Name: this.Translate('Type'),
                  Options: this.AvailableCards.DonutChart.Settings.Type.map((value) => { return value.Name }),
                  Res: 'DashBoard_EditingCard_Type',
                  Radio: true
                },
                {
                  Name: this.Translate('Category'),
                  Options: this.AvailableCards.DonutChart.Settings.GroupBy.map((value) => { return value.Name }),
                  Res: 'DashBoard_EditingCard_GroupBy',
                  Radio: true
                },
                {
                  Name: this.Translate('Unit'),
                  Options: ['Volume', 'Quantity'],
                  Res: 'DashBoard_EditingCard_Unit',
                  Radio: true
                },
                {
                  Name: this.Translate('Sort'),
                  Options: this.AvailableCards.DonutChart.Settings.OrderBy.map((value) => { return value.Name }),
                  Res: 'DashBoard_EditingCard_OrderBy',
                  Radio: true
                },
                {
                  Name: this.Translate('Visible Name'),
                  Options: CustomLabels !== undefined ? CustomLabels : [],
                  Res: 'DashBoard_EditingCard_CustomLabel',
                  Radio: true,
                  Visible: CustomLabels !== undefined
                }
              ],
              Switches: [
                {
                  Res: 'DashBoard_EditingCard_Legend',
                  Name: this.Translate('Legend'),
                  Type: 'Switch'
                }
              ]
            })
            Settings.push({
              Title: 'Similarity',
              Switches: [],
              // TextInputs: [
              //   {
              //     Res: 'DashBoard_EditingCard_Similarity_Exclusions',
              //     Name: 'Combine',
              //     Order: 2
              //   }
              // ],
              Sliders: [
                {
                  Name: 'Merge Similarity',
                  Res: 'DashBoard_EditingCard_Similarity',
                  Max: 1,
                  Min: 0.4,
                  Steps: 0.05,
                  Visible: (this.MEMRead('DashBoard_EditingCard_GroupBy') === 'Transfer.CustomerName') || this.CardsContent[this.EditingCard].Data.CardData.Similarity !== undefined
                }
              ]
            })
            break
        }
      }
      return Settings
    },
    ComputedCards () {
      var GroupBy = this.AvailableCards.DonutChart.Settings.GroupBy.find((value, index, array) => { return this.MEMRead('DashBoard_EditingCard_GroupBy') === value.Name })
      var CustomLabels: any[] | undefined
      if (GroupBy) CustomLabels = GroupBy.CustomLabel
      return this.CardsContent.map((value, index) => {
        if (index === this.EditingCard) {
          var card = value
          // main Settings --------------------------------------------------------------
          card.Data.CardName = this.MEMRead('DashBoard_EditingCard_CardName')
          if (this.MEMRead('DashBoard_EditingCard_CustomLabel')) card.Data.CustomLabel = this.MEMRead('DashBoard_EditingCard_CustomLabel')
          card.Settings.Size = this.MEMRead('DashBoard_EditingCard_CardSize')
          if (this.MEMRead('DashBoard_EditingCard_CardFixedSizeEnabled')) {
            card.Settings.FixedSize = this.MEMRead('DashBoard_EditingCard_CardFixedSize')
          } else {
            card.Settings.FixedSize = undefined
          }
          // Donut Card Settings --------------------------------------------------------------
          switch (value.Data.CardComponent) {
            case 'CookiesDonutCard':
              if (this.MEMRead('DashBoard_EditingCard_Type')) card.Data.CardData.Type = this.AvailableCards.DonutChart.Settings.Type.find((value) => { return value.Name === this.MEMRead('DashBoard_EditingCard_Type') })?.Value
              if (this.MEMRead('DashBoard_EditingCard_GroupBy')) card.Data.CardData.GroupBy = this.AvailableCards.DonutChart.Settings.GroupBy.find((value) => { return value.Name === this.MEMRead('DashBoard_EditingCard_GroupBy') })?.Value
              if (this.MEMRead('DashBoard_EditingCard_OrderBy')) card.Data.CardData.OrderBy = this.AvailableCards.DonutChart.Settings.OrderBy.find((value) => { return value.Name === this.MEMRead('DashBoard_EditingCard_OrderBy') })?.Value
              if (CustomLabels && this.MEMRead('DashBoard_EditingCard_CustomLabel')) card.Data.CardData.CustomLabel = CustomLabels.find((value: { Name: any }) => { return value.Name === this.MEMRead('DashBoard_EditingCard_CustomLabel') })?.Value
              card.Data.CardData.Legend = this.MEMRead('DashBoard_EditingCard_Legend')
              card.Data.CardData.Similarity = this.MEMRead('DashBoard_EditingCard_Similarity')
              card.Data.CardData.Unit = this.MEMRead('DashBoard_EditingCard_Unit')
              break
          }
          return card
        } else return value
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading = true
      this.Get('Config', '?Name=DashBoardCards').then(response => {
        this.CardsContent = JSON.parse(response.Value)
        if (this.CardsContent && this.CardsContent.length === 0) {
          this.CardsContent = this.DefaultCardsContent
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItems () {
      this.Get('Inventory_Items', (this.MEMRead('CurrentWarehouse') ? '?WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')).then(response => {
        this.InventoryItems = response
      }, error => {
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    MyCardIsDragging () {
      this.CardIsDragging = true
    },
    MousePlace () {
      var e :any = event
      this.DraggedCard = e.target.parentElement.parentElement
      var CardsOverNow = document.elementsFromPoint(e.clientX, e.clientY)
      var filteredArray = CardsOverNow.filter(function (element) {
        return element.classList.contains('CookiesCardPlaceHolder')
      })
      this.CardOverNow = filteredArray[0]
      if (!this.CardOverNow) {
        this.LastSwappedCard = -1
        return
      }
      var OldIndex = parseInt(this.DraggedCard.getAttribute('index'))
      var NewIndex = parseInt(this.CardOverNow.getAttribute('index'))
      if (this.LastSwappedCard === NewIndex) return
      var OldCard = this.CardsContent[OldIndex]
      var NewCard = this.CardsContent[NewIndex]
      if (NewCard && OldCard) {
        this.LastSwappedCard = OldIndex
        this.SwapArrayElements(this.CardsContent, OldIndex, NewIndex)
        // OldCard.Data.Order = NewIndex
        // NewCard.Data.Order = OldIndex
      }
      // var OldOrder = parseInt(this.DraggedCard.style.order)
      // var NewOrder = parseInt(this.CardOverNow.style.order)
      // if (this.LastSwappedCard === NewOrder) return
      // var OldCard = this.CardsContent.find(card => {
      //   return card.Data.Order === OldOrder
      // })
      // var NewCard = this.CardsContent.find(card => {
      //   return card.Data.Order === NewOrder
      // })
      // if (NewCard && OldCard) {
      //   this.LastSwappedCard = OldOrder
      //   OldCard.Data.Order = NewOrder
      //   NewCard.Data.Order = OldOrder
      // }
    },
    DraggingStopped () {
      this.CardIsDragging = false
      this.SaveConfig('DashBoardCards', JSON.stringify(this.ComputedCards))
    },
    OpenCardSettings (i :number, card :{Data: DashCardData, Settings: DashCardSettings}) {
      // main Settings --------------------------------------------------------------
      this.MEMWrite('DashBoard_EditingCard_CardName', card.Data.CardName)
      this.MEMWrite('DashBoard_EditingCard_CardSize', card.Settings.Size)
      this.MEMWrite('DashBoard_EditingCard_CustomLabel', card.Data.CustomLabel)
      if (card.Settings.FixedSize) this.MEMWrite('DashBoard_EditingCard_CardFixedSize', card.Settings.FixedSize)
      if (card.Settings.FixedSize) {
        this.MEMWrite('DashBoard_EditingCard_CardFixedSizeEnabled', true)
      } else {
        this.MEMWrite('DashBoard_EditingCard_CardFixedSizeEnabled', false)
      }
      // Donut Card Settings --------------------------------------------------------------
      switch (card.Data.CardComponent) {
        case 'CookiesDonutCard':
          this.MEMWrite('DashBoard_EditingCard_Type', this.AvailableCards.DonutChart.Settings.Type.find((value) => { return card.Data.CardData.Type === value.Value })?.Name)
          this.MEMWrite('DashBoard_EditingCard_GroupBy', this.AvailableCards.DonutChart.Settings.GroupBy.find((value) => { return card.Data.CardData.GroupBy === value.Value })?.Name)
          this.MEMWrite('DashBoard_EditingCard_OrderBy', this.AvailableCards.DonutChart.Settings.OrderBy.find((value) => { return card.Data.CardData.OrderBy === value.Value })?.Name)
          this.MEMWrite('DashBoard_EditingCard_Legend', card.Data.CardData.Legend !== false)
          this.MEMWrite('DashBoard_EditingCard_Similarity', card.Data.CardData.Similarity)
          this.MEMWrite('DashBoard_EditingCard_Unit', card.Data.CardData.Unit)
          break
      }
      this.EditingCard = i
    },
    SaveCards () {
      this.CardsContent[this.EditingCard] = this.ComputedCards[this.EditingCard]
      this.EditingCard = -1
      this.SaveConfig('DashBoardCards', JSON.stringify(this.ComputedCards))
    },
    ResetCard () {
      this.EditingCard = -1
      this.MEMClean('DashBoard_EditingCard')
    }
  },
  watch: {}
})
