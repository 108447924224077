const routes :Array<CookiesRouter> = [
  {
    path: '/Branches',
    name: 'Branches',
    meta: {
      Access: 'Branches_View' as AccessFeature
    },
    component: () => import('@/Apps/Branches/views/Branches.vue'),
    children: [
      {
        path: '/Branches/View',
        name: 'View Branches',
        component: () => import('@/Apps/Branches/views/BranchesManagment.vue'),
        meta: {
          Access: 'Branches_Read' as AccessFeature,
          LoginHeader: true
        }
      }
    ]
  }
]

export const BranchesRoutes = routes
