<template>
  <div class="W-100 D-Flex FlexWrap DateContainer" style="justify-content: center;">
    <div><span>{{LanguageSelector('From', 'من')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateFrom" class="CookiesInput color2" type="date"></div>
    <div><span>{{LanguageSelector('To', 'الى')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateTo" class="CookiesInput color2" type="date"></div>
  </div>
  <div id='CookiesCardsOrganizer' class='CookiesMainView' :class="$store.state.CookiesMemory.DashCardOnhold ? 'NoUserSelect' : ''">
    <div
      v-for='(value, index) in CookiesMainContent'
      :CardContent='value.CardContent'
      :key='(value, index)'
      :style='{ order: value.order }'
      class='CookiesCardSize CookiesCardPlaceHolder'
      :class='{ [value.cardSize]: true, CookiesCardPlaceHolderDashes: CardIsDragging }'
      @mousemove='CardIsDragging ? MousePlace() : null'
    >
      <cookiesCard
        @IamDragging='MyCardIsDragging()'
        @DraggingStopped='DraggingStopped()'
        :class='value.cardSize'
        :number='index'
        :CardContent='value.CardContent'
      />
    </div>
  </div>
</template>

<script>
// import CookiesCard from '@/Apps/DashBoard/components/CookiesCard.vue.js'

export default {
  components: {},
  beforeMount () {
    var GetItem = this.$store.state.CookiesConfig.Cards
    if (GetItem !== null & GetItem !== undefined) {
      var SavedCards = JSON.parse(GetItem)
      if (this.CookiesMainContent.length > SavedCards.length) {
        // this.EditConfig('Cards', JSON.stringify(this.CookiesMainContent))
      } else {
        this.CookiesMainContent = JSON.parse(GetItem)
      }
    }
  },
  mounted () {
    if (!this.Access('MainView')) {
      this.$router.replace('/Orders')
    }
    this.ReadConfigData()
  },
  data () {
    return {
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To,
      CardOverNow: null,
      DraggedCard: null,
      CardIsDragging: false,
      ChangingCards: false,
      OriginalConfig: {},
      CookiesMainContent: [
        {
          cardSize: 'CookiesCardSizeSM',
          order: 1,
          CardContent: 'TicketsCard'
        },
        {
          cardSize: 'CookiesCardSizeSM',
          order: 3,
          CardContent: 'CashCard'
        },
        {
          cardSize: 'CookiesCardSizeSM',
          order: 5,
          CardContent: 'CustomersOrdersCard'
        },
        {
          cardSize: 'CookiesCardSizeLG',
          order: 4,
          CardContent: 'ProfitCard'
        },
        {
          cardSize: 'CookiesCardSizeSM',
          order: 6,
          CardContent: 'DriversCard'
        },
        {
          cardSize: 'CookiesCardSizeLG',
          order: 2,
          CardContent: 'OrdersFlowCard'
        }
      ]
    }
  },
  methods: {
    ReadConfigData () {
      this.Get('Config').then(response => {
        // this.OriginalConfig = this.DeepCloner(response)
        response.forEach(element => {
          this.$store.state.CookiesConfig[element.Name] = element.Value
          this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
          this.OriginalConfig[element.Name] = element.Value
          this.OriginalConfig[element.Name + 'ID'] = element.ID
        })
        this.Console(this.OriginalConfig)
        // this.OriginalConfig = this.DeepCloner(this.$store.state.CookiesConfig)
      }, error => {
        error.CookiesError = 'Error in reading Cookies Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    MyCardIsDragging () {
      this.CardIsDragging = true
    },
    MousePlace () {
      var CookiesCardstoOrganize = document.getElementById('CookiesCardsOrganizer')
        .children
      this.DraggedCard = event.target.parentElement.parentElement.style.order
      var CardsOverNow = document.elementsFromPoint(event.clientX, event.clientY)
      // console.log(CardsOverNow)
      var filteredArray = CardsOverNow.filter(function (element) {
        return element.classList.contains('CookiesCardPlaceHolder')
      })
      this.CardOverNow = filteredArray[0]
      if (this.CardOverNow != null) {
        var CookiesCardNum = this.DraggedCard
        // console.log('this.CardOverNow: ')
        // console.log(this.CardOverNow)
        var CookiesPlaceNumber = this.CardOverNow.style.order
        if (CookiesPlaceNumber !== CookiesCardNum && !this.ChangingCards) {
          this.ChangingCards = true
          var CookiesCardsArray = Array.prototype.slice.call(CookiesCardstoOrganize)
          // console.log(CookiesCardsArray)
          var TheLastElement = CookiesCardsArray.filter(function (element) {
            return element.style.order === CookiesPlaceNumber
          })
          try {
            var TheLastElementName = this.CookiesMainContent.find(function (obj) {
              return obj.CardContent === TheLastElement[0].getAttribute('CardContent')
            })
          } catch (error) {
          }
          // console.log(TheLastElement[0].getAttribute('CardContent'))
          // console.log(TheLastElementName)
          try {
            var TheElement = CookiesCardsArray.filter(function (element) {
              return element.style.order === CookiesCardNum.toString()
            })
          } catch (error) {
          }
          try {
            var ElementName = this.CookiesMainContent.find(function (obj) {
              return obj.CardContent === TheElement[0].getAttribute('CardContent')
            })
            this.Console('CookiesPlaceNumber')
            this.Console(CookiesPlaceNumber)
            this.Console(CookiesCardNum)
            this.Console(ElementName)
            this.Console(TheLastElementName)
            if (CookiesCardNum != null && CookiesPlaceNumber != null && (CookiesPlaceNumber !== CookiesCardNum)) {
              TheLastElementName.order = null
              ElementName.order = parseInt(CookiesPlaceNumber, 10)
              TheLastElementName.order = parseInt(CookiesCardNum, 10)
              var CardsConfig = JSON.stringify(this.CookiesMainContent)
              // this.EditConfig('Cards', CardsConfig)
            }
          } catch (error) {
          }
          this.CardOverNow = null
          this.DraggedCard = null
          CookiesPlaceNumber = null
          CookiesCardNum = null
          setTimeout(() => {
            this.ChangingCards = false
          }, 500)
        }
      }
    },
    DraggingStopped () {
      this.CardIsDragging = false
      this.ChangingCards = false
    }
  }
}
</script>

<style scoped>
.CookiesMainView{
  padding-left: 5em;
  padding-right: 5em;
  margin-top: -4em;
}
.NoUserSelect>>>* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CookiesCardPlaceHolder {
  display: block;
  margin: 12px;
  /* border: 2px dashed rgba(0, 0, 0, 0); */
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-property: opacity;
  display: flex;
}
.CookiesCardPlaceHolderDashes {
  border: 2px dashed rgba(51, 51, 51, 0.39);
}

@media (max-width: 1000px) {
  .CookiesMainView{
    margin-top: -1em;
  }
}
</style>
