
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    CookiesSettings: { type: Array as PropType<Array<AdminPanelFields>> },
    FooterButtons: { type: Array as PropType<Array<'save' | 'reset' | 'close'>> },
    Saving: { default: false, type: Boolean }
  },
  data () {
    return {
      Opened: true
    }
  },
  computed: {
    FilteredGroups () {
      var groups :Array<any> = this.CookiesSettings as Array<AdminPanelFields>
      return groups.map(group => {
        var computedSettings = {} as any
        Object.keys(group).forEach(key => {
          if (Array.isArray(group[key])) {
            var Filtered = group[key].filter((element :any) => {
              return element.Visible === undefined || element.Visible
            })
            computedSettings[key] = Filtered
          } else {
            computedSettings[key] = group[key]
          }
        })
        console.log(computedSettings)
        // if (group.RadioButtons) {
        //   computedSettings.RadioButtons = group.RadioButtons.filter(element => {
        //     console.log(element.Visible)
        //     return element.Visible === undefined || element.Visible
        //   })
        // }
        return computedSettings
      }) as Array<AdminPanelFields>
    }
  },
  // ---------------------------------------------------
  methods: {
    Clicked () {
      this.Opened = !this.Opened
    },
    Reset () {}
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "113e5028": (_ctx.Theme.Light3),
  "16a5b8a2": (_ctx.Theme.UserColors.fifth),
  "0e43243e": (_ctx.Theme.UserColors.second_50),
  "533a54d8": (_ctx.Theme.UserColors.fourth_50),
  "bd5aa9bc": (_ctx.Theme.UserColors.fourth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__