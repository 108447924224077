
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'Whatsapp Integration',
  data () {
    return {
      Saving: false,
      OriginalConfig: {} as any
    }
  },
  computed: {
    Verified () {
      return this.$store.state.CookiesConfig.WhatsappStatus
    }
  },
  mounted () {
    this.ReadConfigData()
    this.MEMWrite('WhatsAppPhoneNumberID', this.$store.state.CookiesConfig.WhatsappPhoneNumberID)
    this.MEMWrite('WhatsAppToken', this.$store.state.CookiesConfig.WhatsappToken)
  },
  methods: {
    SaveAndVerify () {
      this.Saving = true
      this.$store.state.CookiesConfig.WhatsappToken = this.MEMRead('WhatsAppToken')
      this.$store.state.CookiesConfig.WhatsappPhoneNumberID = this.MEMRead('WhatsAppPhoneNumberID')
      this.$store.state.CookiesConfig.WhatsappStatus = 'false'
      this.EditWhatsappConfig('WhatsappToken', this.MEMRead('WhatsAppToken'))
      this.EditWhatsappConfig('WhatsappPhoneNumberID', this.MEMRead('WhatsAppPhoneNumberID'))
      this.EditWhatsappConfig('WhatsappStatus', 'false')
      this.OriginalConfig.WhatsappStatus = 'false'
      var Value = {}
      const RequestOptions = {
        method: 'POST',
        body: JSON.stringify(Value),
        headers: {
          Authorization: 'Bearer ' + this.$store.state.CookiesConfig.WhatsappToken
        }
      } as any
      fetch('https://graph.facebook.com/v14.0/' + this.$store.state.CookiesConfig.WhatsappPhoneNumberID, RequestOptions)
        .then(CookiesResponse => {
          if (CookiesResponse.ok) {
            this.PushNotification(this.Translate('Verified Succesfully'))
            this.$store.state.CookiesConfig.WhatsappStatus = 'true'
            this.EditWhatsappConfig('WhatsappStatus', 'true')
            this.OriginalConfig.WhatsappStatus = 'true'
            this.Saving = false
          } else {
            this.PushNotification(this.Translate('Error in Verifing'))
            this.Saving = false
          }
        })
        .catch(error => {
          this.Saving = false
          this.Console(error)
          this.PushNotification(error)
        })
    },
    ReadConfigData () {
      this.Get('Config').then(response => {
        if (response === null) {
          return
        }
        response.forEach((element :any) => {
          this.OriginalConfig[element.Name] = element.Value
          this.OriginalConfig[element.Name + 'ID'] = element.ID
          if (element.Name === 'CategoriesImages') {
            this.OriginalConfig[element.Name] = JSON.parse(element.Value)
          }
        })
      }, error => {
        error.CookiesError = 'Error in reading Cookies Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveWhatsappConfig (attr :string, val :any) {
      var newItem = {
        Name: attr,
        Value: val,
        updatedBy: this.$store.state.UserManagment.CurrentUser.Email ? this.$store.state.UserManagment.CurrentUser.Email : ''
      }
      this.Post('POST', 'Config', newItem, '?UpdateOn=Name').then(() => {
      }, error => {
        error.CookiesError = 'Error in Saving Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditWhatsappConfig (attr :string, val :any) {
      this.SaveWhatsappConfig(attr, val)
    }
  }
})
