
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      CookiesData: [] as Array<InventoryTransfer>,
      Loading: [] as Array<string>,
      Warehouses: [] as Array<Warehouse>,
      InfiniteScroller: true
    }
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'R_InventoryExports_Table',
        ResID: 'ID',
        MultiLevel: this.MEMRead('R_Exports_Multi'),
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'NO.',
            Type: 'normal',
            Value: 'TransferNO',
            Bold: true,
            Sort: 'TransferNO',
            IsSearchable: true
          },
          {
            Name: 'Date',
            Type: 'datetime',
            Value: 'AddedDate'
          },
          {
            Name: 'Customer',
            Type: 'normal',
            Value: 'CustomerName',
            IsSearchable: true,
            Sort: 'CustomerName'
          },
          {
            Name: 'Status',
            Type: 'normal',
            Value: 'TranslatedStatus',
            IsSearchable: true,
            Sort: 'TranslatedStatus'
          },
          {
            Name: 'Invoice',
            Type: 'normal',
            Value: 'InvoiceNO'
          },
          {
            Name: 'Items',
            Type: 'array-normal',
            Value: 'CombinedItems',
            MultiValue: 'ItemCode'
          },
          ...this.MEMRead('R_Exports_Multi') ? [{
            Name: 'Quantity',
            Type: 'array-normal',
            Value: 'CombinedItems',
            Bold: true,
            MultiValue: 'Quantity'
          }] : []
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectMemory: true,
        PrintControls: true,
        TableSelectDefaults: true,
        InfiniteScroller: this.InfiniteScroller,
        DefaultSort: {
          Direction: 1,
          Path: 'TransferNO'
        }
      } as CookiesTable
      return Table
    },
    FilteredData () {
      var Search = this.MEMRead('R_Exports_Search')
      var NoInvoice = this.MEMRead('R_Exports_NoInvoice')
      // var Category = this.MEMRead('R_Exports_Category_Value')
      return this.CookiesData.filter((element :any) => {
        try {
          if (NoInvoice && element.InvoiceNO && element.InvoiceNO?.trim() !== '') return false
        } catch (error) {
        }
        // if (Category && Category !== '' && Category.ID && element.CategoryID !== Category.ID) return false
        var Searched = this.CookiesSearcher(element, Search)
        return Searched
      }).map((element :any) => {
        return { ...element, TranslatedStatus: this.Translate(element.Status) }
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.ReadWarehouses()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Transfers', '?Type=[Export,BranchesTransfer]' + '&' + this.DateAPIURL()).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadWarehouses () {
      this.Loading.push('Loading')
      this.Get('Inventory_Warehouses').then(response => {
        this.Warehouses = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      this.InfiniteScroller = false
      setTimeout(() => {
        var bodd = document.getElementById('TableToPrint')
        if (!bodd) return
        this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
      }, 200)
      setTimeout(() => {
        this.InfiniteScroller = true
      }, 500)
    },
    DownloadExcel () {
      var DatatoExport :any = this.MEMRead(this.TableSettings.CookiesID).map((element :any) => {
        var addedspecs :any = {}
        // if (element.Specifications && element.Specifications[0] && element.Specifications[0].Name) addedspecs[element.Specifications[0].Name] = element.Specifications[0].Description
        // if (element.Specifications && element.Specifications[1] && element.Specifications[1].Name) addedspecs[element.Specifications[1].Name] = element.Specifications[1].Description
        return {
          No: element.TransferNO,
          AddedDate: this.DateFormatter(element.AddedDate),
          Time: this.DateFormatter(element.AddedDate, 'Time'),
          LastEdit: this.DateFormatter(element.UPDTime),
          Customer: element.CustomerName,
          Status: element.TranslatedStatus
        }
      })
      this.ExportFromJSON(DatatoExport, 'Exports Report')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
    }
  }
})
