
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Placeholder: { default: undefined, type: String },
    Default: { default: undefined, type: String },
    Res: { type: String },
    ErrorData: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean }
  },
  data () {
    return {
      Opened: false,
      Selecting: false,
      Loading: false,
      SelectedImgUrl: ''
    }
  },
  methods: {
    Clicked () {
      this.Selecting = true
    },
    selectionDone () {
      setTimeout(() => {
        CheckLoading()
      }, 100)
      const _this = this
      function CheckLoading () {
        if (!_this.Loading) {
          _this.Selecting = false
        }
      }
    },
    UserImageChanged () {
      this.Loading = true
      var BeforeSelection = this.MEMRead(this.Res)
      const Myevent: any = event
      var file = Myevent.target.files[0]
      if (file) {
        this.MEMWrite(this.Res, file)
        this.SelectedImgUrl = URL.createObjectURL(file)
        this.MEMWrite(this.Res + '_ObjectURL', this.SelectedImgUrl)
        // this.MEMWrite(this.Res, this.SelectedImgUrl)
        this.$emit('selected', file)
      } else {
        this.SelectedImgUrl = BeforeSelection
      }
      this.Loading = false
      this.Selecting = false
    }
  },
  created () {
    if (this.Default !== undefined) {
      this.SelectedImgUrl = this.Default
    }
  },
  computed: {},
  unmounted () {
    if (this.Res) this.MEMClean(this.Res)
  }
})
