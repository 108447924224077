
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' }
  },
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<FinanceAccount>
    }
  },
  computed: {
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name']
          },
          {
            Type: 'Normal',
            Path: ['Code'],
            Name: 'Code',
            Sort: ['Code'],
            Bold: true
          },
          {
            Type: 'Normal',
            Path: 'Type',
            Name: 'Type'
          },
          {
            Type: 'Normal',
            Path: ['Description'],
            Name: 'Description'
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Accounts').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Accounts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CancelOrder (order :any) {
      if (!this.Access('MRP_ManufacturingOrders_Edit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Remove Order') + ' : ' + order.ID, { Text: this.Translate('Confirm cancelling manufacturing order #') + order.ID, URL: ['MRP_ManufacturingOrders_Edit', '?ID=' + order.ID], Method: 'PATCH', RedButton: true, Icon: 'Removed', Data: { op: 'replace', path: '/IsCanceled', value: true } }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditOrder (ManufacturingOrder :any) {
      if (!this.Access('MRP_ManufacturingOrders_Edit')) {
        return
      }
      this.OpenDialog('MRP_ManufacturingOrder_Dialog', this.Translate('Edit Manufacturing Order') + ' : ' + '#' + ManufacturingOrder.ID, { Data: ManufacturingOrder, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveProduct (Product :any) {
      this.OpenDialog('Delete_Dialog', this.Translate('Deleting Product'), [['Products/Delete', '?Id=' + Product.ID], Product.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
