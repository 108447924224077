
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ShowFilter: false,
      Search: '',
      PhoneSearchEnabled: false,
      Buttons: [] as Array<FunctionalTableButton>,
      Filter: undefined as FilterObject | undefined
    }
  },
  computed: {
    Tabs () {
      return [
        { Name: this.Translate('Catalogs'), Link: 'Catalogs' }
      ]
    },
    RouteName () {
      return this.$route.name
    },
    This () {
      return this
    }
  },
  methods: {},
  watch: {
    RouteName: function (newVal, oldVal) { // watch it
      this.Buttons = []
      this.Filter = undefined
    }
  }
})
