<template>
<div class="CookiesRow W-75" style="margin-top: 4em; font-size: 1.1em;">
  <!-- User Image -------------------------- -->
  <div class="CookiesRow">
    <div class="UserImage" :style="'background-image: url(' + DefaultImageHandler($store.state.UserManagment.CurrentUser.ImgURL, $store.state.CookiesConfig.UserImage) + ');'"></div>
  </div>
  <!-- UserName -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="M-Auto F1_5em F-Bold">{{$store.state.UserManagment.CurrentUser.UserInfo.Name}}</div>
  <!-- PhoneNumber -------------------------- -->
  <!-- <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.UserInfo.PhoneNumber}}</div> -->
  <!-- Email -------------------------- -->
  <!-- <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.UserInfo.Email}}</div> -->
  <!-- Email -------------------------- -->
  <!-- <div class="CookiesRowSplitter"/>
  <div class="M-Auto"><button @click="ChangePassword" class="CookiesButton">{{Translate('Change Password')}}</button></div> -->
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Account Settings',
  data () {
    return {}
  },
  computed: {},
  methods: {
    ChangePassword () {
      this.OpenDialog('Security_ChangePassword_Dialog', 'Change Password', this.$store.state.UserManagment.CurrentUser.UserInfo.Email) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  }
}
</script>
