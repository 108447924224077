
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// ////////////////////////////
export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  props: {
    DialogIndex: Number,
    DialogData: { default: () => {}, type: Object as PropType<Invoice> }
  },
  data () {
    return {
      SelectedInvoice: 'Original'
    }
  },
  computed: {},
  methods: {
    PrintInvoice () {
      this.Console('s')
    }
  }
})
