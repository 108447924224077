import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be43b6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"border-radius":"var(--rad3)","padding":"0.2em"} }
const _hoisted_2 = ["name", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.Name ==='' ? {'padding-right': '0px'} : {'padding-right': '0.2em'}),
    class: _normalizeClass(["M-Auto", _ctx.Disabled ? 'CookiesInputDisabled' : ''])
  }, [
    _createElementVNode("label", {
      style: _normalizeStyle(_ctx.Name ==='' ? _ctx.$store.getters.Direction === 'ltr' ? {'padding-right': '0px'} : {'padding-left': '0px'} : ''),
      class: _normalizeClass(["F-Bold SwitchInput", _ctx.DirectionalCheckBoxContainerClass])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.Name), 1),
      _createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.OnChange && _ctx.OnChange(...args))),
        type: "radio",
        name: _ctx.Res,
        checked: _ctx.Checked
      }, null, 40, _hoisted_2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.DirectionalCheckBoxClass)
      }, null, 2)
    ], 6)
  ], 6))
}