<template>
<div class="M-Auto ReportFontSize">
  <div class="D-Flex ReportFilter">
    <input v-model="SearchInput" class="CookiesInput F1_5em" :placeholder="LanguageSelector('Search', 'بحث')" type="text">
    <div class="D-Flex" style="flex:3">
      <CookiesDropDown @slection_changed="ReadJournalEntry(CurrentUserAccountID)" :Res="'R_Drivers_Driver'" :Type="'Object'" :Selector="['Employee', 'Name']" :Name="LanguageSelector('Driver', 'السائق')" :Options="CookiesDataDrivers"></CookiesDropDown>
      <div @click="printIT" class="PrintButton D-Flex RowMargin">
        <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
        <span class="F-Bold M-Auto">Print</span>
      </div>
    </div>
  </div>
  <div class="CookiesRowSplitter"></div>
  <CookiesTable id="TableToPrint" :Loading="Loading" :SaveClientSettings="false" :DefaultSort="DefaultSort" :TableName="TableName" :Data="FilteredData" :Fields="Fields" :Search="SearchInput"></CookiesTable>
</div>
</template>

<script>
export default {
  props: {
    DateFrom: { type: String },
    DateTo: { type: String }
  },
  data () {
    return {
      SearchInput: '',
      Orders: [],
      CookiesDataDrivers: [],
      CookiesDataJournalEntries: [],
      CookiesDataLines: [],
      Loading: true
    }
  },
  computed: {
    FilteredData () {
      var Filtered = []
      this.Console(this.$store.state.CookiesMemory.R_Drivers_Driver, 'color: black; background: yellow')
      if (this.$store.state.CookiesMemory.R_Drivers_Driver === undefined) {
        return []
      }
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name) >= 0)) {
        this.CookiesDataDrivers.forEach(Driver => {
          var FilterIt = true
          if (FilterIt) {
            var DeliveredLedger = {}
            var DriversLedgers = this.DeepFetcher(Driver, ['DriverBalance', 'ledgers'])
            if (this.ObjectFinder(DriversLedgers, ['Type'], 12)) {
              DeliveredLedger = this.ObjectFinder(DriversLedgers, ['Type'], 12)
              DeliveredLedger.NetBalance = DeliveredLedger.Debited - DeliveredLedger.Credited
            }
            Driver.DeliveredLedger = DeliveredLedger
            Filtered.push(Driver)
          }
        })
      } else {
        Filtered = this.CookiesDataJournalEntries
        // this.CookiesDataJournalEntries.forEach(entry => {
        //   entry.Amount = -1 * this.CreditMapper(entry.Type, entry.Amount)
        //   entry.Type = this.CreditMapper(entry.Type)
        //   var FilterIt = true
        //   if (FilterIt) {
        //     Filtered.push(entry)
        //   }
        // })
      }
      return Filtered
    },
    DefaultSort () {
      if (this.$store.state.CookiesMemory.R_Drivers_Driver === undefined) {
        return { value: 1, name: ['Employee', 'Name'] }
      }
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name) >= 0)) {
        return { value: 1, name: ['Employee', 'Name'] }
      } else {
        return { value: -1, name: ['Date'] }
      }
    },
    Fields () {
      if (this.$store.state.CookiesMemory.R_Drivers_Driver === undefined) {
        return []
      }
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name) >= 0)) {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Driver', 'السائق'),
            value: ['Employee', 'Name'],
            sort: true
          },
          {
            name: this.LanguageSelector('Phone Number', 'الهاتف'),
            value: ['Employee', 'PhoneNumber'],
            sort: true
          },
          {
            name: this.LanguageSelector('Balance In', 'الايرادات'),
            value: ['DeliveredLedger', 'Credited'],
            sort: true,
            type: 'number',
            pricetag: true
          },
          {
            name: this.LanguageSelector('Balance Out', 'المدفوعات'),
            value: ['DeliveredLedger', 'Debited'],
            sort: true,
            type: 'number',
            pricetag: true
          },
          {
            name: this.LanguageSelector('Total', 'الاجمالي'),
            value: ['DeliveredLedger', 'NetBalance'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          }
        ]
      } else {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Date', 'التاريخ'),
            value: ['Date'],
            sort: true,
            type: 'date'
          },
          {
            name: this.LanguageSelector('Time', 'الوقت'),
            value: ['Date'],
            sort: true,
            type: 'time'
          },
          {
            name: this.LanguageSelector('Order ID', 'رقم الطلب'),
            value: ['RefId', 0, 'ID']
          },
          {
            name: this.LanguageSelector('Amount', 'القيمة'),
            value: ['Amount'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Type', 'النوع'),
            value: ['CreditType'],
            sort: true
          },
          {
            name: this.LanguageSelector('Description', 'الوصف'),
            value: this.LanguageSelector(['Description'], ['DescriptionAr'])
          }
        ]
      }
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    },
    TableName () {
      var ENG = 'Account report'
      var ARA = 'كشف حساب'
      if (this.$store.state.CookiesMemory.R_Drivers_Driver && !(['All', 'الكل'].indexOf(this.DeepCloner(this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name)) >= 0)) {
        ENG = 'Account report' + 'for Driver (' + this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name + ') from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To
        ARA = 'كشف حساب' + ' للسائق (' + this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name + ') من: ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To
      } else {
        ENG = 'Drivers Live cash report'
        ARA = 'كشف الحالة الماليه المباشر للسائقين'
      }
      return this.LanguageSelector(ENG, ARA)
    },
    CurrentUserAccountID () {
      try {
        var TheUser = this.MEMRead('R_Drivers_Driver')
        return this.DeepFetcher(TheUser, ['DriverBalance', 'ID'])
      } catch (error) {
        return 0
      }
    }
  },
  mounted () {
    this.ReadDrivers()
    if (!this.$store.state.UserManagment.Status) {
      this.$router.replace('/SignIn')
    }
  },
  methods: {
    ReadJournalEntry (AccountID) {
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Drivers_Driver.Employee.Name) >= 0)) {
        return
      }
      this.Loading = true
      this.Get('Financial/JournalEntry?AccountID=' + AccountID + '&' + this.DateAPIURL).then(response => {
        this.CookiesDataJournalEntries = response.map(element => {
          element.Amount = -this.CreditMapper(element.Type, element.Amount)
          element.CreditType = this.CreditMapper(element.Type)
          return element
        })
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Journal Entries'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Loading = true
      this.Get('Employee/getdriver').then(response => {
        this.CookiesDataDrivers = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      this.$store.state.Printing = bodd.cloneNode([true])
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadJournalEntry(this.CurrentUserAccountID)
      this.ReadDrivers()
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadJournalEntry(this.CurrentUserAccountID)
      this.ReadDrivers()
    }
  }
}
</script>
<style scoped>
.RowMargin{
  margin: auto 0.5em;
}
.CookiesInput{
  padding: 5px 10px;
  border: solid 1px var(--wshade0);
  filter: drop-shadow(var(--shadow0));
  /* background: var(--colorw2); */
}
.PrintButton{
  border-radius: 5em;
  background: v-bind('Theme.BG2');
  padding: 0.7em 1.2em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
.CookiesSelectBotton{
  margin: auto 0.5em !important;
}
@media (max-width: 800px) {
  .ReportFilter{
    flex-direction: column;
  }
}
</style>
