
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    CookiesData: { default: () => { return {} }, type: Object as PropType<DashCardData> },
    CookiesSettings: { default: () => { return {} }, type: Object as PropType<DashCardSettings> },
    FixedStyle: { default: {} }
  },
  created () {
    window.addEventListener('mousemove', this.CookiesCardMover)
    window.addEventListener('keyup', this.DetectKey)
  },
  data () {
    return {
      CardOnHold: false,
      Loading: false,
      CardData: [],
      // //
      ClickedDomElement: null as HTMLElement | null,
      CardInitialPlace: undefined as DOMRect | undefined,
      MousXInitial: 0 as number,
      MousYInitial: 0 as number
    }
  },
  components: {},
  emits: ['IamDragging', 'DraggingStopped', 'Settings'],
  methods: {
    CookisCardHolder (event :MouseEvent) {
      var MainAppContainer = document.getElementById('MainAppContainer')
      var Target :any = event.target
      this.ClickedDomElement = Target.parentNode as HTMLElement// getting the clicked dom
      if (!this.ClickedDomElement || !MainAppContainer) return
      this.CardInitialPlace = this.ClickedDomElement.getBoundingClientRect() // getting intial place of the clicked card
      this.MousXInitial = event.clientX // getting initial x position of the mouse
      this.MousYInitial = event.clientY // getting initial y position of the mouse
      this.CardOnHold = true // defining the status of the card to on hold
      // removing text and other selections
      this.$store.state.CookiesMemory.DashCardOnhold = true
      this.CookiesCardMover()
      // var WindoweSelection = window.getSelection()
      // var DocumentSelection = document.getSelection()
      // if (WindoweSelection) {
      //   WindoweSelection.removeAllRanges()
      // } else if (DocumentSelection) {
      //   DocumentSelection.empty()
      // }
      this.$emit('IamDragging')
    },
    CookisCardPlacer () {
      if (this.CardOnHold) {
        this.CardOnHold = false // defining the status of the card to not hold
        this.$store.state.CookiesMemory.DashCardOnhold = false
        if (!this.ClickedDomElement) return
        this.ClickedDomElement.style.top = ''
        this.ClickedDomElement.style.left = ''
        this.ClickedDomElement = null
        this.CardInitialPlace = undefined
        this.MousXInitial = 0
        this.MousYInitial = 0
      }
      this.$emit('DraggingStopped')
    },
    CookiesCardMover () {
      var Cardevent = event as MouseEvent
      var MainAppContainer = document.getElementById('MainAppContainer')
      if (this.CardOnHold && this.ClickedDomElement && this.CardInitialPlace && MainAppContainer) {
        var MouseXNow = Cardevent.clientX
        var MouseYNow = Cardevent.clientY
        var PositionXDef = MouseXNow - this.MousXInitial
        var PositionYDef = MouseYNow - this.MousYInitial
        // console.log(PositionXDef, PositionYDef)
        // console.log(this.CardInitialPlace)
        // console.log(this.MousXInitial, this.MousYInitial)
        var CardNewX = this.CardInitialPlace.x + PositionXDef
        var CardNewY = this.CardInitialPlace.y + PositionYDef
        this.ClickedDomElement.style.top = CardNewY.toString() + 'px'
        this.ClickedDomElement.style.left = CardNewX.toString() + 'px'
      }
    },
    DetectKey () {
      var KeyEvent = event as KeyboardEvent
      if (KeyEvent.key === 'Escape') {
        this.CookisCardPlacer()
      }
    },
    OpenCardSettings (event: MouseEvent) {
      event.preventDefault()
      event.stopPropagation()
      this.$emit('Settings')
    }
  },
  computed: {
    CardSizeNow () {
      if (this.CardInitialPlace != null) {
        return {
          minWidth: `min(90vw,${this.CardInitialPlace.width}px)`,
          minHeight: this.CardInitialPlace.height + 'px',
          maxWidth: `min(90vw,${this.CardInitialPlace.width}px)`,
          maxHeight: this.CardInitialPlace.height + 'px'
        }
      } else {
        return {
          minWidth: '',
          minHeight: '',
          maxWidth: '',
          maxHeight: ''
        }
      }
    }
  },
  unmounted () {
    window.removeEventListener('mousemove', this.CookiesCardMover)
    window.removeEventListener('keyup', this.DetectKey)
  }
})
