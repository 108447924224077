
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      DeleteConfirmed: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<GeneralAcceptDialogData> }
  },
  computed: {
    FixedData () {
      return {
        Text: '',
        ButtonText: this.Translate('Confirm'),
        PostMethod: 'POST',
        PostAPI: '',
        PostData: {},
        ...this.DialogData
      } as GeneralAcceptDialogData
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  methods: {
    Confirm () {
      var DialogsToClose = 1
      if (this.FixedData.CloseMyDialog) {
        DialogsToClose = 2
      }
      this.Saving = true
      this.Post(this.FixedData.PostMethod, this.FixedData.PostAPI, this.FixedData.PostData, this.FixedData.AddQuery).then(response => {
        this.DeleteConfirmed = true
        this.Saving = false
        if (this.DialogData?.AfterDialogFunction) {
          this.DialogData.AfterDialogFunction(this.DialogData?.AfterDialogFunctionValue)
        }
        this.$router.go(-1 * DialogsToClose)
      }, error => {
        this.Saving = false
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
})
