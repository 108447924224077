
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { isArray } from '@vue/shared'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  created () {
    this.ReadCookiesData()
    var UserView = this.LocalRead('CookiesCatalogViewType_' + this.$route.params.CatalogID)
    if (UserView !== null) {
      this.ViewType = UserView
    }
    var UserSelectedItems = this.LocalRead('CookiesCatalog_' + this.$route.params.CatalogID)
    if (UserSelectedItems !== null) {
      this.SelectedCatalogItems = UserSelectedItems
    }
  },
  data () {
    return {
      CatalogID: null,
      Loading: ['Loading'] as Array<string>,
      CookiesData: {} as any,
      CatalogAvailable: false,
      ErrorText: 'Cannot Find Catalog',
      SelectedCatalogItems: [] as Array<CatalogItem>,
      ViewType: 'GRID' as ViewType,
      OnlySelected: false,
      ImageViewerImages: [] as Array<CatalogItem>,
      Categories: [] as Array<any>
    }
  },
  computed: {
    SortedItems () {
      var ModifiedSorter = this.CookiesData.Items.map((element :any) => {
        var NewElem = element
        NewElem.Name = parseInt(element.Name)
        return NewElem
      })
      return this.GeneralSort(ModifiedSorter, 'Name', 1)
    },
    FilteredItems () {
      if (this.OnlySelected) {
        return this.SortedItems.filter((Item :CatalogItem) => {
          return this.SelectedCatalogItems.some((SelectedItem) => {
            return Item.ID === SelectedItem.ID
          })
        })
      } else {
        return this.SortedItems.filter((Item :CatalogItem) => {
          return Item.Description === this.CurrentCat || this.CurrentCat === 'All'
        })
      }
    },
    CurrentCat () {
      return this.$router.currentRoute.value.query.CatCat
    },
    ImagesArray () :Array<CookiesImageView> {
      return this.FilteredItems.map((Item :CatalogItem) => {
        return { Name: Item.Code, ImgUrl: Item.ImgUrl, Selected: this.IsItemSelected(Item.ID.toString()) }
      })
    }
  },
  methods: {
    ReadCatalogData () {
      this.Get('Catalogs', '?ID=' + this.$route.params.CatalogID).then(response => {
        if (isArray(response) && response.length === 0) {
          this.CatalogAvailable = false
          this.Loading.pop()
          return
        }
        this.CookiesData = {
          Items: [],
          ...response
        }
        this.Console(response)
        if (!response.IsHidden) {
          this.CatalogAvailable = true
        }
        var AvailableCat = [] as Array<any>
        this.CookiesData.Items.forEach((element :any) => {
          if (element.Description !== '' && AvailableCat.indexOf(element.Description) === -1) {
            AvailableCat.push(element.Description)
          }
        })
        if (AvailableCat.length === 0) {
          this.SelectCat('All')
        }
        this.Categories = AvailableCat
        if (this.$route.params.ItemID !== undefined) {
          var ItemNeeded = this.CookiesData.Items.find((item :any) => {
            return item.ID.toString() === this.$route.params.ItemID
          })
          if (ItemNeeded) {
            if (ItemNeeded.Description !== '') {
              this.SelectCat(ItemNeeded.Description, ItemNeeded)
            } else {
              this.OpenImage(ItemNeeded)
            }
          }
        }
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.CatalogAvailable = false
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    IsItemSelected (ItemID: string) {
      return this.SelectedCatalogItems.some(item => {
        return item.ID === ItemID
      })
    },
    CatalogItemSelected (Item :CatalogItem) {
      this.SelectedCatalogItems.push(Item)
      this.LocalSave('CookiesCatalog_' + this.$route.params.CatalogID, this.SelectedCatalogItems)
    },
    CatalogItemDeSelected (Item :CatalogItem) {
      this.SelectedCatalogItems = this.SelectedCatalogItems.filter(item => {
        return item.ID !== Item.ID
      })
      this.LocalSave('CookiesCatalog_' + this.$route.params.CatalogID, this.SelectedCatalogItems)
      if (this.SelectedCatalogItems.length === 0) {
        this.OnlySelected = false
      }
    },
    ReadCookiesData () {
      this.ReadCatalogData()
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewUser':
          this.OpenDialog('Security_User_Dialog', this.Translate('New User'), {})
          break
      }
    },
    SelectCat (category: string | undefined, Image?: any) {
      // this.MEMWrite('ViewCatalogCategory', category)
      const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute.value.query))
      CurrentQuery.CatCat = category
      window.history.pushState({}, '', this.$route.fullPath + '?CatCat=' + category)
      var replace = false
      if (category === 'All') {
        replace = true
      }
      this.$router.push({ query: CurrentQuery, replace: replace })
      if (Image) {
        setTimeout(() => {
          this.OpenImage(Image)
        }, 100)
      }
    },
    OpenImage (item :CatalogItem) {
      var SelectedImage = this.IndexFinder(this.FilteredItems, 'ID', item.ID)
      this.OpenDialog('CookiesImageViewer', '', { Data: { Images: 'ImagesArray', FirstImage: SelectedImage, ParentThis: this } as CookiesImageViewerData }, { header: false, background: 'rgba(0,0,0,0)', FullWindow: true })
    },
    ImageSelected (i: number) {
      this.CatalogItemSelected(this.FilteredItems[i])
    },
    ImageDeSelected (i: number) {
      this.CatalogItemDeSelected(this.FilteredItems[i])
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // this.ReadCookiesData()
      }
    }
  }
})
