<template>
<div class="CookiesMainView">
  <div class="DriverInfo BG2 Shadow0 D-Flex">
    <div class="M-Auto" style="margin-right: 0;">{{LanguageSelector('Total Cash', 'اجمالي الكاش')}}<div class="F-Bold F1_25em">{{CookiesCashLedger.NetBalance}} {{$store.state.CookiesConfig.PriceUnit}}</div></div>
    <div class="M-Auto" style="margin-left: 0;">{{LanguageSelector('Number Of Orders', 'الطلبات')}}<div class="F-Bold F1_25em">{{CookiesDataOrders.length}} / {{DelivedCount}}</div></div>
  </div>
  <div class="CookiesRowSplitter"/>
  <template v-for="Order in CookiesDataOrders" :Key="Order">
    <div class="OrderCard Shadow0 P-Rel">
      <div class="P-Abs W-100 Inset0 BG2" style="border-radius: var(--rad2); z-index: -50;"></div>
      <div class="D-Flex FlexWrap W-100">
        <div class="M-Auto" style="padding: 0em; margin-right: 0; border-radius: var(--rad2);"><strong class="">#{{Order.ID}}</strong></div>
        <div class="F-Bold M-Auto"><span>{{Order.AmountTotal}}</span><span>{{$store.state.CookiesConfig.PriceUnit}}</span></div>
        <div v-if="Order.HasIssue" class="M-Auto D-Flex Pointer IssueAnimation" :ToolTip="Order.IssueNote" style="margin-left: 0; height: 1.6em; width: 1.6em;"><CookiesIcon :ID="'HasIssue'"></CookiesIcon></div>
        <div class="M-Auto D-Flex Pointer" :ToolTip="StatusMapper(Order.Status)" style="margin-left: 0; margin-right: 0.5em; height: 1.6em; width: 1.6em;"><CookiesIcon :ID="Order.Status"></CookiesIcon></div>
      </div>
      <div class="CookiesRowSplitter"/>
      <div class="D-Flex FlexWrap W-100">
        <div class="" style="margin: 0 1em;"><span class="F1_25em">{{DeepFetcher(Order, ['Client', 'Name'])}}</span></div>
        <div class="" style="margin: 0 1em;"><strong @click="OpenWhatsapp('0797810807')" class="F1_25em HoverUp">{{DeepFetcher(Order, ['Client', 'PhoneNum'])}}</strong></div>
        <div class="F-Bold M-Auto D-Flex Pointer" style="margin-left: 0; height: 1.6em; width: 1.6em; border-radius: 5em;" :ToolTip="Order.Notes"><CookiesIcon :ID="'Edited'"></CookiesIcon></div>
      </div>
      <div class="CookiesRowSplitter"/>
      <div class="EvenContainer FlexWrap W-100">
        <div class="F-Bold M-Auto" style="margin-left: 0; border-radius: 5em;"><span> ({{ Order.Line.Name }}) </span></div>
        <div class="F-Bold"><span class="F1_25em"> {{ Order.Area.Name }} -</span><span class="F1_25em">{{DeepFetcher(Order, ['Address'])}}</span></div>
      </div>
      <div class="P-Abs W-100 D-Flex DriverButtons BG4">
        <div v-if="Order.Status === 'OnDelivery'" @click="ChangeSelectedStatus('Delivered', Order)" class="DriverButton Shadow0 HoverUp BG2">
          <CookiesIcon :ID="'Delivered'"></CookiesIcon><span>{{StatusMapper('Delivered')}}</span>
        </div>
        <div @click="ChangeSelectedStatus('HasIssue', Order)" class="DriverButton Shadow0 HoverUp BG2">
          <CookiesIcon :ID="'HasIssue'"></CookiesIcon><span>{{LanguageSelector('Has Issue', 'يوجد مشكله')}}</span>
        </div>
      </div>
    </div>
  </template>
</div>
</template>
<script>
export default {
  data () {
    return {
      TheUser: '',
      CookiesCashLedger: {},
      CookiesDataOrders: []
    }
  },
  mounted () {
    this.ReadUser()
  },
  computed: {
    DelivedCount () {
      // var NotSelected = []
      return (this.CookiesDataOrders.filter(element => element.Status === 'Delivered')).length
      // return NotSelected
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    }
  },
  methods: {
    ReadUser () {
      this.Get('Employee/GetDriver?ID=' + this.$store.state.UserManagment.CurrentUser.Id).then(response => {
        this.ApplyUserData(response)
      }, error => {
        error.CookiesError = 'Error in reading Driver'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadUserOders () {
      this.Get('Order').then(response => {
        this.CookiesDataOrders = response
      }, error => {
        error.CookiesError = 'Error in reading Driver Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ApplyUserData (User) {
      this.Console(User, 'background: orange')
      this.TheUser = this.DeepCloner(User)
      var CashLedger = {
        Credited: 0,
        Debited: 0
      }
      CashLedger.NetBalance = 0
      try {
        var AllLedgers = this.DeepFetcher(this.TheUser, ['DriverBalance', 'ledgers'])
        if (AllLedgers == null) { return }
        if (this.ObjectFinder(AllLedgers, ['Type'], 12)) {
          CashLedger = this.ObjectFinder(AllLedgers, ['Type'], 12)
          CashLedger.NetBalance = CashLedger.Debited - CashLedger.Credited
        }
      } catch (error) {
      }
      this.CookiesCashLedger = CashLedger
      this.ReadUserOders()
      // this.ReadBalances()
      // this.ReadJournalEntry()
    },
    ChangeSelectedStatus (newStatus, Order = '') {
      var ApiUrlMapper = {
        Ordered: 'Order/UpdateStatus?NewStatus=14',
        Collecting: 'Order/UpdateStatus?NewStatus=1',
        Collected: 'Order/UpdateStatus?NewStatus=2',
        Received: 'Order/UpdateStatus?NewStatus=3',
        OnDelivery: 'Order/UpdateStatus?NewStatus=4',
        Redirected: 'Order/UpdateStatus?NewStatus=7',
        Delayed: 'Order/UpdateStatus?NewStatus=6',
        Delivered: 'Order/UpdateStatus?NewStatus=10',
        Item_Lost: 'Order/UpdateStatus?NewStatus=8',
        AssignDriver: 'Order/AssignOrdersToDriver',
        Canceled: 'Order/UpdateStatus?NewStatus=5',
        HasIssue: 'Order/UpdateStatus',
        RemoveIssue: 'Order/UpdateStatus?HasIssue=2&IssueNote=',
        Returned: 'Order/UpdateStatus?NewStatus=12',
        Delete: 'Order/UpdateStatus?NewStatus=15'
      }
      var DataToSend = {
        Driver: false,
        Date: false,
        Items: false,
        Money: false
      }
      var OrdersIDs = []
      if (Order !== '') {
        OrdersIDs = [Order.ID]
      } else {
        OrdersIDs = this.AttributeArray(this.SelectedOrders, 'ID')
      }
      DataToSend.NewStatus = newStatus
      DataToSend.OldStatus = this.SameStatus
      DataToSend.OrdersList = OrdersIDs
      DataToSend.AmountTotal = ''
      DataToSend.CustomerID = ''
      DataToSend.OlderAreaFees = ''
      DataToSend.ApiUrl = ApiUrlMapper[newStatus]
      this.OpenDialog('OrderAction', this.LanguageSelector('Changing Orders Status', 'تعديل حالة الطلبات'), DataToSend) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadUserOders()
      this.ReadUser()
    }
  }
}
</script>
<style scoped>
.IssueAnimation{
  animation: issueanim 2s infinite ;
  transition: transform,filter 1s ease;
}
@keyframes issueanim {
  0%{
    transform: scale(1);
    filter: drop-shadow(0px 0px 0em rgba(255, 0, 0, 0));
  }
  50%{
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 0.5em rgba(255, 0, 0, 0.192));
  }
  100%{
    transform: scale(1);
    filter: drop-shadow(0px 0px 0em rgba(255, 0, 0, 0));
  }
}
.DriverButtons{
  left: 0;
  right: 0;
  height: 5em;
  border-radius: var(--rad3);
  z-index: -80;
  bottom: -3.5em;
}
.DriverInfo{
  width: 90%;
  padding: 1em;
  border-radius: var(--rad3);
}
.DriverButton{
  height: 1.5em;
  width: 7em;
  padding: 0.5em;
  border-radius: 5em;
  margin: auto;
  margin-bottom: 0.5em;
  display: flex;
  transition-property: transform,filter;
  transition: 0.5s ease;
}
.DriverButton>div{
  height: 1.5em;
  width: 1.5em;
  margin: 2 auto;
}
.DriverButton:hover{
  transform: translateY(-0.2em);
  filter: drop-shadow(var(--shadow1));
}
.DriverButton>span{
  margin: auto;
}
.OrderCard{
  width: 85%;
  padding: 1em;
  margin: 0.5em 0em;
  margin-bottom: 3.5em;
  border-radius: var(--rad3);
  /* display: flex; */
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Search */
.CookiesSearchInput{
  border-radius: 100px;
  padding: 0.5em 1em;
  margin: 0.6em 1em;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 1em;
  background: v-bind('Theme.Light3');
}
.CookiesSearchInput::placeholder{
  color: v-bind('Theme.Shade3');
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search */

.CookiesMainView{
  padding: 1em;
}
.CookiesMainContainer{
  margin-left: 0 !important;
}
@media (max-width: 500px) {
  .CookiesMainView {
      padding-right: 3px;
      padding-left: 3px;
  }
  .BigHeader{
    display: flex;
    flex-direction: column;
  }
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .DialogHeaderButton{
    margin-left: auto;
    margin-right: 1em;
  }
  .CookiesSearchInputContainer{
    margin: auto !important;
  }

}

@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
}
td, th{
  border-left: solid 0.5px rgba(136, 136, 136, 0.1);
  white-space: nowrap;
  padding: 0.9em 0.1em;
  /* display: inline-flex; */
  margin: auto;
  max-width: 23%;
}
tr{
  width: 100%;
}
tr:nth-child(even){
  background-color: var(--shade0);
}
table { border-collapse: 0px; }
td:first-child { border-radius: var(--rad3) 0px 0px var(--rad3); border: none !important;}
td:last-child { border-radius: 0px var(--rad3) var(--rad3) 0px; }
th:first-child { border: none !important;}

.CarsHeader{
  cursor: pointer;
}

.NoteCell{
  max-width: 10em;
  overflow: hidden;
  outline: none;
  cursor: text;
}
.NoteCell:focus{
  background: var(--color2);
}
.SettingsContainer{
  overflow: hidden;
}
</style>
