
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'System Config',
  data () {
    return {
      LanguageIndex: -1,
      selectedFile: null,
      OriginalConfig: {} as any,
      LanguagesArray: [] as Array<any>,
      Saving: false
    }
  },
  mounted () {
    const _this = this
    Object.keys(this.Languages).some(function (Lang) {
      _this.LanguagesArray.push({
        Name: _this.Languages[Lang].Name,
        Code: Lang
      })
    })
    this.LanguageIndex = this.IndexFinder(this.LanguagesArray, ['Code'], this.$store.state.CookiesConfig.Language)
    this.MEMWrite('SystemBackground', this.$store.state.CookiesConfig.background)
    this.MEMWrite('ComapnySettings_Name', this.$store.state.CookiesConfig.Name)
    this.ReadConfig()
  },
  computed: {},
  methods: {
    ChooseTheme (theme : string) {
      this.$store.state.CookiesConfig.Theme = theme
      this.SaveConfig('Theme', theme)
    },
    LanguageChanged (value :any) {
      this.$store.state.CookiesConfig.Language = value.Code
      this.SaveConfig('Language', value.Code)
    },
    LogoChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        console.log(response)
        console.log(response)
        this.SaveConfig('Logo', response)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    HeroImageChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        this.SaveConfig('HeroImage', response)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    FavIcoChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        this.SaveConfig('FavIco', response)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
})
