import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "W-100 H-100 IconFixer" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", {
        href: _ctx.IconsSheet + '#' + _ctx.ID,
        style: _normalizeStyle(_ctx.Styler)
      }, null, 12, _hoisted_2)
    ]))
  ]))
}