<template>
    <div class="CookiesCard Shadow0">
      <div class="CookiesCardInner Rad1 F1_25em BG7">
      <div style="grid-area: CardImage;" class="D-Flex Shadow0"><div class="CardImage Rad2" :style="'background: url(' + DefaultImageHandler(Branch.ImgUrl, DefaultImage) + ');'"></div></div>
      <div style="grid-area: CardName; margin-inline-start: 1em;" class="M-Auto D-Flex F1_25em"><span class="F1_5em M-Auto F-Bold">Branch 101</span><span class="P1em"></span><CookiesIcon :ID="'Location'" class="SQR1_5em V-Auto"></CookiesIcon><span class="P_25em"></span><span class="V-Auto">Location sakcnjalc usijvksdj nv jn</span></div>
      <!-- <div style="grid-area: CardSalesHeader;"><div class="F-Bold O-50">{{Translate('Sales')}}</div></div> -->
      <!-- =============== Headers =============== -->
      <div class="BGShade0" style="grid-area: splitter;"></div>
      <div class="F-Bold O-50 P-Rel" style="grid-area: CardSalesHeader;"><span>{{Translate('Sales')}}</span><CookiesIcon class="SQR1_5em ExpandIcon" :ID="'Expand'"></CookiesIcon></div>
      <div class="F-Bold O-50 P-Rel" style="grid-area: CardCustomersHeader;"><span>{{Translate('Customers')}}</span><CookiesIcon class="SQR1_5em ExpandIcon" :ID="'Expand'"></CookiesIcon></div>
      <div class="F-Bold O-50 P-Rel" style="grid-area: CardEmployeesHeader;"><span>{{Translate('Employees')}}</span><CookiesIcon class="SQR1_5em ExpandIcon" :ID="'Expand'"></CookiesIcon></div>
      <div class="F-Bold O-50 P-Rel" style="grid-area: CardWarehousesHeader;"><span>{{Translate('Warehouses')}}</span><CookiesIcon class="SQR1_5em ExpandIcon" :ID="'Expand'"></CookiesIcon></div>
      <!-- -------------------------------------------------- Branch Sales -->
      <div style="grid-area: CardSales;" class="ListArea W-100 H-100">
        <div class="P1em">
          <div v-for="Sale in DeepFetcher(Branch, 'Sales')" :key="Sale">
            <div class="D-Flex"><div>#</div><div>{{DeepFetcher(Sale, 'ID')}}</div><div class="P_5em"></div><div class="F-Bold">{{DecimalFixer(DeepFetcher(Sale, 'Ammount'))}} {{PriceUnit}}</div></div>
            <div class="CookiesRowSplitter"></div>
          </div>
          <div>
            <div class="O-15 F-Bold" v-if="ArrayHandler(DeepFetcher(Branch, 'Sales')).length === 0">{{Translate('No Data')}}</div>
          </div>
        </div>
      </div>
      <!-- -------------------------------------------------- Branch Employees -->
      <div style="grid-area: CardEmployees;" class="ListArea W-100 H-100">
        <div class="P1em">
          <div v-for="Employee in DeepFetcher(Branch, 'Employees')" :key="Employee">
            <span>{{DeepFetcher(Employee, 'Name')}}</span>
            <div class="CookiesRowSplitter"></div>
          </div>
          <div>
            <div class="O-15 F-Bold" v-if="ArrayHandler(DeepFetcher(Branch, 'Employees')).length === 0">{{Translate('No Data')}}</div>
          </div>
        </div>
      </div>
      <!-- -------------------------------------------------- Branch Warehouses -->
      <div style="grid-area: CardWarehouses; border-left: none;" class="ListArea W-100 H-100">
        <div class="P1em">
          <div v-for="Warehouse in DeepFetcher(Branch, 'Warehouses')" :key="Warehouse">
            <span>{{DeepFetcher(Warehouse, 'Name')}}</span>
            <div class="CookiesRowSplitter"></div>
          </div>
          <div>
            <div class="O-15 F-Bold" v-if="ArrayHandler(DeepFetcher(Branch, 'Warehouses')).length === 0">{{Translate('No Data')}}</div>
          </div>
        </div>
      </div>
      </div>
      <div class="CardButtons Shadow1 BG8Fixed">
        <div class="W-100 H-100 D-Flex" style="flex-direction: column;">
          <template v-for="Button in Buttons" :key="Button">
            <div class="CookiesRowSpacer"></div>
            <CookiesIcon :class="Button.Disabled && 'Disabled'" @click="$emit(Button.EventName, Branch)" class="HoverUp H-Auto SQR2_5em" style="z-index: 50;" :ID="Button.Icon"></CookiesIcon>
          </template>
          <div class="M-Auto"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    DecodedUnits: { default: {} },
    Branch: { default: {} },
    Sales: { default: [] },
    Buttons: { default: [] }
  },
  methods: {}
}
</script>

<style scoped>
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CARD */
.CookiesCard{
  width: 95%;
  height: 25em;
  display: inline;
  border-radius: var(--rad2);
  /* overflow: hidden; */
  transition-property: filter, transform;
  transition-duration: 0.2s;
  position: relative;
  overflow: hidden;
}
@media (max-width: 250px) {
  .CookiesCard{
    font-size: 5vw;
  }
}
.CookiesCardInner{
  margin-inline-end: -3em;
  margin-block-end: 5em;
  display: grid;
  overflow: hidden;
  border: solid 0.01em v-bind('Theme.Shade1');
  height:100%;
  width: 100%;
  user-select: none;
  grid-template-columns: 0em 18em 1fr 1fr 1fr 1fr 0em;
  grid-template-rows: 0em 3.5em 1px 0.8em 1fr 0em;
  grid-template-areas:
    'PaddingLEFT PaddingTOP PaddingTOP PaddingTOP PaddingTOP PaddingTOP PaddingRIGHT'
    'PaddingLEFT CardImage CardName CardName CardName CardName PaddingRIGHT'
    'PaddingLEFT CardImage splitter splitter splitter splitter PaddingRIGHT'
    'PaddingLEFT CardImage CardSalesHeader CardCustomersHeader CardEmployeesHeader CardWarehousesHeader PaddingRIGHT'
    'PaddingLEFT CardImage CardSales CardCustomers CardEmployees CardWarehouses PaddingRIGHT'
    'PaddingLEFT PaddingBOTTOM PaddingBOTTOM PaddingBOTTOM PaddingBOTTOM PaddingBOTTOM PaddingRIGHT';
  gap: 1em;
}
.CardButtons{
  height: calc(100%);
  position: absolute;
  width: 5em;
  bottom: 0;
  inset-inline-start: -6em;
  transition-property: inset, opacity, height;
  transition-duration: 0.2s;
  opacity: 0;
}
.CookiesCard:hover{
  transform: scale(1.02);
  z-index: 50;
  filter: drop-shadow(var(--shadow1));
}
.CookiesCard:hover .CardButtons{
  inset-inline-start: 0em;
  height: calc(100%);
  opacity: 1;
}
.CardImage{
  margin: auto;
  height: 100%;
  width: 100%;
  background-size: cover !important;
  background-position: center !important;
  box-shadow: inset var(--shadow1);
}
.ListArea{
  /* background: v-bind('Theme.Shade0'); */
  height: calc(100% - 1em);
  /* border-radius: var(--rad1); */
  overflow-y: scroll;
  border-left: solid 1px v-bind('Theme.Shade0');
  /* filter: drop-shadow(var(--shadow0)); */
}
.ListArea::-webkit-scrollbar {
  max-width: 0.5em;
}
.ExpandIcon{
  position: absolute;
  inset-inline-end: 1em;
  bottom: -0.5em;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< CARD */
</style>
