
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  data () {
    return {
      Configed: false
    }
  },
  created () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    this.MEMWrite('GlobalSaving', [])
    this.MEMWrite('GlobalSavingDone', [])
    this.MEMWrite('GlobalSavingFailed', [])
    fetch('/Cookies.config.json')
      .then((CustomConfigRes) => {
        if (CustomConfigRes.ok) {
          CustomConfigRes.json()
            .then((CustomConfig) => {
              console.log('CustomConfig')
              this.$store.state.CookiesCustomConfig = CustomConfig
              this.AfterCustomConfig()
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.AfterCustomConfig()
      })
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    AfterCustomConfig () {
      var LocalConfig = this.LocalRead('CookiesDefaultConfig')
      if (LocalConfig !== null) {
        this.$store.state.CookiesConfig = {
          ...this.$store.state.CookiesConfig,
          ...LocalConfig
        }
        this.Configed = true
      }
      this.ReadSystemConfig()
      this.CookiesAuthorization()
    },
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    },
    ReadSystemConfig () {
      this.ReadConfig().then((Configed) => {
        this.Configed = Configed as boolean
        if (!Configed) {
          var _this = this
          setTimeout(() => {
            _this.$store.state.AutoSignIn = true
            _this.ReadSystemConfig()
            _this.CookiesAuthorization()
          }, 10000)
        } else {
          console.log(this.$store.state.SystemManagment.CurrentSystems)
        }
      })
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5ec0ef60": (_ctx.$store.getters.Direction),
  "166189a6": (_ctx.Theme.color3),
  "80804d2a": (_ctx.Theme.colorBold),
  "a68ffcd4": (_ctx.$store.getters.DefaultTextAlign),
  "2d85e5a2": (_ctx.Theme.UserColors.main),
  "cebd732c": (_ctx.Theme.UserColors.second),
  "3eca6cb1": (_ctx.Theme.UserColors.third),
  "f9f127e0": (_ctx.Theme.UserColors.fourth),
  "166189aa": (_ctx.Theme.color1),
  "166189a8": (_ctx.Theme.color2),
  "03508baf": (_ctx.Theme.BG1),
  "03508bb0": (_ctx.Theme.BG2),
  "03508bb1": (_ctx.Theme.BG3),
  "03508bb2": (_ctx.Theme.BG4),
  "03508bb3": (_ctx.Theme.BG5),
  "03508bb4": (_ctx.Theme.BG6),
  "03508bb5": (_ctx.Theme.BG7),
  "03508bb6": (_ctx.Theme.BG8),
  "220b3df6": (_ctx.Theme.BG1Fixed),
  "1ea18cb8": (_ctx.Theme.BG2Fixed),
  "1b37db7a": (_ctx.Theme.BG3Fixed),
  "17ce2a3c": (_ctx.Theme.BG4Fixed),
  "146478fe": (_ctx.Theme.BG5Fixed),
  "10fac7c0": (_ctx.Theme.BG6Fixed),
  "0d911682": (_ctx.Theme.BG7Fixed),
  "0a276544": (_ctx.Theme.BG8Fixed),
  "96396d54": (_ctx.Theme.InputColor),
  "48aa625a": (_ctx.Theme.UserColors.Dimmed),
  "73409074": (_ctx.Theme.UserColors.Gradient),
  "4dcc3c80": (_ctx.Theme.Shade0),
  "4dcc3c7e": (_ctx.Theme.Shade1),
  "4dcc3c7c": (_ctx.Theme.Shade2),
  "4dcc3c7a": (_ctx.Theme.Shade3),
  "4dcc3c78": (_ctx.Theme.Shade4),
  "b4b3966a": (_ctx.Theme.BackBlur),
  "37a29b53": (_ctx.Theme.UserColors.main_25),
  "065ff4e9": (_ctx.Theme.UserColors.third_50),
  "658e500c": (_ctx.Theme.Light3),
  "0d83b00e": (_ctx.DirectionStyler.borderLeft),
  "2edd386a": (_ctx.DirectionStyler.borderRight),
  "77778992": (_ctx.TabHeaderShadower),
  "51267c54": (_ctx.CurrentBackgroundColor),
  "482c656c": (_ctx.DirectionStyler.first),
  "1fe0dcbc": (_ctx.DirectionStyler.last),
  "7c4b35db": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__