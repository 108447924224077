<template>
<div v-if="Access('CustomersAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <DialogDisabler v-if="Loading.length > 0"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Product -------------------------------------- -->
      <div class="CookiesRow FlexBreaker">
        <div class="CookiesRow D-Flex" style="min-width: 70%;">
          <input v-model="Name" :CookiesInvalidInput="!Name" :class="(ErrorsVisibilty & !Name) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="CustomerName" :placeholder="Translate('Name')" autocomplete="off">
        </div>
        <div class="CookiesRow D-Flex" style="min-width: 70%;">
          <input v-model="PhoneNumber" :CookiesInvalidInput="!PhoneValidator(PhoneNumber)" :class="(ErrorsVisibilty & !PhoneValidator(PhoneNumber)) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="PhoneNumber" :placeholder="Translate('Phone Number')" autocomplete="off">
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="CookiesRow D-Flex" style="min-width: 70%;">
          <input v-model="AddressL1" class="CookiesInput color2" type="Text" name="Address" :placeholder="Translate('Address')" autocomplete="off">
        </div>
          <div class="CookiesRowSplitter"/>
          <!-- Can Login Section -->
          <div class="CookiesRow D-Flex" style="min-width: 70%;">
            <input v-model="Email" :CookiesInvalidInput="MEMRead('D_Customer_CanLogin') && !EmailValidator(Email)" :class="(ErrorsVisibilty && MEMRead('D_Customer_CanLogin') &&  !EmailValidator(Email)) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="Email" :placeholder="Translate('Email')" autocomplete="off">
          </div>
        <template v-if="CreateCustomersAccounts">
          <div class="CookiesRowSpacer"></div>
          <div class="CookiesRow D-Flex" style="min-width: 70%;">
            <CookiesCheckBox :Default="false" :Res="'D_Customer_CanLogin'" :Name="Translate('Can Login to the System')" :PreserveMemory="false"></CookiesCheckBox>
          </div>
          <form v-if="!OldAccount" :class="MEMRead('D_Customer_CanLogin') ? '' : 'Disabled'" class="CookiesRow D-Flex" style="min-width: 70%;">
            <input v-model="Password" :type="MEMRead('D_Customer_ShowPassword') ? 'Text' : 'Password'" :CookiesInvalidInput="MEMRead('D_Customer_CanLogin') && !PasswordValidator(Password)" :class="(ErrorsVisibilty && MEMRead('D_Customer_CanLogin') &&  !PasswordValidator(Password)) && 'ErrorBorder'" class="CookiesInput color2" name="Password" :placeholder="Translate('New Password')" autocomplete="off">
            <input v-model="ConfirmPassword" :type="MEMRead('D_Customer_ShowPassword') ? 'Text' : 'Password'" :CookiesInvalidInput="MEMRead('D_Customer_CanLogin') && Password !== ConfirmPassword" :class="(ErrorsVisibilty && MEMRead('D_Customer_CanLogin') &&  Password !== ConfirmPassword) && 'ErrorBorder'" class="CookiesInput color2" name="ConfirmPassword" :placeholder="Translate('Confirm New Password')" autocomplete="off">
          </form>
          <div v-if="!OldAccount" class="CookiesRow D-Flex" style="min-width: 70%;">
            <CookiesCheckBox :Default="false" :Disabled="!MEMRead('D_Customer_CanLogin')" :Name="Translate('Show Password')" :Res="'D_Customer_ShowPassword'" :Type="'Switch'" style="margin-inline-start: 0px;"></CookiesCheckBox>
          </div>
          <!-- Can Login Section -->
          <div v-if="Access('ChangePasswords') && OldAccount" :class="MEMRead('D_Customer_CanLogin') ? '' : 'Disabled'" class="CookiesRow D-Flex" style="min-width: 70%;">
            <strong @click="ChangePassword(Email)" class="colorFourth M-Auto Pointer">{{Translate('Change Password')}}</strong>
          </div>
        </template>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('CustomersAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
    <button @click="SaveCustomer()" :style="'background: ' + Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Save')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpGender = {}
  },
  created () {},
  data () {
    return {
      Editing: false,
      OriginalData: {},
      Name: '',
      PhoneNumber: '',
      AddressL1: '',
      Email: '',
      Password: '',
      ConfirmPassword: '',
      ErrorsVisibilty: false,
      Customer: [],
      Saving: false,
      OldAccount: false,
      Loading: [],
      UnitsCatolgsCount: 1
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    this.LoadEditingData()
    if (!this.Access('CustomerAdd')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    LoadEditingData () {
      if (this.DialogData.Editing) {
        this.Editing = true
        this.OriginalData = this.DeepCloner(this.DialogData.Data)
        var DataImage = this.DeepCloner(this.DialogData.Data)
        this.OriginalData = {
          ID: DataImage.ID,
          Name: DataImage.Name,
          PhoneNumber: DataImage.PhoneNumber,
          AddressL1: DataImage.AddressL1,
          CanLogIn: DataImage.CanLogIn
        }
        if (this.CreateCustomersAccounts && this.OriginalData.CanLogIn) {
          this.OldAccount = true
        }
        if (!this.CreateCustomersAccounts) {
          this.OriginalData.CanLogIn = false
        }
        // --------- applying to the dialog
        this.Name = this.DeepFetcher(this.OriginalData, 'Name')
        this.PhoneNumber = this.DeepFetcher(this.OriginalData, 'PhoneNumber')
        this.AddressL1 = this.DeepFetcher(this.OriginalData, 'AddressL1')
        this.Email = DataImage.Email
        this.MEMWrite('D_Customer_CanLogin', DataImage.CanLogIn)
      }
    },
    SaveCustomer () {
      const _this = this
      if (this.Requirments(this).Incomplete) { // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      // image handling
      PostNow()
      // Post // Patch
      function PostNow () {
        let Method = 'POST'
        let API = 'Product'
        let Data = _this.NewDataToSave
        let SavingError = 'Error in Adding New Product'
        if (_this.Editing) {
          Method = 'PATCH'
          API = 'Product/Edit'
          Data = _this.CookiesPatcher(_this.OriginalData, _this.NewDataToSave)
          SavingError = 'Error in Saving Product'
          if (Data.length === 0) {
            _this.Saving = false
            _this.$router.go(-1)
            return
          }
        }
        _this.Post(Method, API, Data).then(response => {
          _this.Saving = false
          _this.CloseDialog(_this.DialogIndex)
        }, error => {
          _this.Saving = false
          error.CookiesError = SavingError
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    }
  },
  computed: {
    NewDataToSave () {
      var CurrentData = {
        ID: this.OriginalData.ID,
        Name: this.Name,
        PhoneNumber: this.PhoneNumber,
        AddressL1: this.AddressL1,
        Email: this.Email,
        CanLogIn: this.MEMRead('D_Customer_CanLogin')
      }
      if (this.CreateCustomersAccounts && this.MEMRead('D_Customer_CanLogin') && !this.OldAccount) {
        CurrentData.Password = this.Password
      }
      if (!this.CreateCustomersAccounts) {
        CurrentData.CanLogIn = false
      }
      return CurrentData
    }
  },
  watch: {
    Loading: {
      handler: function (val, oldVal) {
        if (this.DialogData.Editing && val.length === 0) {
          this.LoadEditingData()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 750px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .ProductImageHolder{
    order: -1;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

.ProductImage{
  width: 10em;
  aspect-ratio: 1;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: var(--rad1);
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.ProductImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
