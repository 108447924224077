
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      Acc: 0,
      OriginalData: {} as Section,
      Saving: false,
      SavingTable: [] as Array<'Saving'>,
      Loading: [] as Array<'Loading'>,
      Saved: undefined as boolean|undefined,
      CookiesData: {} as any,
      TransferItems: [] as any,
      Items: [] as any,
      TransferSources: [] as any,
      CustomersList: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Items', Res: 'D_Transfer_Items', Default: [] },
        { Path: 'CustomerName', Res: 'D_Transfer_Customer', ValuePath: 'Name', Default: '' },
        { Path: 'CustomerPhone', Res: 'D_Transfer_CustomerPhone', Default: '' },
        { Path: 'Type', Value: this.DialogData.Type },
        { Path: 'Notes', Res: 'D_Transfer_Note', Default: '' },
        { Path: 'SupplierID', Res: 'D_Transfers_ImportSource', ValuePath: 'ID', Default: null },
        { Path: 'SupplierName', Res: 'D_Transfers_SupplierName' },
        { Path: 'IsPack', Res: 'D_Transfer_IsPack' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadItems()
      this.ReadTransferSources()
      this.ReadCustomersList()
    },
    RemoveItem (Item :any) {
      if (!this.DialogData.Editing) {
        this.TransferItems.splice(Item.OriginalIndex, 1)
      } else {
        this.Saving = true
        console.log(Item)
        this.Post('POST', 'Inventory_Transfers_UPDATE', Item, '?Stat=DeleteTransferItem').then(() => {
          this.Saving = false
          this.ReadTransferData()
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Removing Item'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_Items').then(response => {
        this.Items = response
        try {
          if (this.CookiesData && this.CookiesData.Items && this.CookiesData.Items.length > 0) {
            this.CookiesData.Items.forEach((transitem :any) => {
              var Found = this.Items.find((item:any) => {
                return item.ID === transitem.ItemID
              })
              if (Found) {
                this.TransferItems.push(this.DeepCloner({
                  ...Found,
                  ...transitem,
                  ResID: transitem.ItemID + '_' + this.Acc
                }))
                this.Acc += 1
              }
            })
          }
        } catch (error) {
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransferData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Transfers', '?ID=' + this.DialogData.Data.ID).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfer'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      this.ReadDialogData()
    },
    SaveData () {
      if (this.DialogData.Editing) {
        return
      }
      var SelectedItems = this.TransferItems.map((item :any) => {
        return {
          ItemID: item.ID,
          ItemName: item.Code,
          PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID),
          Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID)
        }
      })
      var Supplier = this.MEMRead('D_Transfers_ImportSource_Value')
      if (Supplier && Supplier.Name) {
        this.MEMWrite('D_Transfers_SupplierName', Supplier.Name)
      }
      this.MEMWrite('D_Transfer_Items', SelectedItems)
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
        PostAPI: 'Inventory_Transfers_Import',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Transfers',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID : ''
      })
    },
    InputChanged (Changed :any) {
      if (!this.DialogData.Editing) {
        return
      }
      var CurrentValue = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + Changed.field.Name + '_' + Changed.element.ResID)
      if (this.CookiesData.Items && this.CookiesData.Items.length > 0) {
        var Found = this.CookiesData.Items.find((item :any) => {
          return item.ItemID === Changed.element.ItemID
        })
        var OriginalValue = Found[Changed.field.Value]
        console.log('CurrentValue')
        console.log(CurrentValue)
        console.log(OriginalValue)
        // if (CurrentValue !== OriginalValue) {
        //   this.Post('POST', 'Inventory_Transfers_UPDATE', Item, '?Stat=DeleteTransferItem').then(() => {
        //     this.Saving = false
        //     this.ReadTransferData()
        //   }, error => {
        //     this.Saving = false
        //     error.CookiesError = 'Error in Removing Item'
        //     this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        //   })
        // }
      }
    },
    AddItem (Item: InventoryItem) {
      if (this.DialogData.Type === 'Export' && Item.AvailableQuantity === 0) {
        this.MEMClean('D_Transfer_Item', true)
        return
      }
      var found = this.TransferItems.find((element :InventoryItem) => {
        return Item.ID === element.ID
      })
      if (found) {
        return
      }
      var ModifiedItem :any = Item
      ModifiedItem.ResID = ModifiedItem.ID + '_' + this.Acc
      this.TransferItems.push(this.DeepCloner(ModifiedItem))
      this.Acc += 1
      this.MEMClean('D_Transfer_Item', true)
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    },
    ReadTransferSources () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=TransferSources').then(response => {
        this.TransferSources = response
        console.log(this.CookiesData)
        if (this.CookiesData && this.CookiesData.SupplierID) {
          var SelectedSource = this.TransferSources.find((Source :any) => {
            return Source.ID === this.CookiesData.SupplierID
          })
          if (SelectedSource) {
            this.MEMWrite('D_Transfers_ImportSource_Value', SelectedSource)
            this.MEMWrite('D_Transfers_ImportSource', SelectedSource.Name)
          }
        } else {
          this.MEMClean('D_Transfers_ImportSource')
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomersList () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=ExportCustomers').then(response => {
        this.CustomersList = response
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    this.CookiesData = this.DialogData.Data
  },
  mounted () {
    if (this.CookiesData) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as Section
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Transfer')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('TransferDIalogItems') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_Transfer_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Bold: true
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'AvailableQuantity',
            Bold: true
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 1,
            numberInput_Min: 1,
            Value: 'Quantity',
            Res_ID: 'ResID',
            numberInput_Max: this.DialogData.Type === 'Export' ? 'AvailableQuantity' : undefined
          }
          // {
          //   Name: 'IsPack',
          //   Type: 'checkBox',
          //   Value: 'IsPack',
          //   Res_ID: 'ResID'
          // }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      if (this.MEMRead('D_Transfer_IsPack') === 1) {
        Table.Fields.push({
          Name: 'Pack Size',
          Type: 'numberInput',
          Value: 'PackSize',
          Res_ID: 'ResID'
        })
      }
      if (!(this.DialogData.Editing && this.TransferItems.length === 1)) {
        Table.Fields.push({
          Name: 'Delete',
          Value: '',
          Type: 'delete',
          NoHeader: true
        })
      }
      return Table
    }
  }
})
