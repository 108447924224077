
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {
        Items: []
      } as any,
      ErrorsVisibilty: false,
      GrappedItem: null as any,
      GrappedElement: null as any,
      GrappedIndex: null as any,
      NewIndex: null as any,
      CategoriesMap: {} as any,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Catalog_Name' },
        { Path: 'Description', Res: 'D_Catalog_Description', Default: '' },
        { Path: 'ImgURL', Res: 'D_Catalog_Image', IsImage: true, Default: '' },
        { Path: 'Media', Res: 'D_Catalog_Media', Default: [] },
        { Path: 'Tags', Res: 'D_Catalog_Tags', Default: {} }
        // { Path: 'Items', Res: 'D_Catalog_Items', IDPath: 'ID', Array: [['ID'], ['Name'], ['Code'], ['Description'], ['ImgURL']], Default: [] }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadCatalog()
    },
    ReadCatalog () {
      this.Loading.push('Loading')
      this.Get('Catalogs', '/' + this.DialogData.Data.ID).then(response => {
        this.Console(response)
        response.Categories.forEach((category :any) => {
          this.CategoriesMap['Cat_' + category.Sequence] = category
        })
        var ModifiedRes = response
        this.CookiesData = ModifiedRes
        this.MEMWrite('D_Catalog_Items', ModifiedRes.Items)
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.Saving = true
      this.GlobalSave()
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Catalogs',
        OriginalData: this.OriginalData,
        PatchAPI: 'Catalogs',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '/' + this.DialogData.Data.ID : undefined
      })
    },
    DataSaved (response :any) {
      this.GlobalSaveDone()
      this.Saving = false
      // this.$router.go(-1)
    },
    PrintTable () {
      const TableDom = document.getElementById('D_Profiles_Print')
      this.PrintIT(TableDom, { PageSize: 'A4 portrait', Header: true })
    },
    AddItem () {
      this.GlobalSave()
      this.Post('POST', 'Catalogs', { Code: 'Code', Name: this.Translate('New Item'), ImgURL: '' }, `/${this.CookiesData.ID}/Items`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
      }, error => {
        this.GlobalSaveDone(false)
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', Name: this.MEMRead('D_Catalog_Items').length + 1, IsNew: true, Categories: this.AvailableCategories() } })
    },
    AddCategory () {
      this.Saving = true
      this.GlobalSave()
      this.Post('POST', 'Catalogs', { Name: this.Translate('New Category'), Description: this.Translate('Description') }, `/${this.CookiesData.ID}/Categories`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', Name: this.MEMRead('D_Catalog_Items').length + 1, IsNew: true, Categories: this.AvailableCategories() } })
    },
    EditItem (e :any, item :any, Attr :string) {
      this.Saving = true
      this.GlobalSave()
      this.Post('PATCH', 'Catalogs', { [Attr]: e?.target?.innerText }, `/${this.CookiesData.ID}/Items/${item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditItemCategory (item :any, Res: string) {
      this.GlobalSave()
      this.Saving = true
      this.Post('PATCH', 'Catalogs', { CategorySeq: this.MEMRead(`${Res}_Value`)?.Sequence }, `/${this.CookiesData.ID}/Items/${item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditCategory (e :any, category :any, Attr :string) {
      this.GlobalSave()
      this.Saving = true
      console.log(e)
      console.log(e?.target)
      this.Post('PATCH', 'Catalogs', { [Attr]: e?.target?.innerText }, `/${this.CookiesData.ID}/Categories/${category.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveItem (Item :any, Attr :string) {
      this.Saving = true
      this.GlobalSave()
      this.Post('DELETE', 'Catalogs', {}, `/${this.CookiesData.ID}/${Attr}/${Item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CustomSorting (NewSort :any) {
      this.MEMWrite('D_Catalog_Items', NewSort.SortedData)
    },
    AvailableCategories () {
      var Categories :Array<string> = []
      var CategoriesObjArray :any = []
      this.MEMRead('D_Catalog_Items').forEach((element :any) => {
        if (element.Description !== '' && Categories.indexOf(element.Description) === -1) {
          Categories.push(element.Description)
          CategoriesObjArray.push({
            Name: element.Description
          })
        }
      })
      return CategoriesObjArray
    },
    EditCategories () {
      this.OpenDialog('CategoriesTable', this.Translate('Catalog Categories Images'), { Data: this.AvailableCategories() })
    },
    Grapped (event :any, item: any, index :number, type :'Touch' | 'Mouse') {
      this.GrappedElement = event?.target
      this.GrappedItem = item
      this.GrappedIndex = index
      this.NewIndex = index
    },
    StopGrapping () {
      this.Saving = true
      this.GlobalSave()
      this.Post('PATCH', 'Catalogs', this.NewIndex, `/${this.CookiesData.ID}/ItemsOrder/${this.GrappedItem.ID}`).then(() => {
        this.GrappedItem = null
        this.GrappedIndex = null
        this.NewIndex = null
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GrappedItem = null
        this.GrappedIndex = null
        this.NewIndex = null
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // if (this.Grapping !== implements) {
      //   this.Console('this.SortIndexEdited')
      //   this.Console(this.SortIndexEdited)
      //   var SortedSorter = this.SortIndexEdited.map((element :any) => {
      //     var Newlement = element
      //     if (this.TableSettings.Sorter) {
      //       this.DeepChanger(Newlement, this.TableSettings.Sorter, element.SorterIndex)
      //     }
      //     return Newlement
      //   })
      //   this.Console(SortedSorter)
      //   this.$emit('CustomSorting', { SortedData: SortedSorter, Sorter: this.TableSettings.Sorter })
      //   this.Grapping.style.opacity = 1
      //   this.Grapping = null
      //   this.GrappedItem = {}
      // }
    },
    EditImage (Item :any, Attr:string, Res:string) {
      this.PostImage(this.MEMRead(Res)).then((value :any) => {
        this.Post('PATCH', 'Catalogs', { ImgURL: value }, `/${this.CookiesData.ID}/${Attr}/${Item.ID}`).then(() => {
          this.ReadDialogData()
          this.GlobalSaveDone()
        }, error => {
          this.GlobalSaveDone(false)
          error.CookiesError = 'Error in Editing'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      })
    },
    IsMoving (event :any) {
      // console.log(event.clientY)
      if (this.GrappedItem !== null && this.GrappedElement) {
        var Table = document.getElementById('CatalogItemsContainer')
        var TableTop = Table?.getBoundingClientRect().top
        var TableHeigth = Table?.getBoundingClientRect().height
        var RowsSize = 0
        if (TableHeigth) RowsSize = TableHeigth / this.CookiesData?.Items?.length
        var MouseTop = event.clientY
        var Top = MouseTop
        if (TableTop && RowsSize) {
          Top = Math.max(0, MouseTop - TableTop)
        }
        // console.log('scsc', Top, TableHeigth)
        if (RowsSize && Math.floor(Top / RowsSize) !== this.NewIndex) {
          this.NewIndex = Math.floor(Top / RowsSize)
          // ///////////////////////////////
          var OldArray = this.MEMRead('D_Catalog_Items')
          var NewArray = OldArray.slice(0, this.GrappedIndex).concat(OldArray.slice(this.GrappedIndex + 1))
          NewArray.splice(this.NewIndex, 0, this.GrappedItem)
          this.GrappedIndex = this.NewIndex
          this.MEMWrite('D_Catalog_Items', NewArray)
        }
      }
    },
    IsTouching () {}
  },
  created () {
    this.MEMWrite('D_Catalog_Items', [])
    const _this = this
    window.addEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.addEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.addEventListener('touchend', function () {
      _this.StopGrapping()
    })
    // if (this.DialogData.Data) {
    //   this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
    // }
    // this.MEMWrite('D_Catalog_AddedItems', [])
  },
  mounted () {
    if (this.DialogData.Data) {
      // this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
    }
    if (this.DialogData.Editing) {
      this.ReadDialogData()
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Catalog')
    const _this = this
    window.removeEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('touchend', function () {
      _this.StopGrapping()
    })
  },
  computed: {}
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "21a9f142": (_ctx.Theme.Shade1),
  "1f147ad6": (_ctx.Theme.BG6)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__