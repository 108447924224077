import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34a31c6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.TooltipElement !== null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.ToolTipStyler && _ctx.ToolTipStyler.Outer),
        class: "CookiesJSTooltip D-Flex Shadow1"
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle([_ctx.ToolTipStyler && _ctx.ToolTipStyler.Inner, {"order":"2"}]),
          class: "M-Auto P-Rel CookiesJSTooltipContent",
          innerHTML: _ctx.TooltipElement.getAttribute('ToolTip') ? _ctx.TooltipElement.getAttribute('ToolTip') : ''
        }, null, 12, _hoisted_1),
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.ToolTipStyler && _ctx.ToolTipStyler.Indicator),
          class: "ToolTipIndicator"
        }, null, 4)
      ], 4))
    : _createCommentVNode("", true)
}