
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    CatalogItemData: { type: Object as PropType<CatalogItem> },
    ViewType: { default: () => { return 'GRID' }, type: String as PropType<ViewType> },
    Selected: { default: () => { return false }, type: Boolean as PropType<boolean> }
  },
  data () {
    return {}
  },
  computed: {},
  // ---------------------------------------------------
  methods: {
    OpenImage () {
      this.$emit('OpenImage')
    },
    Deselect () {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      this.$emit('DeSelected', this.CatalogItemData)
    },
    PreventDefault () {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "677bfc33": (_ctx.Theme.BG4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__