
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  name: 'CookiesRadio',
  data () {
    return {
      Checked: this.Default
    }
  },
  mixins: [CookiesCoreMixinVue],
  props: {
    Default: { default: false },
    Name: { default: '', Type: String },
    Res: { default: '', Type: String },
    Disabled: { default: false, Type: Boolean },
    Value: { default: '', Type: String }
  },
  mounted () {
    if (this.Default) {
      this.MEMWrite(this.Res, this.Value)
    }
    if (this.MEMRead(this.Res) === undefined) {
      this.$store.state.CookiesMemory[this.Res] = ''
    } else if (this.MEMRead(this.Res) === this.Value) {
      this.Checked = true
    } else {
      this.Checked = this.Default
    }
  },
  methods: {
    OnChange () {
      this.$store.state.CookiesMemory[this.Res] = this.Value
      this.$emit('changed')
    }
  },
  computed: {
    CurrentValue () {
      return this.MEMRead(this.Res)
    },
    DirectionalCheckBoxClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxLeft'
      } else {
        return 'CookiesCheckBoxRight'
      }
    },
    DirectionalCheckBoxContainerClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxContaierLeft'
      } else {
        return 'CookiesCheckBoxContaierRight'
      }
    }
  },
  watch: {
    CurrentValue: function (newVal, oldVal) { // watch it
      if (this.MEMRead(this.Res) === this.Value) {
        this.Checked = true
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3c9ff3ba": (_ctx.Theme.UserColors.fourth),
  "88158bda": (_ctx.Theme.UserColors.third_50)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__