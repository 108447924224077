
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<InventoryTransfer>,
      Branches: [] as Array<Branch>
      // Warehouses: [] as Array<Warehouse>
    }
  },
  computed: {
    Filter () :FilterObject {
      // var WarehousesChecks = this.Warehouses.map(warehouse => {
      //   return {
      //     Name: warehouse.Code,
      //     Visible: this.Access('Inventory_Warehouses_Read'),
      //     Res: 'Transfers_Filter_' + warehouse.ID,
      //     Value: warehouse.ID,
      //     DataPath: ['WarehouseID']
      //   }
      // })
      return {
        Lists: [],
        Checks: [
          // {
          //   GroupName: 'Warehouses',
          //   Checks: WarehousesChecks
          // },
          {
            DefaultOpen: true,
            GroupName: 'Status',
            Checks: [
              {
                Name: 'New',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_New',
                Value: 'New',
                DataPath: ['Status']
              },
              {
                Name: 'Exporting',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Exporting',
                Value: 'Exporting',
                DataPath: ['Status']
              },
              {
                Name: 'Done',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Done',
                Value: 'Done',
                DataPath: ['Status']
              },
              {
                Name: 'Canceled',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Canceled',
                Value: 'Canceled',
                DataPath: ['Status']
              }
            ]
          },
          {
            DefaultOpen: true,
            GroupName: 'Invoice',
            Checks: [
              {
                Name: 'With',
                Visible: this.Access('Inventory_Transfers_AddInvoiceNO'),
                Res: 'Transfers_Filter_WithInvoice',
                Value: 'Invoiced',
                Icon: 'Done',
                DataPath: ['IsInvoiced']
              },
              {
                Name: 'Without',
                Visible: this.Access('Inventory_Transfers_AddInvoiceNO'),
                Res: 'Transfers_Filter_WithoutInvoice',
                Value: 'Not',
                Icon: 'Removed',
                DataPath: ['IsInvoiced']
              }
            ]
          }
        ],
        Dates: true
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'Export',
          this: this,
          Function: 'NewExport',
          Icon: 'Export',
          Visible: this.Access('Inventory_Transfers_Export')
        },
        {
          Name: 'Transfer Into Branch',
          this: this,
          Function: 'NewBranchesTransfer',
          Icon: 'Leveling',
          Visible: this.Access('Inventory_Transfers_BranchesTransfer')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: ['AddedDate'] },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'NO',
            Path: ['TransferNO'],
            Sort: ['TransferNO'],
            Bold: true,
            Class: 'F1_25em',
            Hash: '#',
            IsSearchable: true
          },
          {
            Type: 'DateTime',
            Path: 'AddedDate',
            Name: 'Date',
            Sort: 'AddedDate'
          },
          {
            Type: 'DateTime',
            Path: 'AddedDate',
            Name: 'Time',
            Sort: 'AddedDate',
            DateStyle: 'Time'
          },
          {
            Type: 'ActionIcons',
            Path: 'Type',
            Name: 'Type',
            Sort: 'Type',
            Style: 'width: 2em;',
            ActionIcons: [
              {
                ID: 'ItemImport',
                TooltTip: this.Translate('Import'),
                DynamicVisiblity: {
                  FixedValue: 'Import',
                  ValuePath: 'Type',
                  Operator: 'EQUAL LOWERCASE'
                },
                Style: 'cursor: default;'
              },
              {
                ID: 'Export',
                TooltTip: this.Translate('Export'),
                DynamicVisiblity: {
                  FixedValue: 'Export',
                  ValuePath: 'Type',
                  Operator: 'EQUAL LOWERCASE'
                },
                Style: 'cursor: default;'
              },
              {
                ID: 'Leveling',
                TooltTip: this.Translate('Transfer Into Branch'),
                DynamicVisiblity: {
                  FixedValue: 'BranchesTransfer',
                  ValuePath: 'Type',
                  Operator: 'EQUAL LOWERCASE'
                },
                Style: 'cursor: default;'
              }
            ]
          },
          {
            Type: 'Normal',
            Name: 'To',
            Path: ['CustomerName'],
            Sort: ['CustomerName'],
            IsSearchable: true
          },
          {
            Type: 'Multi',
            Path: 'CombinedItems',
            Name: 'Item (Pack) Quantity',
            MultiFields: {
              Path: ['ItemCode'],
              Bold: true,
              Secondary: {
                Path: 'Quantity',
                Class: 'F_85em'
              }
              // Quantity: 'Quantity'
            },
            Style: 'width: 2em;'
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              New: 'BGMain',
              Importing: 'BGMain',
              Done: 'BGGreen'
            },
            StatusStyleColors: {
              Importing: 'yellow',
              Exporting: 'yellow',
              Canceled: 'var(--colorr1)',
              Done: 'var(--colorg2)',
              undefined: ''
            },
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Invoice',
            Path: ['InvoiceNO'],
            Sort: ['InvoiceNO'],
            IsSearchable: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Done',
                // TooltTip: 'Importing Done',
                Emits: 'AddInvoice',
                DynamicVisiblity: {
                  FixedValue: 'Invoiced',
                  Operator: 'EQUAL',
                  ValuePath: 'IsInvoiced'
                }
              },
              {
                ID: 'Removed',
                // TooltTip: 'Add Invoice NO',
                Emits: 'AddInvoice',
                DynamicVisiblity: {
                  FixedValue: 'Not',
                  Operator: 'EQUAL',
                  ValuePath: 'IsInvoiced'
                }
              }
            ],
            Visible: this.Access('Inventory_Transfers_AddInvoiceNO')
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                Style: 'cursor: unset !important; font-size: 0.75em;',
                ID: 'Info',
                DynamicToolTip: ['Notes'],
                DynamicVisiblity: {
                  FixedValue: '',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Notes'
                }
              },
              {
                ID: 'Done',
                Visible: this.Access('Inventory_Transfers_Edit'),
                TooltTip: 'Importing Done',
                Emits: 'ImportDone',
                DynamicVisiblity: {
                  FixedValue: 'Importing',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Select',
                Visible: this.Access('Inventory_Transfers_Edit'),
                TooltTip: 'Check Items',
                Emits: 'ItemsChecked',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusDecoded'
                }
              },
              {
                ID: 'Select',
                Visible: this.Access('Inventory_Transfers_Edit'),
                TooltTip: 'Check Items',
                Emits: 'ItemsChecked',
                DynamicVisiblity: {
                  FixedValue: 'Exporting',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Approve',
                Visible: this.Access('Inventory_Transfers_Export'),
                TooltTip: 'Approve',
                Emits: 'Approve',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusExportAproveDecoded'
                }
              },
              {
                ID: 'Printer',
                Visible: this.Access('Inventory_Transfers_Export'),
                TooltTip: 'Print',
                Emits: 'PrintImport',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusExportDoneDecoded'
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Printer',
                Visible: this.Access('Inventory_Transfers_Export'),
                TooltTip: 'Print',
                Emits: 'PrintExport',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusExportDecoded'
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Expand',
                Visible: this.Access('Inventory_Transfers_Read'),
                TooltTip: 'View',
                Emits: 'ViewDialog'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Transfers_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditTransfer',
                DynamicVisiblity: {
                  FixedValue: 'New',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Transfers_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditTransfer',
                DynamicVisiblity: {
                  FixedValue: 'Exporting',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Transfers_Cancel'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditTransfer',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusEditAfterDone'
                }
              },
              {
                ID: 'Removed',
                Emits: 'CancelTransfer',
                Visible: this.Access('Inventory_Transfers_Cancel'),
                TooltTip: this.Translate('Cancel'),
                DynamicVisiblity: {
                  FixedValue: 'Canceled',
                  Operator: 'NOT EQUAL',
                  ValuePath: 'Status'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      this.CookiesData.forEach((element :any) => {
        element.HashedNumber = '#' + element.TransferNO
        element.IsInvoiced = (element.InvoiceNO !== '' && element.InvoiceNO) ? 'Invoiced' : 'Not'
        element.StatusDecoded = (element.Type === 'Import' || element.Type === 'ExternalImport') && element.Status === 'New'
        element.StatusExportDecoded = (element.Type === 'Export' || element.Type === 'BranchesTransfer') && element.Status === 'New'
        element.StatusExportDoneDecoded = element.Status !== 'New' && (element.CustomerName === 'صندوق النقد والشيكات' || (element.PrintAprroved === 1) || !this.$store.state.BranchManagment?.CurrentBranch?.ApproveBeforePrint || this.Access('Inventory_Transfers_AprrovePrint'))
        element.StatusExportAproveDecoded = element.Status !== 'New' && element.CustomerName !== 'صندوق النقد والشيكات' && !element.PrintAprroved && this.$store.state.BranchManagment?.CurrentBranch?.ApproveBeforePrint && this.Access('Inventory_Transfers_AprrovePrint')
        var NoLocationID = element.Items.some((item :any) => {
          return item.ItemLocationID === null
        })
        element.StatusEditAfterDone = !NoLocationID && element.Status === 'Done'
      })
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      // this.ReadWarehouses()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Transfers', '?Type=[Export,BranchesTransfer]' + (this.MEMRead('CurrentWarehouse') ? '&WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '') + '&' + this.DateAPIURL()).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    // ReadWarehouses () {
    //   this.Loading.push('Loading')
    //   this.Get('Inventory_Warehouses').then(response => {
    //     this.Warehouses = response
    //     this.Loading.shift() // Remove Loading flag
    //   }, error => {
    //     this.Loading.shift() // Remove Loading flag
    //     error.CookiesError = 'Error in reading Warehouses'
    //     this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    //   })
    // },
    ItemsChecked (Transfer :InventoryTransfer) {
      console.log(Transfer)
      // this.OpenDialog('Inventory_TransferCheck_Dialog', 'Checking Items', { PostMethod: 'POST', PostAPI: 'Inventory_Transfers_UPDATE', AddQuery: '?ID=' + transfer.ID + '&Stat=ImportCheck', Text: this.Translate('Are you sure you sure all items checked correctly?'), ButtonText: this.Translate('Accept'), Icon: 'Select', PostData: { Transfer: transfer } } as GeneralAcceptDialogData, {})
      this.OpenDialog('Inventory_TransferCheck_Dialog', 'Checking Items', { Data: Transfer }, { NoBackgroundClose: true })
    },
    ImportDone (Transfer :InventoryTransfer) {
      this.OpenDialog('General_Accept_Dialog', 'Items Imported', { PostMethod: 'POST', PostAPI: 'Inventory_Transfers_UPDATE', AddQuery: '?ID=' + Transfer.ID + '&Stat=Imported', Text: this.Translate('Are you sure all items imported successfully?'), ButtonText: this.Translate('Accept'), Icon: 'Done', PostData: { Transfer: Transfer } } as GeneralAcceptDialogData, { NoBackgroundClose: true })
    },
    AddInvoice (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_Transfers_AddInvoice', 'Invoice NO', { Data: Transfer.ID }, { NoBackgroundClose: true })
    },
    Approve (Transfer :InventoryTransfer) {
      this.Loading.push('Loading')
      this.Post('PATCH', 'Inventory_Transfers_AprrovePrint', { TransferID: Transfer.ID }).then(response => {
        this.ReadCookiesData()
        this.Loading.pop()
      }, error => {
        this.Loading.pop()
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditTransfer (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('Edit Transfer') + ' (' + Transfer.TransferNO + ') ', { Data: Transfer, Editing: true, Type: Transfer.Type }, { NoBackgroundClose: true })
    },
    EditTransferSections (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('Edit Sections'), { Data: Transfer, Editing: true }, { NoBackgroundClose: true })
    },
    CancelTransfer (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Cancel')) {
        return
      }
      // this.OpenDialog('General_Accept_Dialog', 'Items ', { PostMethod: 'POST', PostAPI: 'Inventory_Transfers_UPDATE', AddQuery: '?ID=' + Transfer.ID + '&Stat=Imported', Text: this.Translate('Are you sure all items imported successfully?'), ButtonText: this.Translate('Accept'), Icon: 'Done', PostData: { Transfer: Transfer } } as GeneralAcceptDialogData, { NoBackgroundClose: true })
      this.OpenDialog('General_Accept_Dialog', 'Cancel', { PostMethod: 'POST', PostAPI: 'Inventory_Transfers_UPDATE', AddQuery: '?ID=' + Transfer.ID + '&Stat=TransfereCancel', Text: this.Translate('Are you sure you want to cancel') + ' <strong>(' + Transfer.TransferNO + ')</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Cancel Transfer') } as GeneralAcceptDialogData, {})
    },
    DeleteTransfer (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Transfers', AddQuery: '?ID=' + Transfer.ID + '&Type=' + Transfer.Type, Text: 'Are you sure you want to delete <strong>' + Transfer.TransferNO + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    PrintImport (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferPrint_Dialog', 'Print Import', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    PrintExport (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferPrint_Dialog', 'Print Export', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    ViewDialog (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferView_Dialog', 'Print Export', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewImport':
          this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('New Transfer'), { Data: {}, Type: 'Import' }, { NoBackgroundClose: true })
          break
        case 'NewExport':
          this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('New Transfer'), { Data: {}, Type: 'Export' }, { NoBackgroundClose: true })
          break
        case 'InternalTransfer':
          this.OpenDialog('Inventory_InternalTransfer_Dialog', this.Translate('Internal Transfer'), { Data: {}, Type: 'Internal' }, { NoBackgroundClose: true })
          break
        case 'NewBranchesTransfer':
          this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('Transfer Into Branch'), { Data: {}, Type: 'BranchesTransfer' }, { NoBackgroundClose: true })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
