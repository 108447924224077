import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-984b6fbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "CookiesMainView"
}
const _hoisted_2 = { class: "BigPlaceholder P-Rel" }
const _hoisted_3 = {
  class: "BigContainer",
  style: {"background":"none","filter":"none"}
}
const _hoisted_4 = {
  class: "F1_5em Color2 F-Bold O-50",
  style: {}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesTabs = _resolveComponent("CookiesTabs")!
  const _component_MainPageDateSelector = _resolveComponent("MainPageDateSelector")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.Access('Reports_View'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.Translate('Reports')), 1),
            (_ctx.Tabs.length > 1)
              ? (_openBlock(), _createBlock(_component_CookiesTabs, {
                  key: 0,
                  TabsContainerWidth: '100%',
                  RouteStrict: false,
                  Res: 'Reports',
                  class: "W-100",
                  style: {"width":"100% !important"},
                  Tabs: _ctx.Tabs,
                  Default: (_ctx.Tabs[0] as CookiesTabs)
                }, null, 8, ["Tabs", "Default"]))
              : _createCommentVNode("", true),
            _createVNode(_component_MainPageDateSelector),
            _createVNode(_component_router_view)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}