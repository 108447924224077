import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}
const _hoisted_2 = { style: {"position":"absolute","left":"1.5em","z-index":"55","top":"-2em","display":"flex","justify-content":"flex-end","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CookiesIcon, {
        Tooltip: _ctx.Translate('Refresh'),
        style: {"width":"2em","height":"2em","cursor":"pointer"},
        ID: 'Delayed',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ReadCookiesData()))
      }, null, 8, ["Tooltip"])
    ]),
    _createVNode(_component_CookiesFunctionalTable, {
      onViewDialog: _ctx.ViewDialog,
      onPrintImport: _ctx.PrintImport,
      onPrintExport: _ctx.PrintExport,
      onImportDone: _ctx.ImportDone,
      onAddInvoice: _ctx.AddInvoice,
      onApprove: _ctx.Approve,
      onItemsChecked: _ctx.ItemsChecked,
      onEditTransfer: _ctx.EditTransfer,
      onEditTransferSections: _ctx.EditTransferSections,
      onCancelTransfer: _ctx.CancelTransfer,
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, null, 8, ["onViewDialog", "onPrintImport", "onPrintExport", "onImportDone", "onAddInvoice", "onApprove", "onItemsChecked", "onEditTransfer", "onEditTransferSections", "onCancelTransfer", "Loading", "CookiesData", "TableSettings"])
  ], 64))
}