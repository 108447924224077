<template>
<div v-if="Access('FinancialAdd')" class="W-100 H-100">
  <div style="white-space: nowrap;" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
    <DialogDisabler v-if="Saving"></DialogDisabler>
    <div class="M-Auto DialogContainer color3">
      <div class="DialogBody">
        <div class="CookiesRow F-Bold W-100" style="justify-content:center;">
          <CookiesDropDown :Radio="true" :Res="'D_New_Trans_LedgerType'" :CookiesIcons="AttributeArray(TransactionTypes[DialogData.Category], ['Icon'])" :Type="'Object'" :Selector="['name']" :Options="TransactionTypes[DialogData.Category]" ></CookiesDropDown>
          <div class="CookiesRowSpacer W-100"></div>
          <div class="ContentChunck F1_25em">
            <CookiesNumberInput :ErrorData="MEMRead('D_New_Trans_Ammount') === 0 & ErrorsVisibilty" :Min="MEMRead('D_New_Trans_LedgerType') && MEMRead('D_New_Trans_LedgerType').LedgerType === 'Diff' ? -99999999 : 0" :Name="LanguageSelector('Ammount: ', 'القيمة: ')" MinWidth="2em" style="margin-top:-8px;" :Res="'D_New_Trans_Ammount'" :Default="0" :Unit="$store.state.CookiesConfig.PriceUnit"></CookiesNumberInput>
          </div>
          <!-- <div class="ContentChunck F1_5em">
            <span class="Pointer Shadow1 F-C2" @click="SwapType()" :style="TransType === 'DEBIT' ? {background: 'var(--colorr1)'} : {background: 'var(--colorg3)'}" style="padding: 3px 8px; border-radius: var(--rad3)" >{{TransType}}</span>
          </div> -->
          <div class="CookiesRowSplitter W-100"/>
        </div>
        <div v-if="DialogData.Category === 'Driver'" class="CookiesRow F1_5em W-100" style="padding:5px; justify-content:center;">
          <div class="M-Auto D-Flex FlexWrap"><span class="M-Auto">{{LanguageSelector('From Account: ', 'من حساب: ')}}</span></div>
        </div>
        <div v-if="DialogData.Category === 'Driver'" class="CookiesRow F1_25em W-100" style="padding:5px; justify-content:center; gap: 0.5em;">
          <div v-for="Account in AvailableAccounts" :key="Account" @click="ChangeAccount(Account)" class="M-Auto AccountSelector" :class="[(FromLedger === Account.LedgerID & FromAccount === Account.AccountID) ? 'AccountSelected' : '']">
            <span style="padding: 0em 0.4em;" class="F-Bold M-Auto"> {{' ' + Account.Name  + ' '}}</span>
            <span style="padding: 0em 0.4em;" class="F-Bold M-Auto"> {{' ' + Account.Cash + ' '}} {{PriceUnit}}</span>
          </div>
        </div>
        <div v-if="DialogData.Category === 'Driver'" class="CookiesRowSplitter"></div>
      </div>
        <div class="CookiesRow W-100">
          <textarea v-model="TransNotes" class="CookiesCommentBox" name="Comments" :placeholder="LanguageSelector('Notes', 'ملاحظات')" autocomplete="off"></textarea>
        </div>
    </div>
  </div>
  <div class="CookiesDialogFooter">
    <div class=" F-Bold" style="margin: auto 1em;">
      <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold F1em CookiesButton DialogFooterButton">{{LanguageSelector('Cancel', 'الغاء')}}</button>
      <button @click="NewTrans" class="F-Bold CookiesButton DialogFooterButton BGThird">{{LanguageSelector('Confirm', 'تأكيد')}}</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      TransNotes: '',
      Saving: false,
      ErrorsVisibilty: false,
      FromLedger: null,
      FromAccount: null,
      M_Expenses: {},
      M_Revenue: {},
      M_Cash: {},
      TransactionTypes: {
        Driver: [
          {
            name: this.LanguageSelector('Gas', 'وقود'),
            Icon: 'Gas',
            LedgerType: 'Gas'
          },
          {
            name: this.LanguageSelector('Maintenance', 'تصليح'),
            Icon: 'Maintenance',
            LedgerType: 'Maintenance'
          },
          {
            name: this.LanguageSelector('Others', 'مصاريف اخرى'),
            Icon: '',
            LedgerType: 'Expenses'
          },
          {
            name: this.LanguageSelector('Account Difference', 'فرق حساب'),
            Icon: '',
            LedgerType: 'Diff',
            NoUser: true
          }
        ],
        Main: [
          {
            name: this.LanguageSelector('Expenses', 'مصاريف'),
            Icon: 'Expenses',
            LedgerType: 'Expenses'
          },
          {
            name: this.LanguageSelector('Revenue', 'ايرادات'),
            Icon: 'Revenue',
            LedgerType: 'CollectedRevenue'
          }
        ],
        Employee: [
          {
            name: this.LanguageSelector('Early payment to account', 'دفعة على الحساب'),
            Icon: 'Loan',
            LedgerType: 'Loans'
          }
        ]
      }
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    if (!this.Access('FinancialAdd')) {
      return
    }
    this.ReadMainAccounts('M_Expenses')
    this.ReadMainAccounts('M_Revenue')
    this.ReadMainAccounts('M_Cash')
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    TransType () {
      if (this.$store.state.CookiesMemory.D_New_Trans_Ammount < 0) {
        return 'DEBIT'
      } else {
        return 'CREDIT'
      }
    },
    TransactionData () {
      var NewTransData = {}
      var fromA = this.FromAccount
      var fromL = this.FromLedger
      var toA = this.DialogData.Account.ID
      var toL = ''
      switch (this.DialogData.Category) {
        case 'Driver':
          toL = this.LedgerFinder(this.DialogData.Account.ledgers, this.LedgersMapper[this.MEMRead('D_New_Trans_LedgerType').LedgerType], 1).ID
          NewTransData = {
            Amount: this.$store.state.CookiesMemory.D_New_Trans_Ammount,
            FromAccount: { ID: fromA },
            FromLedger: { ID: fromL },
            ToAccount: { ID: toA },
            ToLedger: { ID: toL },
            DesciptionAr: this.TransNotes,
            Desciption: this.TransNotes
          }
          break
        case 'Main':
          if (this.MEMRead('D_New_Trans_LedgerType').LedgerType === 'Expenses') {
            fromA = this.M_Cash.ID
            fromL = this.LedgerFinder(this.M_Cash.ledgers, this.LedgersMapper.Cash, 1).ID
            toA = this.M_Expenses.ID
            toL = this.LedgerFinder(this.M_Expenses.ledgers, this.LedgersMapper[this.MEMRead('D_New_Trans_LedgerType').LedgerType], 1).ID
          } else {
            fromA = this.M_Revenue.ID
            fromL = this.LedgerFinder(this.M_Revenue.ledgers, this.LedgersMapper[this.MEMRead('D_New_Trans_LedgerType').LedgerType], 1).ID
            toA = this.M_Cash.ID
            toL = this.LedgerFinder(this.M_Cash.ledgers, this.LedgersMapper.Cash, 1).ID
          }
          NewTransData = {
            Amount: this.$store.state.CookiesMemory.D_New_Trans_Ammount,
            FromAccount: { ID: fromA },
            FromLedger: { ID: fromL },
            ToAccount: { ID: toA },
            ToLedger: { ID: toL },
            DesciptionAr: this.TransNotes,
            Desciption: this.TransNotes
          }
          break
        case 'Employee':
          fromA = this.M_Cash.ID
          fromL = this.LedgerFinder(this.M_Cash.ledgers, this.LedgersMapper.Cash, 1).ID
          toA = this.DialogData.Account.ID
          toL = this.LedgerFinder(this.DialogData.Account.ledgers, this.LedgersMapper.Loans, 1).ID
          NewTransData = {
            Amount: this.$store.state.CookiesMemory.D_New_Trans_Ammount,
            FromAccount: { ID: fromA },
            FromLedger: { ID: fromL },
            ToAccount: { ID: toA },
            ToLedger: { ID: toL },
            DesciptionAr: this.TransNotes,
            Desciption: this.TransNotes
          }
          break
        default:
          // code block
      }
      return NewTransData
    },
    DialogError () {
      var DialogErrors = []
      this.MEMRead('D_New_Trans_Ammount') === 0 && DialogErrors.push('Ammount cant be 0')
      return DialogErrors
    },
    AvailableAccounts () {
      var Accounts = []
      try {
        var MainCashLedger = this.LedgerFinder(this.M_Cash.ledgers, this.LedgersMapper.Cash, 1)
        if (this.MEMRead('D_New_Trans_LedgerType') && !this.MEMRead('D_New_Trans_LedgerType').NoMain) {
          Accounts.push({
            Name: MainCashLedger.Name,
            LedgerID: MainCashLedger.ID,
            AccountID: this.M_Cash.ID,
            Cash: MainCashLedger.NetBalance
          })
        }
        if (this.MEMRead('D_New_Trans_LedgerType') && !this.MEMRead('D_New_Trans_LedgerType').NoUser) {
          switch (this.DialogData.Category) {
            case 'Driver':
              var DriverLedger = this.LedgerFinder(this.DialogData.Account.ledgers, this.LedgersMapper.Cash, 1)
              Accounts.push({
                Name: DriverLedger.Name,
                LedgerID: DriverLedger.ID,
                AccountID: this.DialogData.Account.ID,
                Cash: DriverLedger.NetBalance
              })
              break
            case 'Employee':
              // code block
              break
            default:
              // code block
          }
        }
      } catch (error) {
        this.Console(error, 'color: red;')
      }
      return Accounts
    }
  },
  methods: {
    ChangeAccount (Account) {
      this.FromLedger = Account.LedgerID
      this.FromAccount = Account.AccountID
    },
    ReadMainAccounts (Account) {
      this.Get(['Financial/GetAccount?ID=' + this.AccountMapper[Account]]).then(response => {
        this[Account] = response[0]
      }, error => {
        error.CookiesError = 'Error in reading Cash Account'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewTrans () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Console(this.TransactionData, 'color: Orange; font-size: 2em;')
      // var x = true
      // if (x) {
      //   return
      // }
      this.Saving = true
      this.Post('POST', 'Financial/CreateTransaction', this.TransactionData).then(response => {
        this.Saving = false
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.Saving = false
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SwapType () {
      this.$store.state.CookiesMemory.D_New_Trans_Ammount = -1 * this.$store.state.CookiesMemory.D_New_Trans_Ammount
    }
  },
  watch: {
    AvailableAccounts: function (newVal, oldVal) { // watch it
      try {
        this.FromAccount = this.AvailableAccounts[0].AccountID
        this.FromLedger = this.AvailableAccounts[0].LedgerID
      } catch (error) {
      }
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 800px;
  max-width: 80vw;
  /* min-width: 275px; */
}
.ContentChunck{
  display: flex;
  padding: 0.5em;
  margin: auto;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.AccountSelector{
  display: flex;
  flex-direction: column;
  width: 20em;
  border: 0.2em solid;
  border-color: v-bind('Theme.BG1');
  border-radius: var(--rad2);
  opacity: 0.5;
  cursor: pointer;
}
.AccountSelected{
  background: v-bind('Theme.UserColors.third_50');
  border-color: v-bind('Theme.UserColors.third_75');
  opacity: 1;
}
.AccountSelector:hover{
  background: v-bind('Theme.UserColors.third_25');
  border-color: v-bind('Theme.UserColors.third_25');
  opacity: 1;
}
.AccountSelected:hover{
  background: v-bind('Theme.UserColors.third_50');
  border-color: v-bind('Theme.UserColors.third_75');
  opacity: 1;
}
</style>
