
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import Papa from 'papaparse'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      PhoneNumber: '',
      ServerError: '',
      Saving: false,
      CSVLoaded: false,
      Contacts: [] as any
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<DialogData> }
  },
  computed: {
    Tabs () :Array<CookiesTabs> {
      return [
        {
          Name: this.Translate('Single Contact'),
          Access: this.$store.state.CookiesConfig.WhatsappStatus === 'true',
          Value: 'Single'
        },
        {
          Name: this.Translate('List of Contacts'),
          Access: this.$store.state.CookiesConfig.WhatsappStatus === 'true',
          Value: 'Multiple'
        }
      ]
    },
    TableSettings () :CookiesTable {
      return {
        CookiesID: 'WhatsAppSendMessages',
        Fields: [
          {
            Name: '',
            Type: 'index',
            Value: ''
          },
          {
            Name: this.Translate('Numbers'),
            Type: 'normal',
            Value: 'PhoneNumber'
          }
        ],
        Footer: false,
        Header: false,
        ResID: 'PhoneNumber'
      }
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  methods: {
    SendMessages () {
      this.Saving = true
      var message = this.DialogData?.Data
      if (this.MEMRead('D_Integration_WhatsappMessage_Tab') && this.MEMRead('D_Integration_WhatsappMessage_Tab').Value === 'Single') {
        message.to = this.MEMRead('D_Integration_WhatsappMessage_PhoneNumber')
        this.WhatsappSendMessage(message).then(response => {
          this.Saving = false
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          this.ServerError = error.Description
        })
      } else if (this.MEMRead('D_Integration_WhatsappMessage_Tab') && this.MEMRead('D_Integration_WhatsappMessage_Tab').Value === 'Multiple') {
        var MessagesSent = 0
        for (let i = 0; i < this.Contacts.length; i++) {
          setTimeout(() => {
            var currentMessage = message
            currentMessage.to = this.Contacts[i].PhoneNumber
            this.WhatsappSendMessage(currentMessage, false).then(response => {
              MessagesSent += 1
              AllSentCheck()
            }, error => {
              this.Saving = false
              this.ServerError = error.Description
            })
          }, 15 * (i + 1))
        }
      }
      const _this = this
      function AllSentCheck () {
        if (MessagesSent === _this.Contacts.length) {
          _this.PushNotification(_this.Translate('Messages Sent Succesfully'))
          _this.$router.go(-1)
        }
      }
    },
    NumberValidity (Number :any) : boolean {
      if (Number === undefined) {
        return false
      }
      var reGlobal1 = /^[+]([1-9])([0-9]){7,15}$/
      var reGlobal2 = /^[0][0]([1-9])([0-9]){7,15}$/
      var re3Jordan = /^[0][7][9,8,7]([0-9]){7}$/
      return reGlobal1.test(Number.replaceAll(/\s/g, '')) || reGlobal2.test(Number.replaceAll(/\s/g, '')) || re3Jordan.test(Number.replaceAll(/\s/g, ''))
    },
    ParseCSV () {
      this.Saving = true
      this.CSVLoaded = false
      var MyEvent :any = event
      var file = MyEvent.target.files[0]
      var _this = this
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: function (results :any) {
          var ContactsList :Array<any> = []
          results.data.forEach((contact :any) => {
            if (_this.NumberValidity(contact[0])) {
              ContactsList.push({ PhoneNumber: contact[0] })
            }
          })
          _this.Contacts = ContactsList
          _this.CSVLoaded = true
          _this.Saving = false
        }
      })
    }
  }
})
