
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      Width: '14.8cm',
      Height: '21cm'
    }
  },
  props: {
    Data: { default: () => {}, type: Object as PropType<Invoice> }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d6d0b32c": (_ctx.Width),
  "36e3a5fa": (_ctx.Height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__