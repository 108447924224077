
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' }
  },
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Employee>
    }
  },
  computed: {
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type'],
            Sort: 'Type'
          },
          {
            Type: 'Normal',
            Name: 'Phone',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber']
          },
          {
            Type: 'Normal',
            Name: 'Salary',
            Path: ['Salary'],
            Sort: ['Salary'],
            Unit: this.PriceUnit
          },
          {
            Type: 'Barcode',
            Name: 'Code',
            Path: 'Code',
            Sort: 'Code'
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              Active: 'BGGreen',
              InActive: 'BGRed'
            },
            Bold: true,
            Class: 'HoverUp'
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_Employees_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'HR_Employees_Edit'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Employees').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditEmployee (Employee :Employee) {
      if (!this.Access('HR_Employees_Read')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', 'Edit Employee (' + Employee.Name + ')', { Data: Employee, Editing: true }, {})
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
