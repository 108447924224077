<template>
<div v-if="Access('ProductsAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <DialogDisabler v-if="Loading.length > 0"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Product -------------------------------------- -->
      <div class="CookiesRow FlexBreaker">
        <div class="CookiesRow D-Flex" style="min-width: 65%;"> <!-- select warehouse -->
          <input v-model="Name" :CookiesInvalidInput="!Name" :class="(ErrorsVisibilty & !Name) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="WarehouseName" :placeholder="Translate('Name')" autocomplete="off">
        </div>
        <div class="CookiesRowSpacer"></div>
        <div class="CookiesRow D-Flex" style="width: 65%;"> <!-- select warehouse -->
          <div class="W-100" style="text-align: start;"><span>{{Translate('Under Branch:')}}</span></div>
          <CookiesDropInputObject :CookiesInvalidInput="MEMRead('D_WarehouseDialog_Branch') === ''" :Default="DefaultCategory" :ErrorData="ErrorsVisibilty && MEMRead('D_WarehouseDialog_Branch') === ''" @changed="CategorySelected" @selected="CategorySelected" :Res="'D_WarehouseDialog_Branch'" :Name="Translate('Branch')" :Selectors="['Name']" :Options="Branches"></CookiesDropInputObject>
        </div>
      </div>
      <div class="CookiesRowSplitter"/>
      <textarea :placeholder="Translate('Notes')" v-model="Notes" class="CookiesCommentBox"></textarea>
    </div>
  </div>
</div>
<div v-if="Access('ProductsAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
    <button @click="SaveWarehouse()" :style="'background: ' + Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Save')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpGender = {}
  },
  created () {},
  data () {
    return {
      Editing: false,
      OriginalData: {},
      Name: '',
      Notes: '',
      ErrorsVisibilty: false,
      selectedFile: '',
      SelectedProductImage: '',
      ProductImage: '',
      Supplier: [],
      Manufacturer: [],
      Category: '',
      DefaultCategory: '',
      DefaultManufacturer: '',
      DefaultSupplier: '',
      Branches: [],
      Saving: false,
      Loading: [],
      UnitsCatolgsCount: 1
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    if (!this.Access('WarehouseAdd')) {
      return
    }
    this.ReadBranches()
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    LoadEditingData () {
      if (this.DialogData.Editing) {
        this.Editing = true
        this.OriginalData = this.DeepCloner(this.DialogData.Data)
        var DataImage = this.DeepCloner(this.DialogData.Data)
        this.OriginalData = {
          ID: DataImage.ID,
          Name: DataImage.Name,
          Notes: DataImage.Notes,
          BranchID: DataImage.BranchID
        }
        this.Name = this.DeepFetcher(this.OriginalData, 'Name')
        this.Notes = this.DeepFetcher(this.OriginalData, 'Notes')
        var LoadedBranch = this.ObjectFinder(this.Branches, 'ID', this.DeepFetcher(this.OriginalData, ['BranchID']))
        this.MEMWrite('D_WarehouseDialog_Branch_Value', LoadedBranch)
      }
    },
    ReadBranches () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadBranches']).then(response => {
        this.Branches = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Manufacturers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveWarehouse () {
      const _this = this
      if (this.Requirments(this).Incomplete) { // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      // image handling
      PostNow()
      // Post // Patch
      function PostNow () {
        let Method = 'POST'
        let API = 'Product'
        let Data = _this.WarehouseData
        let SavingError = 'Error in Adding New Product'
        if (_this.Editing) {
          Method = 'PATCH'
          API = 'Product/Edit'
          Data = _this.CookiesPatcher(_this.OriginalData, _this.WarehouseData)
          SavingError = 'Error in Saving Product'
          if (Data.length === 0) {
            _this.Saving = false
            _this.$router.go(-1)
            return
          }
        }
        _this.Post(Method, API, Data).then(response => {
          _this.Saving = false
          _this.CloseDialog(_this.DialogIndex)
        }, error => {
          _this.Saving = false
          error.CookiesError = SavingError
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    }
  },
  computed: {
    WarehouseData () {
      var CurrentData = {
        ID: this.OriginalData.ID,
        Notes: this.Notes,
        Name: this.Name,
        BranchID: this.DeepFetcher(this.MEMRead('D_WarehouseDialog_Branch_Value'), 'ID')
      }
      return CurrentData
    }
  },
  watch: {
    Loading: {
      handler: function (val, oldVal) {
        if (this.DialogData.Editing && val.length === 0) {
          this.LoadEditingData()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 750px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .ProductImageHolder{
    order: -1;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

.ProductImage{
  width: 10em;
  aspect-ratio: 1;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: var(--rad1);
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.ProductImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
