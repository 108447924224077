
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' }
  },
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<FinanceAccount>
    }
  },
  computed: {
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Invoice Number',
            Path: ['Name'],
            Sort: ['Name'],
            Hash: '#'
          },
          {
            Type: 'Normal',
            Path: ['Code'],
            Name: 'Description',
            Sort: ['Code'],
            Bold: true
          },
          {
            Type: 'Normal',
            Path: 'Type',
            Name: 'Type'
          },
          {
            Type: 'Normal',
            Path: ['Description'],
            Name: 'Description'
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'MoneyPaper',
                Visible: this.Access('Finance_Invoices_Read'),
                TooltTip: this.Translate('Invoice')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Invoices').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Invoices'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveProduct (Product :any) {
      this.OpenDialog('Delete_Dialog', this.Translate('Deleting Product'), [['Products/Delete', '?Id=' + Product.ID], Product.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ViewInvoice (Invoice :Invoice) {
      alert('sss')
      if (!this.Access('Finance_Invoices_Read')) {
        return
      }
      this.OpenDialog('Finance_Invoice_View', '', Invoice, { header: false }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
