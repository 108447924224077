<template>
<div v-if="Access('StockView')" class="CookiesMainView">
  <div class="BigPlaceholder P-Rel">
    <!-- Filter Block ------------------------------------------------------------------------------- -->
    <CookiesFilterPannel @CloseMe="ShowFilter = false" :Lists="FilterProps.Lists" :Checks="FilterProps.Checks" :Switches="FilterProps.Switches" :Dates="false" :ShowFilter="ShowFilter"></CookiesFilterPannel>
    <!-- Main Block ------------------------------------------------------------------------------- -->
    <div class="BigContainer">
      <div class="BigHeader O-754">
        <!-- Header ------------------------------------------------------------------------------- -->
        <div class="D-Flex StocksHeader" style="gap: 1em;">
          <div class="D-Flex">
            <CookiesIcon :ToolTip="LanguageSelector('Filter', 'فلتر')" class="FilterButton HoverUp" @click="ShowFilter = true" style="width: 2.2em; height: 2.2em; margin: auto 0.5em; padding: 0em 1em;" :ID="'Filter'"></CookiesIcon>
          </div>
          <!-- <CookiesIcon style="width: 3em; height: 3em; margin: auto 0.5em;" :ID="'Factory'"></CookiesIcon> -->
          <span class="F-Bold F1_75em BigHeaderText">{{Translate('Manufacturing')}}</span>
          <!-- <CookiesDropDown :Res="'WarehousesFilter_Value'" :Type="'Object'" :Width="'12em'" :Selectors="'Name'" :Options="Warehouses"></CookiesDropDown> -->
          <CookiesTabs @selected="ChangeView" class="M-Auto" :Router="false" :Default="{ Name: Translate('Manufacturing Orders'), Value: 'Orders' }" :Tabs="Tabs"></CookiesTabs>
          <button v-if="Access('ManufacturingOrdersAdd') && CurrentView === 'Orders'" @click="NewOrder()" class="DialogHeaderButton BGMain F1_25em">+ {{Translate('New Order')}}</button>
          <button v-if="Access('ManufacturersAdd') && CurrentView === 'Manufacturers'" @click="NewManufacturer()" class="DialogHeaderButton BGMain F1_25em">+ {{Translate('New Manufacturer')}}</button>
        </div>
        <div class="M-Auto"></div> <!-- Space Filler -->
        <!-- Search Block ------------------------------------------------------------------------------- -->
        <div class="D-Flex CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
        </div>
      </div>
        <!-- Main Area Block ------------------------------------------------------------------------------- -->
          <!-- Loading Indicatr ------------------------------------------------------------------------------- -->
        <div v-if="Loading.length > 0" class="D-Flex M-Auto W-100 P-Abs BGShade1 Shadow1" style="justify-content: center; align-items: center; min-width: 100%;">
          <div class="D-Flex" style="min-height:2.7em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
        </div>
        <div class="BigBody P-Rel">
          <div v-if="CurrentView === 'Orders'" class="W-100" style="overflow-y: scroll;">
            <table class="W-100 CookiesGeneralTable" CELLSPACING=0>
              <tr class="CookiesFade">
                <th></th>
                <CookiesSortingTH @click="SortTable(['ID'], 'sortManufacturingElment')" :Name="Translate('ID')" :Value="['ID']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['Sale'], 'sortManufacturingElment')" :Name="Translate('Sale')" :Value="['Sale']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['OrderDate'], 'sortManufacturingElment')" :Name="Translate('Order Date')" :Value="['OrderDate']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['DeliveryDate'], 'sortManufacturingElment')" :Name="Translate('Delivery Date')" :Value="['DeliveryDate']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['ManufacturerName'], 'sortManufacturingElment')" :Name="Translate('Manufacturer')" :Value="['ManufacturerName']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <CookiesSortingTH :Sortable="false" :Name="Translate('Products')" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['ComputedStatus'], 'sortManufacturingElment')" :Name="Translate('Status')" :Value="['ComputedStatus']" :sortColumn="sortManufacturingElment" ></CookiesSortingTH>
                <th></th>
              </tr>
                <tr class="CookiesFade" v-if="!Loading & FilterdActiveOrders.length === 0"><td colspan="20" style="padding: 1.5em 0.2em;">{{Translate('No Data')}}</td></tr>
                <template v-for="(order, index) in FilterdActiveOrders" :key="(order, index)">
                  <tr class="CookiesGeneralTableRow CookiesFade">
                    <td class="P1em">{{index + 1}}</td>
                    <td>{{DeepFetcher(order, 'Hashed_ID')}}</td>
                    <td>{{DeepFetcher(order, 'Hashed_LiveSales')}}</td>
                    <td>{{JsonDateParser(DeepFetcher(order, 'OrderDate'))}} - {{JsonTimeParser(DeepFetcher(order, 'OrderDate'))}}</td>
                    <td class="F-Bold" :class="CompareDates(DeepFetcher(order, 'DeliveryDate'), new Date()) === 0 ? 'colorFourth' : CompareDates(DeepFetcher(order, 'DeliveryDate'), new Date()) === 2 ? 'colorr1' : ''">{{JsonDateParser(DeepFetcher(order, 'DeliveryDate'))}}</td>
                    <td>{{DeepFetcher(order, 'ManufacturerName')}}</td>
                    <td style="text-align: -webkit-center;">
                      <tr class="W-100 text-center" style="text-align: -webkit-center;" v-for="(unit, i) in order.OrderUnits" :key="(unit, i)">
                        <td style="padding: 0.2em"><strong>{{DeepFetcher(unit, ['ProductName'])}}</strong> ({{DeepFetcher(unit, ['Name'])}})</td>
                        <td style="padding: 0.2em;" class="D-Flex F-Bold"><div style="padding: 0 0.2em;"> X </div><div style="text-align: end;">{{DeepFetcher(unit, ['Quantity'])}}</div></td>
                      </tr>
                    </td>
                    <td>
                      <span v-if="DeepFetcher(order, ['ComputedStatus']) === 'Received'" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;" class="F-Bold BGGreen">{{Translate('Received')}}</span>
                      <span v-if="DeepFetcher(order, ['ComputedStatus']) === 'PendingApproval'" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;" class="F-Bold BGMain">{{Translate('Pending Approval')}}</span>
                      <span v-if="DeepFetcher(order, ['ComputedStatus']) === 'Approved'" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;" class="F-Bold BGYellow">{{Translate('Pending')}}</span>
                      <span v-if="DeepFetcher(order, ['ComputedStatus']) === 'Canceled'" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;" class="F-Bold BGRed">{{Translate('Canceled')}}</span>
                    </td>
                    <td style="padding: 0.6em 0.4em">
                      <CookiesIcon :ToolTip="Translate('View')" class="CookiesRowIcon HoverUp" v-if="Access('ManufacturingOrdersView')" @click="ViewOrder(order)" :ID="'Expand'"></CookiesIcon>
                      <CookiesIcon :ToolTip="Translate('Edit')" class="CookiesRowIcon HoverUp" v-if="Access('ManufacturingOrdersEdit') && !order.IsCanceled && !order.IsDone" @click="Editorder(order)" :ID="'Edit'"></CookiesIcon>
                      <CookiesIcon :ToolTip="Translate('Received')" class="CookiesRowIcon HoverUp" v-if="Access('ManufacturingOrdersEdit') && !order.IsCanceled && !order.IsDone && order.IsApproved" @click="ReceiveOrder(order)" :ID="'Done'"></CookiesIcon>
                      <CookiesIcon :ToolTip="Translate('Approve')" class="CookiesRowIcon HoverUp" v-if="Access('ManufacturingOrdersApprove') && !order.IsCanceled && !order.IsDone && !order.IsApproved" @click="ApproveOrder(order)" :ID="'Approve'"></CookiesIcon>
                      <CookiesIcon :ToolTip="Translate('Cancel')" class="CookiesRowIcon HoverUp" v-if="Access('ManufacturingOrdersEdit') && !order.IsCanceled && !order.IsDone" @click="CancelOrder(order)" :ID="'Removed'"></CookiesIcon>
                    </td>
                  </tr>
                </template>
            </table>
          </div>
          <div v-if="CurrentView === 'Manufacturers'" class="W-100" style="overflow-y: scroll;">
            <table class="W-100 CookiesGeneralTable" CELLSPACING=0>
              <tr class="CookiesFade">
                <th></th>
                <th>ID</th>
                <CookiesSortingTH @click="SortTable(['Name'], 'sortManufacturerElment')" :Name="Translate('Manufacturer')" :Value="['Name']" :sortColumn="sortManufacturerElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['ActiveOrders'], 'sortManufacturerElment')" :Name="Translate('Active Orders')" :Value="['ActiveOrders']" :sortColumn="sortManufacturerElment"></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['Email'], 'sortManufacturerElment')" :Name="Translate('Email')" :Value="['Email']" :sortColumn="sortManufacturerElment"></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['PhoneNumber'], 'sortManufacturerElment')" :Name="Translate('Phone')" :Value="['PhoneNumber']" :sortColumn="sortManufacturerElment"></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['AddressL1'], 'sortManufacturerElment')" :Name="Translate('Address')" :Value="['AddressL1']" :sortColumn="sortManufacturerElment"></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['NumberOfUnits'], 'sortManufacturerElment')" :Name="Translate('Products')" :Value="['NumberOfUnits']" :sortColumn="sortManufacturerElment"></CookiesSortingTH>
                <th></th>
              </tr>
                <tr class="CookiesFade" v-if="!Loading & FilterdManufacturers.length === 0"><td colspan="20" style="padding: 1.5em 0.2em;">{{Translate('No Data')}}</td></tr>
                <template v-for="(Manufacturer, index) in FilterdManufacturers" :key="(Manufacturer, index)">
                  <tr class="CookiesGeneralTableRow CookiesFade">
                    <td class="P1em">{{index + 1}}</td>
                    <td>#{{DeepFetcher(Manufacturer, 'ID')}}</td>
                    <td>{{DeepFetcher(Manufacturer, 'Name')}}</td>
                    <td>{{DeepFetcher(Manufacturer, 'ActiveOrders')}}</td>
                    <td>{{DeepFetcher(Manufacturer, 'Email')}}</td>
                    <td @click="OpenWhatsapp(DeepFetcher(Manufacturer, 'PhoneNumber'))" class="Pointer"><span>{{DeepFetcher(Manufacturer, 'PhoneNumber')}}</span></td>
                    <td @click="OpenWhatsapp(DeepFetcher(Manufacturer, 'AddressL1'))" class="Pointer"><span>{{DeepFetcher(Manufacturer, 'AddressL1')}}</span></td>
                    <td>{{DeepFetcher(Manufacturer, 'NumberOfUnits')}}</td>
                    <td style="padding: 0.6em 0.4em">
                      <CookiesIcon  :ToolTip="Translate('Edit')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !Manufacturer.IsExecuted" @click="EditManufacturer(Manufacturer)" :ID="'Edit'"></CookiesIcon>
                      <CookiesIcon  :ToolTip="Translate('Delete')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !Manufacturer.IsExecuted" @click="RemoveManufacturer(Manufacturer)" :ID="'Delete'"></CookiesIcon>
                    </td>
                  </tr>
                </template>
            </table>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted () {
    if (!this.Access('StockView')) {
      if (this.Access('Driver')) {
        this.$router.replace('/DriverPortal')
      }
    }
    this.ReadProducts()
    this.ReadManufacturers()
    this.ReadActiveOrders()
  },
  data () {
    return {
      ShowFilter: false,
      CookiesDataStocks: [],
      CookiesDataArchivedStocks: [],
      Search: '',
      sortElment: {
        name: ['UPDDate'],
        value: -1
      },
      sortManufacturingElment: {
        name: ['UPDTime'],
        value: -1
      },
      sortManufacturerElment: {
        name: ['Name'],
        value: 1
      },
      WarehousesView: true,
      StocksHis: {},
      CurrentPage: 1,
      StocksPerPage: 100,
      Loading: [],
      ArchivedStocks: false,
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To,
      CurrentView: 'Orders',
      Products: [],
      Manufacturers: [],
      Branches: [],
      AllUnits: [],
      UnitCatalogsByID: {},
      ManufacturersByID: {},
      ManufacturersOrders: {},
      ActiveOrders: []
    }
  },
  computed: {
    FilterProps () {
      var FilterLists = [
        {
          Visible: this.Access('StockView'),
          Res: 'WarehousessFilter',
          Selectors: ['Name'],
          Name: this.Translate('Warehouse'),
          Options: this.Manufacturers
        }
      ]
      var FilterChecks = []
      this.StatusMapper('', 'objlist').forEach(element => {
        if (!(['DriverPaid', 'CustomerPaid'].indexOf(element.value) >= 0) && (['Done', 'Closed'].indexOf(element.value) >= 0 ? this.ArchivedStocks : !this.ArchivedStocks)) {
          element.Visible = true
        } else {
          element.Visible = false
        }
        element.Name = element[this.LanguageSelector('ENG', 'ARA')]
        element.Icon = element.value
        element.Res = 'StockFilter_' + element.value
        FilterChecks.push(element)
      })
      var FilterSwitches = [
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Only with issue', 'فقط مع تنبيهات'),
          Icon: 'HasIssue',
          Res: 'StockFilter_HasIssue'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Only assigned', 'فقط مع سائق'),
          Icon: 'Drivers',
          Res: 'StockFilter_Assigned'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Driver Paid', 'تم محاسبة السائق'),
          Icon: 'DriverPaid',
          Res: 'StockFilter_DriverPaid'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Customer Paid', 'تم محاسبة العميل'),
          Icon: 'CustomerPaid',
          Res: 'StockFilter_CustomerPaid'
        }
      ]
      return {
        Lists: FilterLists,
        Checks: FilterChecks,
        Switches: FilterSwitches
      }
    },
    FilterdActiveOrders () {
      var FilteredArray = []
      this.sortedActiveOrders.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.Hashed_ID = '#' + item.ID
        item.Hashed_LiveSales = '#' + item.LiveSales
        item.ManufacturerName = this.DeepFetcher(this.DecodedManufacturers, ['ID_' + this.DeepFetcher(item, 'ManufacturerID'), 'Name'])
        if (item.IsCanceled) {
          item.ComputedStatus = 'Canceled'
        } else if (item.IsDone) {
          item.ComputedStatus = 'Received'
        } else if (item.IsApproved) {
          item.ComputedStatus = 'Approved'
        } else {
          item.ComputedStatus = 'PendingApproval'
        }
        item.OrderUnits.forEach(unit => {
          try {
            unit.ProductName = this.UnitCatalogsByID['ID_' + unit.UnitCatalogID].ProductName
            unit.Name = this.UnitCatalogsByID['ID_' + unit.UnitCatalogID].Name
          } catch (error) {
          }
        })
        var FilterIt = false
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && !FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    FilterdManufacturers () {
      var FilteredArray = []
      this.sortedManufacturers.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.HashedID = '#' + item.ID
        try {
          item.NumberOfUnits = this.DeepFetcher(this.DecodedManufacturers, ['ID_' + item.ID, 'AllProducts']).length
        } catch (error) {
        }
        try {
          item.ActiveOrders = this.DeepFetcher(this.DecodedManufacturers, ['ID_' + item.ID, 'AllActiveOrders']).length
        } catch (error) {
        }
        var FilterIt = false
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && !FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedStocks () {
      var Sorted = []
      if (!this.ArchivedStocks) {
        Sorted = this.CookiesDataStocks
      } else {
        Sorted = this.CookiesDataArchivedStocks
      }
      return Sorted.sort(this.Sorting)
    },
    sortedActiveOrders () {
      var Sorted = this.ActiveOrders
      return Sorted.sort(this.SortingActiveOrders)
    },
    sortedManufacturers () {
      return this.GeneralSort(this.Manufacturers, this.sortManufacturerElment.name, this.sortManufacturerElment.value)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    },
    DecodedManufacturers () {
      var Manufacturers = {}
      this.Manufacturers.forEach(Manufacturer => {
        Manufacturers['ID_' + Manufacturer.ID] = Manufacturer
        Manufacturers['ID_' + Manufacturer.ID].AllProducts = []
        Manufacturers['ID_' + Manufacturer.ID].AllActiveOrders = []
      })
      try {
        this.Products.forEach(Product => {
          if (Manufacturers['ID_' + Product.ManufacturerID].AllProducts === undefined) {
            Manufacturers['ID_' + Product.ManufacturerID].AllProducts = []
          }
          Manufacturers['ID_' + Product.ManufacturerID].AllProducts.push(Product)
        })
      } catch (error) {
      }
      try {
        this.ActiveOrders.forEach(ActiveOrder => {
          if (Manufacturers['ID_' + ActiveOrder.ManufacturerID].AllActiveOrders === undefined) {
            Manufacturers['ID_' + ActiveOrder.ManufacturerID].AllActiveOrders = []
          }
          Manufacturers['ID_' + ActiveOrder.ManufacturerID].AllActiveOrders.push(ActiveOrder)
        })
      } catch (error) {
      }
      return Manufacturers
    },
    Tabs () {
      return [
        { Name: this.Translate('Manufacturing Orders'), Value: 'Orders' },
        { Name: this.Translate('Manufacturers'), Value: 'Manufacturers' }
      ]
    }
  },
  methods: {
    ReadProducts () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadProducts']).then(response => {
        this.Products = response
        response.forEach(product => {
          product.Units.forEach(UnitCatalog => {
            this.UnitCatalogsByID['ID_' + UnitCatalog.ID] = UnitCatalog
            this.UnitCatalogsByID['ID_' + UnitCatalog.ID].ProductName = product.Name
          })
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Stocks'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadManufacturers () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadManufacturers']).then(response => {
        this.Manufacturers = response
        this.ManufacturersByID = {}
        response.forEach(manufacturer => {
          this.ManufacturersByID['ID_' + this.DeepFetcher(manufacturer, 'ID')] = manufacturer
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadActiveOrders () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadManufacturingOrders']).then(response => {
        this.ActiveOrders = response
        this.ManufacturersOrders = {}
        response.forEach(order => {
          try {
            if (this.ManufacturersOrders['ID_' + order.ManufacturerID] === undefined) {
              this.ManufacturersOrders['ID_' + order.ManufacturerID] = []
            }
            this.ManufacturersOrders['ID_' + order.ManufacturerID].push(order)
          } catch (error) {
          }
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Manufacturing Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewProduct () {
      if (!this.Access('StockAdd')) {
        return
      }
      this.OpenDialog('Inventory_Product_Dialog', this.Translate('New Product'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewOrder () {
      if (!this.Access('ManufacturingOrdersAdd')) {
        return
      }
      this.OpenDialog('MRP_ManufacturingOrder_Dialog', this.Translate('New Manufacturing Order'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewManufacturer () {
      if (!this.Access('ManufacturersAdd')) {
        return
      }
      this.OpenDialog('MRP_Manufacturer_Dialog', this.Translate('New Manufacturer'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditManufacturer (manufacturer) {
      if (!this.Access('ManufacturersEdit')) {
        return
      }
      this.OpenDialog('MRP_Manufacturer_Dialog', this.Translate('Edit Manufacturer') + ' : ' + manufacturer.ID, { Data: manufacturer, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CancelOrder (order) {
      if (!this.Access('ManufacturingOrdersEdit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Remove Order') + ' : ' + order.ID, { Text: this.Translate('Confirm cancelling manufacturing order #') + order.ID, URL: ['MRP_ManufacturingOrders_Edit', '?ID=' + order.ID], Method: 'PATCH', RedButton: true, Icon: 'Removed', Data: { op: 'replace', path: '/IsCanceled', value: true } }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReceiveOrder (order) {
      if (!this.Access('ManufacturingOrdersEdit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Receive') + ' ' + this.Translate('Order') + ' : ' + order.ID, { Text: this.Translate('Confirm receiving manufacturing order #') + order.ID, URL: ['MRP_ManufacturingOrders_Edit', '?ID=' + order.ID], Method: 'PATCH', Icon: 'Done', Data: { op: 'replace', path: '/IsDone', value: true } }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ApproveOrder (order) {
      if (!this.Access('ManufacturingOrdersApprove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Approve') + ' ' + this.Translate('Order') + ' : ' + order.ID, { Text: this.Translate('Confirm approving manufacturing order #') + order.ID, URL: ['MRP_ManufacturingOrders_Edit', '?ID=' + order.ID], Method: 'PATCH', Icon: 'Approve', Data: { op: 'replace', path: '/IsApproved', value: true } }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditTransfer (Transfer) {
      if (!this.Access('StockTransfer')) {
        return
      }
      this.OpenDialog('Inventory_Transfers_Dialog', this.Translate('Edit Transfer') + ' : ' + '#' + Transfer.ID, { Data: Transfer, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditProduct (Stock) {
      if (!this.Access('StockEdit')) {
        return
      }
      this.OpenDialog('Inventory_Product_Dialog', this.Translate('Edit Product') + ' : ' + '#' + Stock.ID, { Data: Stock, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditOrder (ManufacturingOrder) {
      if (!this.Access('ManufacturingOrdersEdit')) {
        return
      }
      this.OpenDialog('MRP_ManufacturingOrder_Dialog', this.Translate('Edit Manufacturing Order') + ' : ' + '#' + ManufacturingOrder.ID, { Data: ManufacturingOrder, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortingActiveOrders (a, b) {
      var SortElement = this.sortManufacturingElment.name
      var SortDirection = this.sortManufacturingElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    RemoveProduct (Product) {
      this.OpenDialog('Delete_Dialog', this.Translate('Deleting Product'), [['Products/Delete', '?Id=' + Product.ID], Product.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveTransfer (Transfer) {
      this.OpenDialog('Delete_Dialog', this.Translate('Cancelling Transfer'), [['RemoveTransfer', '?Id=' + Transfer.ID], this.Translate('Transfer') + ' : ' + '#' + Transfer.ID]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveWarehouse (Warehouse) {
      this.OpenDialog('Delete_Dialog', this.Translate('Removing Warehouse'), [['StockTransactions/Delete', '?Id=' + Warehouse.ID], this.Translate('Warehouse') + ' : ' + Warehouse.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintBadge (Stock) {
      this.OpenDialog('StockBagde', this.LanguageSelector('', ''), Stock, { header: false, background: 'none' }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ChangePage (PageNumber) {
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            this.CurrentPage = PageNumber
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        } else {
          this.CurrentPage = PageNumber
        }
      } catch (error) {
        this.CurrentPage = PageNumber
      }
    },
    ChangeView (View) {
      this.CurrentView = View.Value
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadProducts()
      }
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85em;
  height: 80vh;
}
.CookiesMainView{
  padding-top: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
}
.StatusBar{
  top: 5.2em;
}
@media (max-width: 500px) {
  .BigHeader{
    font-size: 1.6em !important;
  }
  .StatusBar{
    font-size: 1em !important;
  }
  .StatusBar{
    top: 18.5em;
  }
  .BigPlaceholder{
    font-size: 0.7vw;
  }
}
@media (max-width: 2000px) {
  .BigPlaceholder{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder{
    font-size: 0.7vw;
  }
}
.FilterButton{
  display: none;
}
@media (max-width: 800px){
  .FilterButton{
    display: block;
  }
  .BigPlaceholder{
    font-size: 3vw;
  }
  .StocksHeader{
    font-size: 3vw;
    width: 100%;
    margin-top: 0.5em;
  }
  .CookiesSearchInputContainer{
    font-size: 3vw;
  }
}

.ItemsHolder{
  justify-content: center;
  width: 100%;
  gap: 1em;
  padding: 0.6em;
  overflow-y: scroll;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Item */

</style>
