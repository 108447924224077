
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  created () {
    this.ReadCookiesData()
    this.SelectedCatalogItems = this.LocalRead('CookiesCatalog_' + this.$route.params.CatalogID)
  },
  data () {
    return {
      CatalogID: null,
      Loading: ['Loading'] as Array<string>,
      CookiesData: [] as Array<Catalog>,
      CatalogAvailable: false,
      ErrorText: 'Cannot Find Catalog',
      SelectedCatalogItems: [] as Array<CatalogItem>,
      ViewType: 'GRID' as ViewType,
      OnlySelected: false
    }
  },
  computed: {
    FilteredItems () {
      if (this.OnlySelected) {
        return this.DeepFetcher(this.CookiesData, 'Items').filter((Item :CatalogItem) => {
          return this.SelectedCatalogItems.some((SelectedItem) => {
            return Item.ID === SelectedItem.ID
          })
        })
      } else {
        return this.DeepFetcher(this.CookiesData, 'Items')
      }
    }
  },
  methods: {
    ReadCatalogs () {
      this.Get('Catalogs').then(response => {
        this.CookiesData = response.filter(function (catalog :Catalog) { // handle multiple prop for multi selector strings into arrays arrays |||| [['selector1'], ['selector2a', 'selector2b']] => [['selector1'], ['selector2a', 'selector2b']]
          return !catalog.IsHidden
        })
        this.Console(response)
        this.CatalogAvailable = true
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.CatalogAvailable = false
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    IsItemSelected (ItemID: string) {
      return this.SelectedCatalogItems.some(item => {
        return item.ID === ItemID
      })
    },
    CatalogItemSelected (Item :CatalogItem) {
      this.SelectedCatalogItems.push(Item)
      this.LocalSave('CookiesCatalog_' + this.$route.params.CatalogID, this.SelectedCatalogItems)
    },
    CatalogItemDeSelected (Item :CatalogItem) {
      this.SelectedCatalogItems = this.SelectedCatalogItems.filter(item => {
        return item.ID !== Item.ID
      })
      if (this.SelectedCatalogItems.length === 0) {
        this.OnlySelected = false
      }
    },
    ReadCookiesData () {
      this.ReadCatalogs()
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewUser':
          this.OpenDialog('Security_User_Dialog', this.Translate('New User'), {})
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // this.ReadCookiesData()
      }
    }
  }
})
