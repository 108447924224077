
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      OriginalData: {} as CatalogItem,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'ID', Value: this.DialogData.Data.ID },
        { Path: 'QRURL', Value: this.DialogData.Data.QRURL },
        { Path: 'Name', Res: 'D_CatalogItem_Name', Default: '' },
        { Path: 'Code', Res: 'D_CatalogItem_Code' },
        { Path: 'ImgURL', Res: 'D_CatalogItem_Image', IsImage: true, Default: '' },
        { Path: 'IsNew', Res: 'D_CatalogItem_IsNew', notForSave: true, Default: false },
        { Path: 'IsEdited', Res: 'D_CatalogItem_IsEdited', notForSave: true, Default: false },
        { Path: 'Description', Res: 'D_CatalogItem_Description', Default: '' }
        // { Path: 'Items', Res: 'D_CatalogItem_Items_Table', Array: ['ID'] }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadCatalogItem()
    },
    ReadCatalogItem () {
      this.Loading.push('Loading')
      this.Get('Inventory_Items', `ID=${this.OriginalData.ID.toString()}`).then(response => {
        this.CookiesData = response
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as CatalogItem
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      if (this.Saving) {
        return
      }
      this.Saving = true
      if (this.Requirments(this).Incomplete) {
        this.ErrorsVisibilty = true
        this.Saving = false
        return
      }
      this.Saving = true
      if (this.MEMRead(this.DialogData.Data.Res) === undefined) {
        this.MEMWrite(this.DialogData.Data.Res, [])
      }
      if (this.DialogData.Editing) {
        this.MEMWrite([this.DialogData.Data.Res, this.DialogData.Data.ResIndex], this.DialogDataDecoder(this.DialogMapper, false))
        if (this.OriginalData.Description !== '' && (this.OriginalData.Description !== this.MEMRead('D_CatalogItem_Description')) && this.MEMRead('ChangeAllCat')) {
          this.MEMRead(this.DialogData.Data.Res).forEach((item :CatalogItem, index :number) => {
            if (item.Description === this.OriginalData.Description) {
              this.MEMWrite([this.DialogData.Data.Res, index, 'Description'], this.MEMRead('D_CatalogItem_Description'))
              this.MEMWrite([this.DialogData.Data.Res, index, 'IsEdited'], true)
            }
          })
        }
        this.MEMWrite([this.DialogData.Data.Res, this.DialogData.Data.ResIndex], this.DialogDataDecoder(this.DialogMapper, false))
      } else {
        this.MEMWrite(this.DialogData.Data.Res, this.MEMRead(this.DialogData.Data.Res).concat(this.DialogDataDecoder(this.DialogMapper, false)))
      }
      this.Saving = false
      this.$router.go(-1)
    },
    CropImage (file :File) {
      if (file) {
        this.Console('FILEEEEEE')
        this.Console(file)
        this.Console(file.name)
        if (this.MEMRead('D_CatalogItem_Code') === '' || this.MEMRead('D_CatalogItem_Code') === undefined) {
          this.MEMWrite('D_CatalogItem_Code', file.name.split('.')[0])
        }
        this.OpenDialog('CookiesImageCropper', '', { Data: { File: file, Res: 'D_CatalogItem_Image' } }, { header: false, background: 'transparent', FullWindow: true })
      }
    }
  },
  created () {
    if (this.DialogData.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data) as CatalogItem
    }
  },
  mounted () {
    if (this.DialogData.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data) as CatalogItem
      if (this.DialogData.Data.IsNew) {
        this.MEMWrite('D_CatalogItem_Name', this.DialogData.Data.Name)
        this.MEMWrite('D_CatalogItem_IsNew', true)
      }
      if (this.DialogData.Data.IsEdited) {
        this.MEMWrite('D_CatalogItem_Name', this.DialogData.Data.Name)
        this.MEMWrite('D_CatalogItem_IsEdited', true)
      }
      if (this.MEMRead('D_CatalogItem_Name') === '') {
        this.MEMWrite('D_CatalogItem_Name', this.DialogData.Data.ResIndex)
      }
    }
    // if (this.DialogData.Editing) {
    //   this.ReadDialogData()
    // }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_CatalogItem')
  },
  computed: {}
})
