
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Catalog>
    }
  },
  computed: {
    Filter () :FilterObject | undefined {
      return {
        Dates: true
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: this.Translate('Save .xlsx'),
          this: this,
          Function: 'SaveExcel',
          Icon: 'Reports',
          Visible: this.Access('Catalogue_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: {
          Direction: -1,
          Path: 'ModifiedAt'
        },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['ModifiedAt'],
            Sort: ['ModifiedAt'],
            DateStyle: 'DD/MM/YYYY - Time'
          },
          {
            Type: 'Normal',
            Name: 'Recipient',
            Path: ['Recipient'],
            Sort: ['Recipient'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Notification',
            Path: ['Title'],
            Sort: ['Title']
          }
        ]
      }
    },
    FilteredData () {
      var DateFrom = new Date(this.$store.state.CurrentDate.From)
      var DateTo = new Date(new Date(this.$store.state.CurrentDate.To).setHours(23, 59, 59, 9999))
      var DataInRange = this.CookiesData.filter((value :any) => {
        return new Date(value.ModifiedAt) >= DateFrom && new Date(value.ModifiedAt) <= DateTo
      })
      return this.CookiesFilter(DataInRange, this.Filter, this.Search).map((value) => {
        return {
          ...value,
          Title: this.Translate(value.Title)
        }
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Notification_History').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalogs'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'SaveExcel':
          this.DownloadExcel()
          break
      }
    },
    DownloadExcel () {
      var DatatoExport :any = this.FilteredData.map((element :any) => {
        // if (element.Specifications && element.Specifications[0] && element.Specifications[0].Name) addedspecs[element.Specifications[0].Name] = element.Specifications[0].Description
        // if (element.Specifications && element.Specifications[1] && element.Specifications[1].Name) addedspecs[element.Specifications[1].Name] = element.Specifications[1].Description
        return {
          Date: this.DateFormatter(element.CreatedAt),
          Recipient: element.Recipient,
          Title: element.Title
        }
      })
      this.ExportFromJSON(DatatoExport, 'Whatsapp Sent')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function () { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function () { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
