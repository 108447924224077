<template>
<div v-if="Access('ProductsAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <DialogDisabler v-if="Loading"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Transfer -------------------------------------- -->
      <div class="CookiesRow FlexBreaker">
        <div class="W-100 F-Bold O-75">{{Translate('Branch')}}</div>
        <div class="CookiesRow D-Flex" style="width: 65%;"> <!-- select Branch -->
          <CookiesDropInputObject @removed="Type === 'Export' ? SelectedProducts = [] : ''" :CookiesInvalidInput="MEMRead('D_Transfer_Warehouse') === ''" :ErrorData="ErrorsVisibilty && MEMRead('D_Transfer_Warehouse') === ''" :Res="'D_Visit'" :Name="Translate('Select Warehouse')" :Selectors="['Name']" :Options="MEMRead('Branches')"></CookiesDropInputObject>
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="W-100 F-Bold O-75">{{Translate('Customer')}}</div>
        <div class="CookiesRow D-Flex" style="width: 65%;"> <!-- select Customer -->
          <CookiesDropInputObject @removed="Type === 'Export' ? SelectedProducts = [] : ''" :CookiesInvalidInput="MEMRead('D_Transfer_Warehouse') === ''" :ErrorData="ErrorsVisibilty && MEMRead('D_Transfer_Warehouse') === ''" :Res="'D_Visit'" :Name="Translate('Select Warehouse')" :Selectors="['Name']" :Options="MEMRead('Branches')"></CookiesDropInputObject>
        </div>
        <div class="CookiesRowSplitter"/>
        <div :class="MEMRead('D_Transfer_Warehouse') === '' ? 'Disabled': ''" class="W-100 F-Bold O-75">{{Translate('Products')}}</div>
        <div class="CookiesRow D-Flex" style="width: 65%;"> <!-- select products -->
          <CookiesDropInputObject :Icon="'Icon'" :RowHeight="'5em'" :Disabled="MEMRead('D_Transfer_Warehouse') === ''" :CookiesInvalidInput="SelectedProducts.length === 0" :ErrorData="ErrorsVisibilty && SelectedProducts.length === 0" @selected="ProductSelected" :Res="'D_Transfer_Product'" :Name="Translate('Select Product')" :Selectors="[['AllName'], ['Code'], ['Price'], ['Quantity']]" :Options="FilterdProducts"></CookiesDropInputObject>
        </div>
        <div :class="MEMRead('D_Transfer_Warehouse') === '' ? 'Disabled': ''" class="CookiesRow D-Flex" style="width: 80%;"> <!-- selected products table -->
          <CookiesTable :RowHighliter="HighlightRows" @DeleteRow="DeleteProduct" class="Shadow0" :Header="false" :PrintControls="false" :Footer="false" :Fields="SelectedProductsFields" :Data="SelectedProducts"></CookiesTable>
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="W-100 F-Bold O-75">{{Translate('Notes')}}</div>
      </div>
      <textarea v-model="Notes" class="CookiesCommentBox"></textarea>
    </div>
  </div>
</div>
<div v-if="Access('ProductsAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
    <button @click="SaveTransfer()" :style="'background: ' + Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Save')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpGender = {}
  },
  created () {
    this.Loading = true
    this.ReadBranches()
    this.LoadEditingData()
  },
  data () {
    return {
      Editing: false,
      OriginalData: {},
      Notes: '',
      Code: '',
      ErrorsVisibilty: false,
      selectedFile: '',
      ProductImage: '',
      Supplier: [],
      Manufacturer: [],
      Category: '',
      DefaultCategory: '',
      DefaultManufacturer: '',
      DefaultSupplier: '',
      SelectedWarehouse: '',
      DefaultWarehouse: '',
      SelectedProducts: [],
      Type: 'Export',
      Warehouses: [
        {
          Name: 'Big Base',
          ID: 1,
          Description: 'string'
        },
        {
          Name: 'Old Town',
          ID: 2,
          Description: 'string'
        },
        {
          Name: 'New Avenue',
          ID: 3,
          Description: 'string'
        }
      ],
      Products: [
        {
          ID: 1,
          Name: 'Bed 1400',
          Code: '5200054',
          Status: 'Active',
          Notes: 'Empty Note',
          IsPreOrder: true,
          ManufacturerID: 1,
          SupplierID: 1,
          CategoryID: 2,
          Type: 'Main Products',
          ImgUrl: 'https://www.seoclerk.com/pics/407226-2eWiCl1471372939.jpg',
          Units: [
            {
              ID: 1,
              Name: 'Green',
              Code: 'Zx-5645',
              Price: 444,
              Cost: 33,
              Status: 'On Stock',
              Notes: 'string',
              IsDiscounted: false,
              DiscountPer: 5.5,
              Type: 'UnitCatalogType'
            },
            {
              ID: 3,
              Name: 'Yellow',
              Code: 'Cx-5881',
              Price: 4,
              Cost: 3,
              Status: 'On Stock',
              Notes: 'string',
              IsDiscounted: false,
              DiscountPer: 5.5,
              Type: 'UnitCatalogType'
            }
          ]
        },
        {
          ID: 2,
          Name: 'Bed 5430',
          Code: '4335',
          Status: 'Active',
          Notes: 'Empty Note',
          IsPreOrder: false,
          ManufacturerID: 2,
          SupplierID: 2,
          CategoryID: 3,
          Type: 'Main Products',
          ImgUrl: '',
          Units: [
            {
              ID: 2,
              Name: '100x500cm',
              Code: 's12',
              Price: 55,
              Cost: 40,
              Status: '',
              Notes: '',
              IsDiscounted: false,
              DiscountPer: 5.5,
              Type: 'UnitCatalogType'
            }
          ]
        },
        {
          ID: 3,
          Name: 'Pellows',
          Code: 'ssasc',
          Status: 'Active',
          Notes: 'Empty Note',
          IsPreOrder: false,
          ManufacturerID: 2,
          SupplierID: 2,
          CategoryID: 3,
          Type: 'Main Products',
          ImgUrl: '',
          Units: [
            {
              ID: 5,
              Name: '100x500cm',
              Code: 's12sadas',
              Price: 55,
              Cost: 40,
              Status: '',
              Notes: '',
              IsDiscounted: false,
              DiscountPer: 5.5,
              Type: 'UnitCatalogType'
            }
          ]
        }
      ],
      AllUnits: [
        {
          ID: 1,
          Name: 'Green',
          Code: 'Cx-54',
          Cost: 32,
          Quantity: 22,
          Status: 'On Stock',
          Notes: 'string',
          ProductID: 1,
          WarehouseID: 1,
          UnitCatalogID: 1,
          Type: 'UnitCatalogType'
        },
        {
          ID: 2,
          Name: 'Green',
          Code: 'Cx-scs445',
          Cost: 5.5,
          Quantity: 11,
          Status: 'On Stock',
          Notes: 'string',
          ProductID: 1,
          WarehouseID: 2,
          UnitCatalogID: 1,
          Type: 'UnitCatalogType'
        },
        {
          ID: 3,
          Name: '100x500cm',
          Code: 'Z1765',
          Cost: 4.3,
          Quantity: 6,
          Status: 'On Stock',
          Notes: 'string',
          ProductID: 2,
          WarehouseID: 1,
          UnitCatalogID: 4,
          Type: 'UnitCatalogType'
        }
      ],
      UnitCatalogs: [],
      Saving: false,
      UnitsCatolgsCount: 1,
      HighlightRows: {}
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    LoadEditingData () {
      if (this.DialogData.Editing) {
        this.Editing = true
        var DataImage = this.DeepCloner(this.DialogData.Data)
        this.OriginalData = {
          ID: DataImage.ID,
          Type: DataImage.Type,
          Description: DataImage.Description,
          DescriptionAr: DataImage.DescriptionAr,
          WarehouseID: DataImage.WarehouseID,
          Units: []
        }
        // ////////////////// Loading Warehouse
        var LoadedWarehouse = this.ObjectFinder(this.Warehouses, 'ID', this.DeepFetcher(this.OriginalData, ['WarehouseID']))
        this.MEMWrite('D_Transfer_Warehouse_Value', LoadedWarehouse)
        // ///////////////////////
        // appying original data to the dialog
        this.Type = this.DeepFetcher(this.OriginalData, 'Type')
        this.Notes = this.OriginalData[this.ServerDescription]
        try {
          DataImage.Units.forEach(unit => {
            var AddingUnit = {}
            AddingUnit.ID = unit.ID
            AddingUnit.Quantity = unit.Quantity
            if (this.Type === 'Import') {
              AddingUnit.Cost = unit.Cost
            }
            this.OriginalData.Units.push(AddingUnit)
            var theProduct = this.Decodedproducts['Product_' + unit.ProductID]
            var theUnitCatalog = this.ObjectFinder(this.DeepFetcher(theProduct, 'Units'), 'ID', unit.ID)
            unit.HashedID = '#' + unit.ID
            unit.AllName = this.DeepFetcher(theProduct, 'Name') + ' (' + unit.Name + ') '
            unit.ProductName = this.DeepFetcher(theProduct, 'Name')
            unit.Code = this.DeepFetcher(theUnitCatalog, 'Code')
            this.ProductSelected(unit)
            this.Console('unit')
            this.Console(unit)
            this.MEMWrite('ProductQuantity_' + unit.ID, unit.Quantity)
            if (this.Type === 'Import') {
              this.MEMWrite('ProductCost_' + unit.ID, unit.Cost)
            }
          })
        } catch (error) {
        }
        this.Loading = false
      } else {
        this.Loading = false
      }
    },
    ReadStocks () {
      this.Get('Stock').then(response => {
        this.AllUnits = response
        this.LoadEditingData()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Stocks'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadWarehouses () {
      this.Get('Warehouses').then(response => {
        this.Warehouses = response
        this.ReadProducts()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBranches () {
      this.Get(['ReadBranches']).then(response => {
        this.MEMWrite('Branches', response)
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadProducts () {
      this.Get('Products').then(response => {
        this.Products = response
        this.ReadStocks()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Products'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveTransfer () {
      const _this = this
      if (this.Requirments(this).Incomplete) { // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      PostNow()
      // Post // Patch
      function PostNow () {
        let Method = 'POST'
        let API = 'Product'
        let Data = _this.RecordData
        let SavingError = 'Error in Adding New Product'
        if (_this.Editing) {
          Method = 'PATCH'
          API = 'Product/Edit'
          Data = _this.CookiesPatcher(_this.OriginalData, _this.RecordData)
          SavingError = 'Error in Saving Product'
          if (Data.length === 0) {
            _this.Saving = false
            _this.$router.go(-1)
            return
          }
        }
        _this.Post(Method, API, Data).then(response => {
          _this.Saving = false
          _this.CloseDialog(_this.DialogIndex)
        }, error => {
          _this.Saving = false
          error.CookiesError = SavingError
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    ProductSelected (productToSelect) {
      var SelectedTest = this.SelectedProducts.some(product => {
        return product.ID === productToSelect.ID
      })
      if (!SelectedTest) {
        var ProductModifier = this.DeepCloner(productToSelect)
        ProductModifier.QuantityRes = 'ProductQuantity_' + productToSelect.ID
        ProductModifier.CostRes = 'ProductCost_' + productToSelect.ID
        this.Console(ProductModifier)
        this.SelectedProducts.push(ProductModifier)
        this.Console(this.SelectedProducts)
        this.MEMWrite('D_Transfer_Product', '')
        // if (this.Editing) {
        //   this.MEMWrite('ProductQuantity_' + productToSelect.ID, productToSelect)
        // }
      } else {
        this.HighlightRows = { Value: 'ID', Rows: [productToSelect.ID] }
        setTimeout(() => {
          this.HighlightRows = {}
        }, 1200)
        this.MEMWrite('D_Transfer_Product', '')
      }
    },
    DeleteProduct (product) {
      try {
        var porductIndex = this.IndexFinder(this.SelectedProducts, 'ID', product.ID)
        this.SelectedProducts.splice(porductIndex, 1)
      } catch (error) {
      }
    },
    ChangeType (type) {
      this.Type = type.Value
      this.SelectedProducts = []
    }
  },
  mounted () {
    if (!this.Access('ProductsAdd')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    FilterdProducts () {
      var FilteredArray = []
      if (this.Type === 'Export') {
        this.AllUnits.forEach(unit => {
          var FilterIt = false
          try {
            if (this.MEMRead('D_Transfer_Warehouse_Value').ID !== unit.WarehouseID) {
              FilterIt = true
            }
          } catch (error) {
          }
          if (!FilterIt) {
            var ProductNow = this.Decodedproducts['Product_' + unit.ProductID]
            var UnitCatalogNow = this.ObjectFinder(this.DeepFetcher(ProductNow, 'Units'), 'ID', unit.ID)
            unit.HashedID = '#' + unit.ID
            unit.Price = this.DeepFetcher(UnitCatalogNow, 'Price')
            unit.AllName = this.DeepFetcher(ProductNow, 'Name') + ' (' + unit.Name + ') '
            unit.ProductName = this.DeepFetcher(ProductNow, 'Name')
            unit.Icon = this.DeepFetcher(ProductNow, 'ImgUrl')
            FilteredArray.push(unit)
          }
        })
      } else if (this.Type === 'Import') {
        this.Products.forEach(Product => {
          Product.Units.forEach(unitCatalog => {
            var FilterIt = false
            if (!FilterIt) {
              unitCatalog.HashedID = '#' + unitCatalog.ID
              unitCatalog.AllName = this.DeepFetcher(Product, 'Name') + ' (' + unitCatalog.Name + ') '
              unitCatalog.ProductName = this.DeepFetcher(Product, 'Name')
              unitCatalog.Icon = this.DeepFetcher(Product, 'ImgUrl')
              try {
                unitCatalog.Quantity = this.DeepFetcher(this.DecodedUnits, ['Warehouses', 'ID_' + this.MEMRead('D_Transfer_Warehouse_Value').ID, 'UnitCatalogs', 'ID_' + unitCatalog.ID])
              } catch (error) {
              }
              if (unitCatalog.Quantity === undefined) {
                unitCatalog.Quantity = 0
              }
              FilteredArray.push(unitCatalog)
            }
          })
        })
      }
      return FilteredArray
    },
    RecordData () {
      var CurrentData = {
        ID: this.OriginalData.ID,
        Type: this.Type,
        WarehouseID: this.MEMRead('D_Transfer_Warehouse_Value').ID,
        Units: []
      }
      if (this.OriginalData[this.ServerDescription] !== this.Notes) {
        CurrentData.Description = this.Notes
        CurrentData.DescriptionAr = this.Notes
      } else {
        CurrentData.Description = this.OriginalData.Description
        CurrentData.DescriptionAr = this.OriginalData.DescriptionAr
      }
      this.SelectedProducts.forEach(unit => {
        var CurrentUnit = {}
        CurrentUnit.ID = unit.ID
        CurrentUnit.Quantity = this.MEMRead('ProductQuantity_' + unit.ID)
        if (this.Type === 'Import') {
          CurrentUnit.Cost = this.MEMRead('ProductCost_' + unit.ID)
        }
        CurrentData.Units.push(CurrentUnit)
      })
      return CurrentData
    },
    SelectedProductsFields () {
      var Fields = []
      Fields.push({ type: 'index' })
      Fields.push({ name: this.Translate('Product'), value: ['AllName'] })
      Fields.push({ name: this.Translate('Code'), value: ['Code'] })
      Fields.push({ name: this.Translate('Price'), value: ['Price'], unit: this.PriceUnit })
      this.Type === 'Export' && Fields.push({ name: this.Translate('Cost'), value: ['Cost'], unit: this.PriceUnit })
      this.Type === 'Import' && Fields.push({ name: this.Translate('Cost'), value: ['Cost'], InputUnit: this.PriceUnit, type: 'numberInput', min: 0, res: ['CostRes'] })
      Fields.push({ name: this.Translate('Quantity'), value: ['Quantity'], type: 'numberInput', min: 1, res: ['QuantityRes'] })
      Fields.push({ type: 'delete', value: ['index'] })
      return Fields
    },
    Tabs () {
      return [
        { Name: this.Translate('Export'), Value: 'Export', Icon: 'Export' },
        { Name: this.Translate('Import'), Value: 'Import', Icon: 'Import' }
      ]
    },
    DecodedUnits () {
      var WarehousesUnits = {}
      var CatalogIDUnits = {}
      var ProductUnits = {}
      this.Warehouses.forEach(warehouse => {
        WarehousesUnits['ID_' + warehouse.ID] = {
          TotalUnits: 0,
          Products: {},
          UnitCatalogs: {}
        }
      })
      this.AllUnits.forEach(unit => {
        if (CatalogIDUnits['ID_' + unit.UnitCatalogID] === undefined) {
          CatalogIDUnits['ID_' + unit.UnitCatalogID] = 0
        }
        if (ProductUnits['ID_' + unit.ProductID] === undefined) {
          ProductUnits['ID_' + unit.ProductID] = 0
        }
        if (WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] === undefined) {
          WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] = 0
        }
        if (WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] === undefined) {
          WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] = 0
        }
        CatalogIDUnits['ID_' + unit.UnitCatalogID] += unit.Quantity
        ProductUnits['ID_' + unit.ProductID] += unit.Quantity
        WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] += unit.Quantity
        WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] += unit.Quantity
        WarehousesUnits['ID_' + unit.WarehouseID].TotalUnits += unit.Quantity
      })
      return {
        UnitCatalogs: CatalogIDUnits,
        Products: ProductUnits,
        Warehouses: WarehousesUnits
      }
    },
    Decodedproducts () {
      var ProductsObj = {}
      this.Products.forEach(product => {
        ProductsObj['Product_' + product.ID] = product
      })
      return ProductsObj
    },
    WareHouseUnits () {
      if (this.MEMRead('D_Transfer_Warehouse_Value') === undefined) {
        return this.DecodedUnits
      } else {
        return this.DecodedUnits.Warehouses['ID_' + this.MEMRead(['D_Transfer_Warehouse_Value', 'ID'])]
      }
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 750px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .ProductImageHolder{
    order: -1;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

.ProductImage{
  width: 10em;
  aspect-ratio: 1;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: var(--rad1);
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.ProductImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
