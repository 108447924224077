<template>
<div class="M-Auto ReportFontSize">
  <div class="D-Flex ReportFilter">
    <input @blur="SearchNow" @keypress="SearchKey" v-model="SearchInput" class="CookiesInput F1_5em" :placeholder="LanguageSelector('Search', 'بحث')" type="text">
    <!-- Search Button -->
    <div v-if="SearchInput !== CurrentSearch" @click="SearchNow" class="SearchBarIcon HoverUp"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Search'"></CookiesIcon></div>
    <!-- Cancel Search Button -->
    <div v-if="CurrentSearch !== '' && SearchInput === CurrentSearch" @click="SearchInput = ''; CurrentSearch = ''" class="SearchBarIcon HoverUp"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Removed'"></CookiesIcon></div>
    <div class="D-Flex" style="flex:3">
      <!-- <CookiesDropDown @slection_changed="ReadJournalEntries" :Res="'R_Customers_Customer'" :Type="'Object'" :Selector="'Company'" :Name="LanguageSelector('Customer', 'العميل')" :Options="CookiesDataCustomers"></CookiesDropDown> -->
      <div @click="printIT" class="PrintButton D-Flex RowMargin">
        <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
        <span class="F-Bold M-Auto">Print</span>
      </div>
    </div>
  </div>
  <div class="CookiesRowSplitter"></div>
  <CookiesTable :Loading="Loading" id="TableToPrint" :TableName="TableName" :DefaultSort="{ value: -1, name: ['UPDDate'] }" :Data="FilteredData" :Fields="Fields" :Search="CurrentSearch"></CookiesTable>
</div>
</template>

<script>
export default {
  props: {
    DateFrom: { type: String },
    DateTo: { type: String }
  },
  data () {
    return {
      SearchInput: '',
      CurrentSearch: '',
      Orders: [],
      CookiesDataCustomers: [],
      CookiesDataJournalEntries: [],
      CookiesDataLines: [],
      Loading: true,
      Fields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false,
          value: ['index']
        },
        {
          name: this.LanguageSelector('Date', 'التاريخ'),
          value: ['Date'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Time', 'الوقت'),
          value: ['Date'],
          sort: true,
          type: 'time'
        },
        {
          id: true,
          name: this.LanguageSelector('Order ID', 'رقم الطلب'),
          value: ['RefId', 0, 'ID'],
          sort: true
        },
        {
          name: this.LanguageSelector('Amount', 'القيمة'),
          value: ['Amount'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Type', 'النوع'),
          value: ['Type'],
          sort: true
        },
        {
          name: this.LanguageSelector('Description', 'الوصف'),
          value: ['Description'],
          sort: true
        }
      ]
    }
  },
  computed: {
    FilteredData () {
      var Filtered = []
      this.Console(this.CookiesDataJournalEntries, 'color: black; background: yellow')
      this.CookiesDataJournalEntries.forEach(entry => {
        entry.Amount = -this.CreditMapper(entry.Type, entry.Amount)
        entry.Type = this.CreditMapper(entry.Type)
        var FilterIt = true
        if (FilterIt) {
          Filtered.push(entry)
        }
      })
      return Filtered
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    },
    TableName () {
      var ENG = 'Expenses Account report from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To
      var ARA = 'كشف حساب المصاريف من: ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To
      return this.LanguageSelector(ENG, ARA)
    }
  },
  mounted () {
    this.ReadJournalEntries()
    // this.ReadJournalEntries()
    if (!this.$store.state.UserManagment.Status) {
      this.$router.replace('/SignIn')
    }
  },
  methods: {
    ReadJournalEntries () {
      this.Loading = true
      this.Get('Financial/GetAccount?ID=' + this.AccountMapper.M_Expenses).then(response => {
        var ExpensesLedgerID = this.LedgerFinder(this.DeepFetcher(response[0], ['ledgers']), this.LedgersMapper.Expenses, 1).ID
        this.Console(response, 'background: orange')
        this.Console(ExpensesLedgerID, 'background: orange')
        if (ExpensesLedgerID === undefined) {
          this.Loading = false
          return
        }
        this.Get('Financial/JournalEntry?' + this.DateAPIURL + '&LedgerId=' + ExpensesLedgerID).then(response => {
          this.CookiesDataJournalEntries = response
          this.Loading = false
        }, error => {
          this.Loading = false
          error.CookiesError = 'Error in reading Journal Entries'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Revenue Account'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      this.$store.state.Printing = bodd.cloneNode([true])
    },
    SearchNow () {
      this.CurrentSearch = this.SearchInput
    },
    SearchKey () {
      if ((event.which) === 13) {
        this.CurrentSearch = this.SearchInput
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadJournalEntries()
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadJournalEntries()
    }
  }
}
</script>
<style scoped>
.RowMargin{
  margin: auto 0.5em;
}
.CookiesInput{
  padding: 5px 10px;
  border: solid 1px var(--wshade0);
  filter: drop-shadow(var(--shadow0));
  /* background: var(--colorw2); */
}
.PrintButton{
  border-radius: 5em;
  background: v-bind('Theme.BG2');
  padding: 0.7em 1.2em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
.CookiesSelectBotton{
  margin: auto 0.5em !important;
}
@media (max-width: 800px) {
  .ReportFilter{
    flex-direction: column;
  }
}
</style>
