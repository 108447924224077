
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Default: { default: undefined, type: String },
    type: { default: 'text', type: String },
    Res: { type: String },
    ErrorData: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Placeholder: { default: '', type: String },
    PLaceholder: { default: '', type: String },
    PreserveMemory: { default: false, type: Boolean },
    Name: { default: '', type: String }
  },
  data () {},
  mounted () {
    if (this.Default) this.MEMWrite(this.Res, this.Default)
    if (!this.MEMRead(this.Res)) this.MEMWrite(this.Res, '')
  },
  unmounted () {
    if (this.Res && !this.PreserveMemory) {
      this.MEMClean(this.Res)
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3db986a3": (_ctx.$store.getters.DefaultTextAlign),
  "3cfe1f3f": (_ctx.Theme.color2),
  "43f987b6": (_ctx.Theme.UserColors.third_50),
  "3cfe1f40": (_ctx.Theme.color3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__