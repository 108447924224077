
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<LogInUser>,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Profile',
            Visible: true,
            Options: this.Profiles,
            Selectors: ['Name'],
            Res: 'Users_Filter_Profiles',
            DataPath: ['Profile'],
            ListPath: ['Name']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: this.Translate('Active'),
                Visible: this.Access('Security_Users_Edit'),
                Icon: 'Done',
                Res: 'Users_Filter_Active',
                Value: 'Active',
                DataPath: ['Status']
              },
              {
                Name: this.Translate('Inactive'),
                Visible: this.Access('Security_Users_Edit'),
                Icon: 'Inactive',
                Res: 'Users_Filter_Inactive',
                Value: 'Inactive',
                DataPath: ['Status']
              }
            ]
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New User',
          this: this,
          Function: 'NewUser',
          Icon: 'Add',
          Visible: this.Access('Security_Users_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'AddedDate' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Style: 'width: 2em',
            ImageStyle: 'border-radius: 100%; width: 3em; height: 3em;',
            ImageClass: 'Shadow1',
            ImageDefault: this.$store.state.CookiesConfig.UserImage
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Type: 'Normal',
            Name: 'Email',
            Path: ['Email'],
            Sort: ['Email']
          },
          {
            Type: 'Normal',
            Name: 'Profile',
            Path: ['Profile'],
            Sort: ['Profile']
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              Active: 'BGGreen',
              Inactive: 'BGRed'
            },
            Bold: true
          },
          {
            Type: 'DateTime',
            Name: 'Date Added',
            Path: 'AddedDate',
            Sort: 'AddedDate'
          },
          {
            Type: 'ActionIcons',
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: this.$store.state.UserManagment.CurrentUser.UserInfo.ID,
              Operator: 'EQUAL',
              ValuePath: 'ID'
            },
            ActionIcons: [
              {
                ID: 'Inactive',
                Visible: this.Access('Security_Users_Edit'),
                TooltTip: this.Translate('Deactivate'),
                Emits: 'SwapStatus',
                Style: 'filter: saturate(0); opacity: 0.5;',
                DynamicVisiblity: {
                  FixedValue: 'Inactive',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Inactive',
                Visible: this.Access('Security_Users_Edit'),
                TooltTip: this.Translate('Activate'),
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Active',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('Security_Users_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditUser'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteUser',
                Visible: this.Access('Security_Users_Remove'),
                TooltTip: this.Translate('Remove')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.ReadProfiles()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Security_Users').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadProfiles () {
      this.Loading.push('Loading')
      this.Get('Security_Profiles').then(response => {
        this.Profiles = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Profiles'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditUser (User :LogInUser) {
      if (!this.Access('Security_Users_Edit')) {
        return
      }
      this.OpenDialog('Security_User_Dialog', this.Translate('Edit User') + ' (' + User.Name + ')', { Data: User, Editing: true }, {})
    },
    DeleteUser (User :LogInUser) {
      if (!this.Access('Security_Users_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Security_Users', AddQuery: '?id=' + User.ID, Text: 'Are you sure you want to delete <strong>' + User.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.UserDeleted, AfterDialogFunctionValue: User.ImgUrl } as GeneralAcceptDialogData, {})
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewUser':
          this.OpenDialog('Security_User_Dialog', this.Translate('New User'), {})
          break
      }
    },
    SwapStatus (User :LogInUser) {
      this.Loading.push('Loading')
      var CurrentStatus = User.Status
      var PatchValue :any
      if (CurrentStatus === 'Active') {
        PatchValue = this.CookiesPatcher({ Status: 'Active' }, { Status: 'Inactive' })
      } else {
        PatchValue = this.CookiesPatcher({ Status: 'Inactive' }, { Status: 'Active' })
      }
      this.Post('PATCH', 'Security_Users', PatchValue, '?id=' + User.ID).then(response => {
        this.ReadCookiesData()
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in editing status'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
