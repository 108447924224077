
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {}
  },
  computed: {
    Name () {
      return this.Translate('Inventory')
    },
    Tabs () {
      return [
        { Name: this.Translate('Exports'), Link: 'Exports', Access: this.Access('Inventory_Transfers_Read') },
        { Name: this.Translate('Imports'), Link: 'Imports', Access: this.Access('Inventory_Transfers_Read') },
        { Name: this.Translate('Transfer'), Link: 'InternalTransfers', Access: this.Access('Inventory_Transfers_Read') },
        { Name: this.Translate('Items'), Link: 'Items', Access: this.Access('Inventory_Items_Read') },
        { Name: this.Translate('Warehouses'), Link: 'Warehouses', Access: this.Access('Inventory_Warehouses_Read') }
        // { Name: this.Translate('Catalogs'), Link: 'Catalogs', Access: this.Access('Catalogue_Read') }
      ] as Array<CookiesTabs>
    }
  },
  methods: {},
  watch: {}
})
