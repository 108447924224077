<template>
<div class="M-Auto ReportFontSize">
  <div class="D-Flex ReportFilter">
    <input v-model="SearchInput" class="CookiesInput F1_5em" :placeholder="LanguageSelector('Search', 'بحث')" type="text">
    <div class="D-Flex" style="flex:3">
      <CookiesDropDown @slection_changed="ReadJournalEntries" :Res="'R_Customers_Customer'" :Type="'Object'" :Selector="'Company'" :Name="LanguageSelector('Customer', 'العميل')" :Options="CookiesDataCustomers"></CookiesDropDown>
      <div @click="printIT" class="PrintButton D-Flex RowMargin">
        <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
        <span class="F-Bold M-Auto">Print</span>
      </div>
    </div>
  </div>
  <div class="CookiesRowSplitter"></div>
  <CookiesTable :Loading="Loading" id="TableToPrint" :TableName="TableName" :Data="FilteredData" :Fields="Fields" :Search="SearchInput"></CookiesTable>
</div>
</template>

<script>
export default {
  props: {
    DateFrom: { type: String },
    DateTo: { type: String }
  },
  data () {
    return {
      SearchInput: '',
      Orders: [],
      CookiesDataCustomers: [],
      CookiesDataJournalEntries: [],
      CookiesDataLines: [],
      Loading: true
    }
  },
  computed: {
    FilteredData () {
      var Filtered = []
      this.Console(this.$store.state.CookiesMemory.R_Customers_Customer, 'color: black; background: yellow')
      if (this.$store.state.CookiesMemory.R_Customers_Customer === undefined) {
        return []
      }
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Customers_Customer.Company) >= 0)) {
        this.CookiesDataCustomers.forEach(customer => {
          var FilterIt = true
          if (FilterIt) {
            var DeliveredLedger = {}
            var CustomersLedgers = this.DeepFetcher(customer, ['Balance', 'ledgers'])
            if (this.ObjectFinder(CustomersLedgers, ['Type'], 17)) {
              DeliveredLedger = this.ObjectFinder(CustomersLedgers, ['Type'], 17)
              DeliveredLedger.NetBalance = DeliveredLedger.Credited - DeliveredLedger.Debited
            }
            customer.DeliveredLedger = DeliveredLedger
            Filtered.push(customer)
          }
        })
      } else {
        this.CookiesDataJournalEntries.forEach(entry => {
          entry.Amount = this.CreditMapper(entry.Type, entry.Amount)
          entry.Type = this.CreditMapper(entry.Type)
          var FilterIt = true
          if (FilterIt) {
            Filtered.push(entry)
          }
        })
      }
      return Filtered
    },
    Fields () {
      if (this.$store.state.CookiesMemory.R_Customers_Customer === undefined) {
        return []
      }
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Customers_Customer.Company) >= 0)) {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Customer', 'العميل'),
            value: ['Company'],
            sort: true
          },
          {
            name: this.LanguageSelector('Phone Number', 'الهاتف'),
            value: ['PhoneNumber'],
            sort: true
          },
          {
            name: this.LanguageSelector('Balance In', 'المستحقات'),
            value: ['DeliveredLedger', 'Credited'],
            sort: true,
            type: 'number',
            pricetag: true
          },
          {
            name: this.LanguageSelector('Balance Out', 'المصاريف'),
            value: ['DeliveredLedger', 'Debited'],
            sort: true,
            type: 'number',
            pricetag: true
          },
          {
            name: this.LanguageSelector('Total', 'الاجمالي'),
            value: ['DeliveredLedger', 'NetBalance'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          }
        ]
      } else {
        return [
          {
            name: 'index',
            noheader: true,
            type: 'index',
            print: false
          },
          {
            name: this.LanguageSelector('Date', 'التاريخ'),
            value: ['Date'],
            sort: true,
            type: 'date'
          },
          {
            name: this.LanguageSelector('Time', 'الوقت'),
            value: ['Date'],
            sort: true,
            type: 'time'
          },
          {
            name: this.LanguageSelector('Order', 'الطلب'),
            value: ['RefId']
          },
          {
            name: this.LanguageSelector('Amount', 'القيمة'),
            value: ['Amount'],
            sort: true,
            type: 'number',
            pricetag: true,
            total: true,
            bold: true
          },
          {
            name: this.LanguageSelector('Type', 'النوع'),
            value: ['Type'],
            sort: true
          },
          {
            name: this.LanguageSelector('Description', 'الوصف'),
            value: ['Description']
          }
        ]
      }
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    },
    TableName () {
      var ENG = 'Account report'
      var ARA = 'كشف حساب'
      if (this.$store.state.CookiesMemory.R_Customers_Customer && !(['All', 'الكل'].indexOf(this.DeepCloner(this.$store.state.CookiesMemory.R_Customers_Customer.Company)) >= 0)) {
        ENG = 'Account report' + 'for (' + this.$store.state.CookiesMemory.R_Customers_Customer.Company + ') from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To
        ARA = 'كشف حساب' + ' للعميل (' + this.$store.state.CookiesMemory.R_Customers_Customer.Company + ') من: ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To
      } else {
        ENG = 'Account report from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To
        ARA = 'كشف حساب من ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To
      }
      return this.LanguageSelector(ENG, ARA)
    }
  },
  mounted () {
    this.ReadCustomers()
    // this.ReadJournalEntries()
    if (!this.$store.state.UserManagment.Status) {
      this.$router.replace('/SignIn')
    }
  },
  methods: {
    ReadJournalEntries () {
      if ((['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Customers_Customer.Company) >= 0)) {
        return
      }
      this.Loading = true
      var CashLedger = this.ObjectFinder(this.DeepFetcher(this.$store.state.CookiesMemory.R_Customers_Customer, ['Balance', 'ledgers']), ['Type'], 16)
      this.Get('Financial/JournalEntry?' + this.DateAPIURL + '&LedgerId=' + CashLedger.ID).then(response => {
        this.CookiesDataJournalEntries = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Journal Entries'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Loading = true
      this.Get('Customers').then(response => {
        this.CookiesDataCustomers = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      this.$store.state.Printing = bodd.cloneNode([true])
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadJournalEntries()
      this.ReadCustomers()
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadJournalEntries()
      this.ReadCustomers()
    }
  }
}
</script>
<style scoped>
.RowMargin{
  margin: auto 0.5em;
}
.CookiesInput{
  padding: 5px 10px;
  border: solid 1px var(--wshade0);
  filter: drop-shadow(var(--shadow0));
  /* background: var(--colorw2); */
}
.PrintButton{
  border-radius: 5em;
  background: v-bind('Theme.BG2');
  padding: 0.7em 1.2em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
.CookiesSelectBotton{
  margin: auto 0.5em !important;
}
@media (max-width: 800px) {
  .ReportFilter{
    flex-direction: column;
  }
}
</style>
