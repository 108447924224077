
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      Sections: [] as any,
      ItemsLocation: [] as any,
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: 'BrandName'
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Class: 'F1_25em'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Visible: this.DialogData?.Data.Status !== 'Done' || (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer')
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    TableSettingsExport () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: 'BrandName'
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Class: 'F1_25em'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Class: 'F1_25em'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'ExportLocation',
            Class: 'F1_25em',
            Bold: true,
            Style: 'min-width: 11em'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath === 'Not Enough Space'
        })
        console.log(Found)
        if (Found) {
          NoSpace = true
        }
      })
      return NoSpace
    },
    TransferItemsFrom () {
      return this.TransferItems.filter(element => {
        return element.Type === 'Reserved'
      }).map(element => {
        var Location = ''
        var ItemLoc = this.ItemsLocation.find((loc :any) => {
          return loc.ID === element.ItemLocationID
        })
        console.log(element)
        console.log('ItemLoc')
        console.log(ItemLoc)
        if (ItemLoc) {
          var Section = this.Sections.find((sec :any) => {
            return ItemLoc.SectionID === sec.ID
          })
        }
        // console.log('Section')
        // console.log(Section)
        if (Section) {
          var Sectionlevel1 = null
          var Sectionlevel2 = null
          if (Section.SectionLevel1ID) {
            Sectionlevel1 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel1ID
            })
          }
          if (Section.SectionLevel2ID) {
            Sectionlevel2 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel2ID
            })
          }
          Location = (Section.WarehouseName && Section.WarehouseName !== '' ? Section.WarehouseName : '') + `(${Section.WarehouseCode})` + (Sectionlevel1 ? (' > ' + (Sectionlevel1.Name ? Sectionlevel1.Name : '') + `(${Sectionlevel1.Code})`) : '') + (Sectionlevel2 ? (' > ' + (Sectionlevel2.Name ? Sectionlevel2.Name : '') + `(${Sectionlevel2.Code})`) : '') + ' > ' + (Section.Name && Section.Name !== '' ? Section.Name : '') + `(${Section.Code})`
        }
        console.log('Location')
        console.log(Location)
        return {
          ExportLocation: Location,
          ...element
        }
      })
    },
    TransferItemsTo () {
      return this.TransferItems.filter(element1 => {
        return element1.Type === 'Ordered'
      }).map(element => {
        var Location = ''
        var ItemLoc = this.ItemsLocation.find((loc :any) => {
          return loc.ID === element.ItemLocationID
        })
        console.log(element)
        console.log('ItemLoc')
        console.log(ItemLoc)
        if (ItemLoc) {
          var Section = this.Sections.find((sec :any) => {
            return ItemLoc.SectionID === sec.ID
          })
        }
        console.log('Section')
        console.log(Section)
        if (Section) {
          var Sectionlevel1 = null
          var Sectionlevel2 = null
          if (Section.SectionLevel1ID) {
            Sectionlevel1 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel1ID
            })
          }
          if (Section.SectionLevel2ID) {
            Sectionlevel2 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel2ID
            })
          }
          Location = (Section.WarehouseName && Section.WarehouseName !== '' ? Section.WarehouseName : '') + `(${Section.WarehouseCode})` + (Sectionlevel1 ? (' > ' + (Sectionlevel1.Name ? Sectionlevel1.Name : '') + `(${Sectionlevel1.Code})`) : '') + (Sectionlevel2 ? (' > ' + (Sectionlevel2.Name ? Sectionlevel2.Name : '') + `(${Sectionlevel2.Code})`) : '') + ' > ' + (Section.Name && Section.Name !== '' ? Section.Name : '') + `(${Section.Code})`
        }
        console.log('Location')
        console.log(Location)
        return {
          ExportLocation: Location,
          ...element
        }
      })
    },
    CombinedItems () {
      if (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer' && !this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`]) {
        return this.TransferItems
      }
      var Combined :Array<any> = []
      if (!this.TransferItems) {
        return Combined
      }
      this.TransferItems.forEach(element => {
        var Found = Combined.find((added:any) => {
          if (this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`]) return added.ItemID === element.ItemID
          return !element.IsUserSelect && added.ItemID === element.ItemID
        })
        if (Found) {
          Found.Quantity += element.IsPack ? element.Quantity * element.PackSize : element.Quantity
        } else {
          // var Name = element.Name ? element.Name : this.ObjectFinder()
          Combined.push({ ...element, Quantity: element.IsPack ? element.Quantity * element.PackSize : element.Quantity, PackSize: 1 })
        }
      })
      return Combined
    }
  },
  // ---------------------------------------------------
  methods: {
    ReadItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      var ItemsIds = this.TransferItems.map((item :any) => {
        return item.ItemID
      })
      if (!ItemsIds || ItemsIds.length === 0) {
        this.Loading.shift()
        return
      }
      this.Get('Inventory_Items', '?ID=[' + ItemsIds + ']' + '&ListAllWarehouses=true').then(response => {
        var ComputedItems = this.TransferItems
        if (!Array.isArray(response)) {
          response = [response]
        }
        this.TransferItems = ComputedItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            item.Specifications = CuurentItem.Specifications
            if (CuurentItem.Name) item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            item.CategoryName = CuurentItem.CategoryName
            item.BrandName = CuurentItem.BrandName
            item.IsUserSelect = CuurentItem.IsUserSelect
            item.ImportLocations = this.ImportLocations['Item_' + item.ItemID]
          }
          return item
        })
        this.ExportItems = this.AvailableItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            item.Specifications = CuurentItem.Specifications
            if (CuurentItem.Name) item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            item.CategoryName = CuurentItem.CategoryName
            item.BrandName = CuurentItem.BrandName
            // item.ExportLocation = item.WarehouseCode + (item.SectionLevel1 !== undefined ? (' > ' + item.SectionLevel1.Code) : '') + (item.SectionLevel2 !== undefined ? (' > ' + item.SectionLevel2.Code) : '') + ' > ' + item.SectionCode
            item.ExportLocation = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
            // item.ExportLocation = item.SectionLevel1,
          }
          return item
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.AvailableItems = response
        console.log('AvailableItems---------------')
        console.log(this.AvailableItems)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransferItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      this.Get('Inventory_TransferItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.TransferItems = response.map((value :any) => {
          if (!value.IsPack) {
            return {
              ...value,
              PackSize: 1
            }
          } else {
            return value
          }
        })
        console.log('response')
        console.log(response)
        this.ReadItems()
        if (this.DialogData?.Type === 'InternalTransfer') {
          this.ReadSections()
          this.GetLocations()
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Confirm () {
      this.Saving = true
      this.Post('POST', 'Inventory_Transfers_UPDATE', {}, '?ID=' + this.DialogData?.Data.ID + '&Stat=ImportCheck').then(() => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in updating Transfer'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSections () {
      this.Loading.push('Loading')
      this.Get('Inventory_Sections').then(response => {
        this.Sections = response
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sections'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT (id: string) {
      if ((this.DialogData?.Data.Type === 'Export' || this.DialogData?.Data.Type === 'BranchesTransfer') && this.DialogData?.Data.Status === 'New') {
        this.Post('POST', 'Inventory_Transfers_UPDATE', {}, '?ID=' + this.DialogData?.Data.ID + '&Stat=Exporting').then(() => {
          this.$router.go(-1)
        }, error => {
          error.CookiesError = 'Error in updating Transfer'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      var bodd = document.getElementById(id)
      if (!bodd) return
      if (this.$store.state.PrintingOptions && id === 'TableToPrint1') this.$store.state.PrintingOptions.FontSize = '0.95em'
      if (this.$store.state.PrintingOptions && id === 'TableToPrint2') this.$store.state.PrintingOptions.FontSize = '0.9em'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.PageSize = 'A4 portrait'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.Padding = '0em'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.MaxWidth = '100%'
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    GetLocations () {
      // if (!this.Transfertem || !this.MEMRead('D_Transfer_WarehouseFrom')) return
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation').then(response => {
        this.ItemsLocation = response
        // this.ReadItems()
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.ReadAvailableItems()
    this.ReadTransferItems()
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "532db4ea": (_ctx.Theme.BG1),
  "532db4e8": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__