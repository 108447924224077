
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Width: { default: '', type: String },
    Height: { default: '', type: String },
    Background: { default: '', type: String },
    Align: { default: 'Center', type: String },
    Options: { default: () => { return [] }, type: Array as PropType<Array<any>> },
    Selector: { default: 'Name' },
    Type: { default: 'String', type: String },
    Res: { default: 'String', type: String },
    Radio: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Icons: { default: [] },
    CookiesIcons: { default: [] },
    Name: { default: '' },
    HoverDark: { default: false },
    MaxHeight: { default: '16em', type: String },
    TrueValue: { default: [] },
    IndexSelect: { default: 0, type: Number }
  },
  data () {
    return {
      Opened: false,
      SelectedImage: '',
      SelectedIcon: '',
      CurrentIndex: 0
    }
  },
  mounted () {
    var selectedIndex = 0
    if (this.$store.state.CookiesMemory[this.Res]) {
      if (this.Type === 'String') {
        var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
        if (Found > -1) {
          selectedIndex = Found
        }
      } else {
        var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
        if (Found2 > -1) {
          selectedIndex = Found2
        }
      }
    }
    this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
    this.SelectedImage = this.ALLIcons[selectedIndex]
    this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
  },
  methods: {
    OptionSelected (option: any, Index: number, event: any) {
      var selected = option
      this.CurrentIndex = Index
      if (this.TrueValue.length > 0) {
        selected = this.TrueValue[Index]
      }
      this.$store.state.CookiesMemory[this.Res] = selected
      this.SelectedImage = this.ALLIcons[Index]
      this.SelectedIcon = this.ALLCookiesIcons[Index]
      this.$emit('slection_changed', selected)
      this.$emit('selected', selected)
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      } else if (this.Type === 'Object') {
        // var newObj = [{}].concat(this.Options)
        var newObj = {}
        var NewArray = []
        this.DeepChanger(newObj, this.SelectorFix, this.LanguageSelector('All', 'الكل'))
        NewArray.push(newObj)
        NewArray = NewArray.concat(this.Options)
        // newObj[0][this.Selector] = 'All'
        return NewArray
      }
      return [this.LanguageSelector('All', 'الكل')].concat(this.Options)
    },
    ALLIcons () {
      if (this.Radio) {
        return this.Icons
      }
      return [''].concat(this.Icons)
    },
    ALLCookiesIcons () :Array<IconsIDs> {
      if (this.Radio) {
        return this.CookiesIcons
      }
      return [''].concat(this.CookiesIcons) as Array<IconsIDs>
    },
    StyledOut () {
      var BGG
      if (this.Background !== '') {
        BGG = this.Background
      } else {
        BGG = this.Theme.BG6
      }
      return {
        width: this.Width,
        height: this.Height,
        background: BGG
      }
    },
    Selected () {
      var x = {}
      if (this.TrueValue.length > 0) {
        x = this.Options[this.CurrentIndex]
      } else {
        x = this.$store.state.CookiesMemory[this.Res]
      }
      return x
    },
    TextAlign () {
      if (this.Align === 'Left') {
        return 'text-align: left; justify-content: left;'
      }
      if (this.Align === 'Right') {
        return 'text-align: right; justify-content: right;'
      }
      if (this.Align === 'Center') {
        return 'text-align: center; justify-content: center;'
      } else {
        return ''
      }
    },
    SelectedName () {
      if (this.Type === 'Object') {
        try {
          return this.DeepFetcher(this.Selected, this.SelectorFix)
        } catch (error) {
          return ''
        }
      }
      return this.Selected
    },
    SelectorFix () {
      if (typeof this.Selector === 'string') {
        return [this.Selector]
      }
      return this.Selector
    }
  },
  watch: {
    Selected: function (newVal, oldVal) { // watch it
      this.SelectedImage = this.ALLIcons[this.ALLOptions.indexOf(newVal)]
      this.SelectedIcon = this.ALLCookiesIcons[this.ALLOptions.indexOf(newVal)]
      if (this.Type === 'Object') {
        try {
          var Path = this.PathFixer(this.Selector)
          // if (typeof this.Selector === 'string') {
          //   Path = [this.Selector]
          // }
          this.SelectedIcon = this.ALLCookiesIcons[this.IndexFinder(this.ALLOptions, Path, newVal[this.Selector])]
        } catch (error) {}
      }
    },
    Options: function (newVal, oldVal) { // watch it
      try {
        if (this.Selected === undefined || !(this.Options.indexOf(this.Selected))) {
          var selectedIndex = 0
          if (this.$store.state.CookiesMemory[this.Res]) {
            if (this.Type === 'String') {
              var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
              if (Found > -1) {
                selectedIndex = Found
              }
            } else {
              var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
              if (Found2 > -1) {
                selectedIndex = Found2
              }
            }
          }
          this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
          this.SelectedImage = this.ALLIcons[selectedIndex]
          this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
          this.$emit('slection_changed', this.$store.state.CookiesMemory[this.Res])
          // this.$emit('selected', this.$store.state.CookiesMemory[this.Res])
        }
      } catch (error) {
        console.log(error)
      }
    },
    IndexSelect: function (newVal, oldVal) { // watch it
      this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[this.IndexSelect]
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3023e57c": (_ctx.Theme.BackBlur),
  "5150c563": (_ctx.Theme.color2),
  "39ca7497": (_ctx.Theme.BG6Fixed),
  "f6b9f64e": (_ctx.Theme.BG2),
  "f6b9f64c": (_ctx.Theme.BG3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__