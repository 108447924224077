import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fffde17a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "F1_5em D-Flex Rad3 BG5 Shadow3 BGBlur",
  style: {"position":"absolute","top":"1em","z-index":"60000"}
}
const _hoisted_3 = { class: "M-Auto ButtonsDom" }
const _hoisted_4 = {
  class: "V-Auto F-Bold",
  style: {"padding":"0 1em"}
}
const _hoisted_5 = {
  class: "M-Auto",
  style: {"overflow":"hidden"}
}
const _hoisted_6 = {
  class: "DialogBody F1_5em D-Flex",
  style: {"overflow":"hidden","justify-content":"center","align-items":"center"},
  id: "printingBadge"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'CookiesDialog_' + _ctx.DialogIndex,
    class: "CookiesDialogdBody",
    style: {"overflow":"hidden"}
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CookiesIcon, {
          Tooltip: _ctx.Translate('Select'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.HandleSelection())),
          class: _normalizeClass([_ctx.CurrentImage.Selected ? '' : 'O-25 Shadow1', "SQR1_5em Pointer"]),
          ID: ('Done' as IconsIDs)
        }, null, 8, ["Tooltip", "class"]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.CurrentImage.Name), 1),
        _createVNode(_component_CookiesIcon, {
          Tooltip: _ctx.Translate('Expand'),
          class: "SQR1_5em Pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenImage(_ctx.CurrentImage.ImgUrl))),
          ID: ('Expand' as IconsIDs)
        }, null, 8, ["Tooltip"]),
        _createVNode(_component_CookiesIcon, {
          Tooltip: _ctx.Translate('Close'),
          class: "SQR1_5em Pointer",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.go(-1))),
          ID: ('Close' as IconsIDs)
        }, null, 8, ["Tooltip"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.TouchStart($event))),
          onTouchend: _cache[5] || (_cache[5] = ($event: any) => (_ctx.TouchEnd($event))),
          class: "ImageHolder"
        }, [
          _createElementVNode("div", {
            class: "DelayFade",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.go(-1))),
            style: {"width":"100vw","height":"100vh","background":"rgba(0,0,0,0.5)","position":"absolute","inset":"0"}
          }),
          _createElementVNode("img", {
            ref: "ImageRef",
            class: "ImageDom Transition0_25",
            src: _ctx.CurrentImage.ImgUrl,
            alt: ""
          }, null, 8, _hoisted_7)
        ], 32),
        _createElementVNode("div", {
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ChangeImage(1))),
          class: "D-Flex Rad3 BG2 Shadow3 BGBlur P1_25em Pointer HideOnMobile",
          style: {"position":"absolute","right":"4%","z-index":"60000"}
        }, [
          _createVNode(_component_CookiesIcon, {
            Tooltip: _ctx.Translate('Next'),
            class: "SQR1_5em",
            style: {"transform":"rotate(-90deg)"},
            ID: ('SortingArrow' as IconsIDs)
          }, null, 8, ["Tooltip"])
        ]),
        _createElementVNode("div", {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ChangeImage(-1))),
          class: "D-Flex Rad3 BG2 Shadow3 BGBlur P1_25em Pointer HideOnMobile",
          style: {"position":"absolute","left":"4%","z-index":"60000"}
        }, [
          _createVNode(_component_CookiesIcon, {
            Tooltip: _ctx.Translate('Next'),
            class: "SQR1_5em",
            style: {"transform":"rotate(90deg)"},
            ID: ('SortingArrow' as IconsIDs)
          }, null, 8, ["Tooltip"])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}