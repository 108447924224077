
const routes :Array<CookiesRouter> = [
  {
    path: '/HR',
    name: 'HR',
    redirect: '/HR/Employees',
    component: () => import('@/Apps/HR/views/HR.vue'),
    meta: {
      Access: 'Main_Public'
    },
    children: [
      {
        path: '/HR/Employees',
        name: 'Employees',
        component: () => import('@/Apps/HR/views/Employees.vue'),
        meta: {
          Access: 'Main_Public'
        }
      }
    ]
  }
]

export const HRRoutes = routes
