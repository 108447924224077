const routes :Array<CookiesRouter> = [
  {
    path: '/Finance',
    name: 'Finance',
    redirect: '/Finance/Accounts',
    component: () => import('@/Apps/Finance/views/Finance.vue'),
    meta: {
      Access: 'Main_Public'
    },
    children: [
      {
        path: '/Finance/Accounts',
        name: 'Accounts',
        component: () => import('@/Apps/Finance/views/Accounts.vue'),
        meta: {
          Access: 'Main_Public'
        }
      },
      {
        path: '/Finance/Invoices',
        name: 'Invoices',
        component: () => import('@/Apps/Finance/views/Invoices.vue'),
        meta: {
          Access: 'Main_Public'
        }
      },
      {
        path: '/Finance/Transactions',
        name: 'Transactions',
        component: () => import('@/Apps/Finance/views/Transactions.vue'),
        meta: {
          Access: 'Main_Public'
        }
      }
    ]
  }
]

export const FinanceRoutes = routes
