
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ToolTipToRemove: ''
    }
  },
  computed: {
    TooltipElement () {
      if (this.$store.state.ToolTipElement && this.$store.state.ToolTipElement.getAttribute('ToolTip') === '') {
        return null
      }
      return this.$store.state.ToolTipElement
    },
    ToolTipStyler () {
      var ElementBox = this.TooltipElement?.getBoundingClientRect()
      var ViewportBox = window.visualViewport
      var CalculatedRight = ElementBox?.right && ViewportBox?.width && ViewportBox?.width - ElementBox?.right
      this.Console(CalculatedRight)
      this.Console(ElementBox?.left)
      var bottom = 0
      var top = 0
      var left = (ElementBox?.left ? ElementBox?.left : 0) + (ElementBox?.width ? ElementBox?.width / 2 : 0) + 'px'
      // var Innerleft = 'max( ' + -1 * (ElementBox?.left ? ElementBox?.left : 0) + 'px, -50%)'
      var Innerleft = 'calc(max( calc(20% - ' + (ElementBox?.left ? ElementBox?.left : 0) + 'px), 0px) + -50%)'
      var Innerright = 'calc(max( calc(20% - ' + CalculatedRight + 'px), 0px) + 50%)'
      if (ElementBox?.top && ElementBox?.top < 100) {
        if (ElementBox?.top) {
          top = ElementBox?.height + ElementBox?.top
        }
        return {
          Outer: ['top:' + 'calc(' + top + 'px' + ' + 0.5em)', 'left: ' + left],
          Inner: [((ElementBox?.x && ViewportBox?.width && (ElementBox?.x < ViewportBox?.width / 2))) ? ('left: ' + Innerleft) : ('right: ' + Innerright)],
          Indicator: ['transform: scaleY(-1)', 'order: 1', 'top: -0.5em']
        }
      } else {
        if (ElementBox?.top) {
          var body = document.body
          var html = document.documentElement
          var Allheight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
          bottom = Allheight - ElementBox?.top
        }
        return {
          Outer: ['bottom:' + 'calc(' + bottom + 'px' + ' + 0.5em)', 'left: ' + left],
          Inner: [((ElementBox?.x && ViewportBox?.width && (ElementBox?.x < ViewportBox?.width / 2))) ? ('left: ' + Innerleft) : ('right: ' + Innerright)],
          Indicator: ['transform: scaleY(1)', 'order: 3']
        }
      }
    }
  },
  methods: {
    RemoveToolTip (x:string) {
      if (this.ToolTipToRemove === x) {
        this.$store.state.ToolTipElement = null
      }
    }
  },
  watch: {
    TooltipElement: function (newVal, oldVal) { // watch it
      const UserAgent = navigator.userAgent
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(UserAgent) || /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(UserAgent)) {
        if (newVal !== null) {
          var x = Date.now().toString()
          this.ToolTipToRemove = x
          setTimeout(() => {
            this.RemoveToolTip(x)
          }, 2000)
        }
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2eb3e7a2": (_ctx.Theme.BG5Fixed),
  "9e44514e": (_ctx.Theme.color1)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__