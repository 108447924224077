
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    Message: { default: '', type: String as PropType<string> }
  },
  data () {
    return {}
  },
  computed: {},
  // ---------------------------------------------------
  methods: {},
  // ---------------------------------------------------
  beforeCreate () {},
  created () {
  },
  beforeMount () {},
  mounted () {
    setTimeout(() => {
      this.$store.state.CookiesNotifications.Messages.shift()
    }, 4000)
  },
  beforeUnmount () {}
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7584e484": (_ctx.Theme.BackBlur),
  "824dde84": (_ctx.Theme.BG1Fixed),
  "3c4ef5b4": (_ctx.Theme.color3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__