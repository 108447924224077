
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Name: { default: '', Type: String },
    SearchRes: { default: undefined, Type: String },
    Value: { default: ['Date'], Type: Array },
    sortColumn: { default: { Path: ['Date'], Direction: -1 }, Type: Object },
    Sortable: { default: true, Type: Boolean }
  },
  data () {
    return {
      SearchOpened: false
    }
  },
  methods: {
    OpenSearch (event :Event) {
      var target :any = this.SearchRes
      event.preventDefault()
      event.stopPropagation()
      this.SearchOpened = !this.SearchOpened
      setTimeout(() => {
        if (target) this.$refs[target].focus()
      }, 100)
    },
    CloseSearch (event :Event) {
      event.preventDefault()
      event.stopPropagation()
      this.SearchOpened = false
    },
    ClearSearch (event :Event) {
      var target :any = this.SearchRes
      event.preventDefault()
      event.stopPropagation()
      if (target) this.$store.state.CookiesMemory[target] = ''
      this.SearchOpened = false
    },
    PreventOthers (event :Event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
})
