
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Loading: { default: () => { return [] }, type: Array as PropType<Array<string>> },
    CookiesData: { default: [] },
    TableSettings: { default: () => {}, type: Object as PropType<FunctionalTable> }
  },
  data () {
    return {
      SortElement: {} as SortElement,
      CurrentPage: 1,
      DataPerPage: 100,
      DefaultSortElement: {
        Direction: -1,
        Path: ['Date']
      } as SortElement
    }
  },
  mounted () {
    if (this.TableSettings.DefaultSort !== undefined) {
      this.SortElement = this.TableSettings.DefaultSort
    } else {
      this.SortElement = this.DefaultSortElement
    }
  },
  computed: {
    SortedData () {
      return this.GeneralSort(this.FilteredData, this.SortElement.Path, this.SortElement.Direction)
    },
    TableHeight () {
      if (this.$el && this.$el.scrollHeight) {
        return this.$el.scrollHeight
      } else {
        return 0
      }
    },
    TableScrollTop () {
      if (this.$el && this.$el.scrollTop) {
        return this.$el.scrollTop
      } else {
        return 0
      }
    },
    PageData () {
      var PData = this.SortedData.slice(((this.CurrentPage - 1) * this.DataPerPage), (this.CurrentPage * this.DataPerPage))
      return PData
    },
    FilteredData () {
      var Filtered = this.CookiesData
      this.TableSettings.Fields.forEach(field => {
        if (field.IsSearchable && this.MEMRead('TableFieldSearch_' + field.Name) && this.MEMRead('TableFieldSearch_' + field.Name) !== '') {
          Filtered = Filtered.filter(element => {
            return this.CookiesSearcher(element, this.MEMRead('TableFieldSearch_' + field.Name), { Exclusive: true, CustomAttribute: field.Path })
          })
        }
      })
      return Filtered
    }
  },
  methods: {
    DynamicCalculator (item :any, Field :any, DynamicObject :'DynamicVisiblity' | 'DynamicClass') {
      if (Field[DynamicObject] === undefined) return true
      var Result = false
      const DV = Field[DynamicObject]
      const itemValue = this.DeepFetcher(item, DV.ValuePath)
      if (!itemValue) return Result
      switch (DV.Operator) {
        case 'EQUAL LOWERCASE':
          if (itemValue === DV.FixedValue || itemValue.toLowerCase() === DV.FixedValue.toLowerCase()) Result = true
          break
        case 'NOT EQUAL LOWERCASE':
          if (itemValue !== DV.FixedValue && itemValue.toLowerCase() !== DV.FixedValue.toLowerCase()) Result = true
          break
        case 'EQUAL':
          if (itemValue === DV.FixedValue) Result = true
          break
        case 'NOT EQUAL':
          if (itemValue !== DV.FixedValue) Result = true
          break
        case 'LARGER':
          if (itemValue > DV.FixedValue) Result = true
          break
        case 'SMALLER':
          if (itemValue < DV.FixedValue) Result = true
          break
      }
      return Result
    },
    BarValues (item :any, Field :FunctionalTableField) {
      if (!Field.BarValues) return []
      return Field.BarValues.map(element => {
        return {
          ...element,
          Value: this.DeepFetcher(item, element.ValuePath)
        }
      })
    },
    ChangePage (PageNumber :number) {
      var Rows :any = document.getElementsByClassName('CookiesGeneralTableRow')
      var Table = this.$refs.FunctinoalTable
      if (Table) Table.scrollTop = 0
      try {
        if (Rows.length > 0) {
          Rows.forEach((row :any) => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            this.CurrentPage = PageNumber
            Rows.forEach((row :any) => {
              row.style.opacity = 1
            })
          }, 250)
        } else {
          this.CurrentPage = PageNumber
        }
      } catch (error) {
        this.CurrentPage = PageNumber
      }
    },
    MultiFieldsCap (item:any, Field :FunctionalTableField) {
      var AllFields = []
      if (Field.Path) AllFields = this.DeepFetcher(item, Field.Path).slice(0, 5)
      return AllFields
    }
  },
  watch: {
    SortedData: function (newVal, oldVal) { // watch it
      if (this.CurrentPage > Math.ceil(this.SortedData.length / this.DataPerPage)) this.CurrentPage = 1
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7d64c5b9": (_ctx.Theme.BG6),
  "6e3962cc": (_ctx.Theme.UserColors.third)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__