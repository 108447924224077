
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      TransferItems: [] as Array<any>,
      ExportItems: [],
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true,
            Sort: 'Code'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName',
            Sort: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: 'BrandName',
            Sort: 'BrandName'
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName',
            Sort: 'CategoryName'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Sort: 'Quantity'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Sort: 'PackSize'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Sort: 'Details'
          },
          {
            Name: 'Expected Locations',
            Type: 'array-normal',
            Value: 'ImportLocations',
            MultiValue: 'LocationFullPath',
            Bold: true,
            Visible: (this.DialogData && (this.DialogData.Data.Type === 'Import' || this.DialogData.Data.Type === 'ExternalImport'))
          },
          {
            Name: 'Location Qty',
            Type: 'array-normal',
            Value: 'ImportLocations',
            MultiValue: 'AddedQuantity',
            Visible: (this.DialogData && (this.DialogData.Data.Type === 'Import' || this.DialogData.Data.Type === 'ExternalImport'))
          },
          {
            Type: 'selector',
            Name: '',
            Res_ID: 'OriginalIndex'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          return element.LocationFullPath.indexOf('Not Enough Space') > -1
        })
        if (Found) {
          NoSpace = true
        }
      })
      var NotCHecked = true
      try {
        if (this.MEMRead('D_TransferCheck_Table').length === (this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`] === 'true' ? this.CombinedItems.length : this.TransferItems.length)) NotCHecked = false
      } catch (error) {
      }
      console.log(NoSpace)
      console.log(NotCHecked)
      return (NoSpace || NotCHecked)
    },
    TranslatedImportLocations () {
      var _this = this
      var Locations = this.DeepCloner({ ...this.ImportLocations })
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath.indexOf('Not Enough Space') > -1
        })
        if (Found) {
          console.log('----------------------Found')
          Found.LocationFullPath = '<span style="color: red" class="F-Bold">' + _this.Translate('Not Enough Space') + '</span>'
        }
      })
      return Locations
    },
    CombinedItems () {
      if (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer' && !(this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`] === 'true')) {
        return this.TransferItems
      }
      var Combined :Array<any> = []
      if (!this.TransferItems) {
        return Combined
      }
      this.TransferItems.forEach(element => {
        var Found = Combined.find((added:any) => {
          console.log(element)
          return added.ItemID === element.ItemID
        })
        if (Found) {
          Found.Quantity += element.IsPack ? element.Quantity * element.PackSize : element.Quantity
        } else {
          // var Name = element.Name ? element.Name : this.ObjectFinder()
          Combined.push({ ...element, Quantity: element.IsPack ? element.Quantity * element.PackSize : element.Quantity, PackSize: 1 })
        }
      })
      return Combined
    }
  },
  // ---------------------------------------------------
  methods: {
    ReadItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      var ItemsIds = this.DialogData.Data.Items.map((item :any) => {
        return item.ItemID
      })
      if (!ItemsIds || ItemsIds.length === 0) {
        this.Loading.shift()
        return
      }
      this.Get('Inventory_Items', '?ID=[' + ItemsIds + ']' + '&ListAllWarehouses=true').then(response => {
        var ComputedItems = []
        if (this.DialogData && (this.DialogData.Data.Type === 'Import' || this.DialogData.Data.Type === 'ExternalImport')) ComputedItems = this.DialogData?.Data.Items
        else ComputedItems = this.ExportItems
        if (!Array.isArray(response)) {
          response = [response]
        }
        this.TransferItems = ComputedItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            console.log(CuurentItem)
            item.Specifications = CuurentItem.Specifications
            item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            item.CategoryName = CuurentItem.CategoryName
            item.BrandName = CuurentItem.BrandName
            if (!item.IsPack) item.PackSize = 1
            if (this.DialogData && (this.DialogData.Data.Type === 'Import' || this.DialogData.Data.Type === 'ExternalImport')) item.ImportLocations = this.TranslatedImportLocations['Item_' + item.ItemID + '_' + item.ID]
          }
          return item
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    GetLocations () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      this.Get('Inventory_Transfers_ImportLocations', '?ID=' + this.DialogData?.Data.ID).then(response => {
        this.ImportLocations = response
        this.ReadItems()
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransferItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      this.Get('Inventory_TransferItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.ExportItems = response
        this.ReadItems()
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Confirm () {
      if (this.Saving) return
      this.Saving = true
      if ((this.DialogData?.Data.Type === 'Import' || this.DialogData?.Data.Type === 'ExternalImport')) {
        this.Post('POST', 'Inventory_Transfers_UPDATE', { VehicleNumber: this.MEMRead('ExportVehicleNumber'), DriverName: this.MEMRead('ExportVehicleDriver'), EmployeeName: this.MEMRead('ExportEmployee') }, '?ID=' + this.DialogData?.Data.ID + '&Stat=ImportCheck').then(() => {
          this.Saving = false
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in updating Transfer'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      if (this.DialogData?.Data.Type === 'Export' || this.DialogData?.Data.Type === 'BranchesTransfer') {
        this.Post('POST', 'Inventory_Transfers_UPDATE', { VehicleNumber: this.MEMRead('ExportVehicleNumber'), DriverName: this.MEMRead('ExportVehicleDriver'), EmployeeName: this.MEMRead('ExportEmployee'), TransferData: this.DialogData.Data }, '?ID=' + this.DialogData?.Data.ID + '&Stat=Exported').then(() => {
          this.Saving = false
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in updating Transfer'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    if (this.DialogData && (this.DialogData.Data.Type === 'Import' || this.DialogData.Data.Type === 'ExternalImport')) {
      this.GetLocations()
    } else {
      this.ReadTransferItems()
    }
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
