<template>
<div v-if="Access('AreasAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Area -------------------------------------- -->
      <div class="CookiesRow">
        <input v-model="Name" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" :placeholder="LanguageSelector('Area Name', 'اسم المنطقة')" autocomplete="off">
        <div class="CookiesRowSpacer"></div>
        <div class="D-Flex M-Auto" style="min-width: 11em; padding: 0.6em;">
          <CookiesNumberInput :Name="LanguageSelector('Fees', 'الرسوم')" :Step="0.25" :Unit="$store.state.CookiesConfig.PriceUnit" style="margin-right: 0.6em;" :MinWidth="'2em'" :PlaceHolder="LanguageSelector('Fees', 'الرسوم')" :Default="3" :Res="'NewAreaFees'"></CookiesNumberInput>
        </div>
        <div class="D-Flex M-Auto" style="min-width: 11em; padding: 0.6em;">
          <CookiesNumberInput :Name="LanguageSelector('Driver Fees', 'رسوم السائق')" :Step="0.25" :Unit="$store.state.CookiesConfig.PriceUnit" style="margin-right: 0.4em;" :MinWidth="'2em'" :PlaceHolder="LanguageSelector('Driver Fees', 'رسوم السائق')" :Default="0" :Res="'NewAreaDriverExtraFees'"></CookiesNumberInput>
        </div>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('AreasAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
    <button @click="SaveArea()" :style="'background: ' + Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Save')}}</button>
  </div>
</div>

</template>

<script>
export default {
  data () {
    return {
      Name: '',
      ErrorsVisibilty: false,
      CookiesDataLines: [],
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadAreas () {
      this.Get('Area/getLine').then(response => {
        this.CookiesDataLines = response
      }, error => {
        error.CookiesError = 'Error in reading Areas'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveArea () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      this.Post('POST', 'Area', this.NewAreaData).then(response => {
        this.Saving = false
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Adding New Area'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    if (!this.Access('AreasAdd')) {
      return
    }
    this.ReadAreas()
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    NewAreaData () {
      return {
        Name: this.Name,
        Fees: this.$store.state.CookiesMemory.NewAreaFees,
        DriverExtraFees: this.$store.state.CookiesMemory.NewAreaDriverExtraFees
      }
    },
    DialogError () {
      var DialogErrors = []
      this.Name === '' && DialogErrors.push('Please Enter Area\'s Name')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_NewArea_Active
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 600px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
</style>
