const routes :Array<CookiesRouter> = [
  {
    path: '/DashBoard',
    name: 'DashBoard',
    meta: {
      Access: 'DashBoard_View' as AccessFeature,
      ReportsDateSelector: true
    },
    component: () => import('@/Apps/DashBoard/views/Dash.vue')
  }
  // ==============================================================================
  // ====================
]

export const DashBoardRoutes = routes
