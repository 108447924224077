
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: [] as Array<Category>,
      ErrorsVisibilty: false
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Categories') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_Categories_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Type: 'Image',
            Value: 'ImgUrl',
            Name: 'Image',
            Style: 'width: 3em;',
            Function: 'ImageChanged',
            Class: 'Shadow2 Pointer',
            ToolTip: this.Translate('Change Image')
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Description',
            Editable: true,
            Type: 'normal',
            Value: 'Description',
            Sort: 'Description',
            Res_ID: 'ID',
            Editable_Multiline: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: -1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      if (!this.DialogData.Type) {
        Table.Fields.push({
          Name: 'Type',
          Type: 'normal',
          Value: 'Type',
          Sort: 'Type',
          Res_ID: 'ID'
        })
      }
      Table.Fields.push({
        Name: 'Delete',
        Value: '',
        Type: 'delete',
        NoHeader: true
      })
      return Table
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadCategories()
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', (this.DialogData.Type ? '?Type=' + this.DialogData.Type : '')).then(response => {
        this.CookiesData = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewCategory () {
      this.Post('POST', 'Categories', {
        Type: this.DialogData.Type ? this.DialogData.Type : 'Default',
        Name: this.MEMRead('D_MainCategories_Name'),
        Description: this.MEMRead('D_MainCategories_Notes'),
        ImgUrl: ''
      }).then(() => {
        this.MEMWrite('D_MainCategories_Name', '')
        this.MEMWrite('D_MainCategories_Notes', '')
        this.ReadDialogData()
      })
    },
    FieldEdited (item :any) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :Category) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      this.Post('PATCH', 'Categories', Patch, '?ID=' + item.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.ReadCategories()
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    RemoveItem (Item :CatalogItem, index:number) {
      if (!this.Access('Main_Categories_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Categories', AddQuery: '?ID=' + Item.ID, Text: 'Are you sure you want to delete <strong>' + Item.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: Item } as GeneralAcceptDialogData, {})
    },
    AfterRemove (Item :Category) {
      this.DeleteImage(Item.ImgUrl as any)
      this.ReadDialogData()
    },
    CustomSorting (NewSort :any) {
      this.MEMWrite('D_Category_Items', NewSort.SortedData)
    },
    ImageChanged (Item :CatalogItem) {
      this.Saved = undefined
      var input = document.createElement('input')
      input.type = 'file'
      input.click()
      input.onchange = (e :any) => {
        this.Saving.push('Saving')
        var file = e.target.files[0]
        if (file) {
          this.PostImage(file).then((response :any) => {
            Item.ImgUrl = response
            this.FieldEdited(Item)
            this.Saving.shift()
          }, error => {
            this.Saving.shift()
            error.CookiesError = 'Error in uploading image'
            this.OpenDialog('Error_Dialog', 'Error', error)
          })
        } else {
          this.Loading.shift()
        }
      }
    }
  },
  mounted () {
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Category')
  }
})
