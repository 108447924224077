
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'System Config',
  data () {
    return {
      LanguageIndex: -1,
      selectedFile: null,
      OriginalConfig: {} as any,
      LanguagesArray: [] as Array<any>,
      Saving: false
    }
  },
  mounted () {
    const _this = this
    Object.keys(this.Languages).some(function (Lang) {
      _this.LanguagesArray.push({
        Name: _this.Languages[Lang].Name,
        Code: Lang
      })
    })
    this.LanguageIndex = this.IndexFinder(this.LanguagesArray, ['Code'], this.$store.state.CookiesConfig.Language)
    this.MEMWrite('SystemBackground', this.$store.state.CookiesConfig.background)
    this.MEMWrite('Config_LanguageUserSelect', this.$store.state.CookiesConfig.LanguageUserSelect)
    this.ReadConfig()
  },
  computed: {},
  methods: {
    ReadConfigData () {
      this.Get('Config').then(response => {
        if (response === null) {
          return
        }
        response.forEach((element :any) => {
          this.$store.state.CookiesConfig[element.Name] = element.Value
          this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
          this.OriginalConfig[element.Name] = element.Value
          this.OriginalConfig[element.Name + 'ID'] = element.ID
          if (element.Name === 'CategoriesImages') {
            this.$store.state.CookiesConfig[element.Name] = JSON.parse(element.Value)
            this.OriginalConfig[element.Name] = JSON.parse(element.Value)
          }
        })
        this.LocalSave('CookiesDefaultConfig', this.$store.state.CookiesConfig)
        var LocalLang = this.LocalRead('Language')
        if (LocalLang) {
          this.$store.state.CookiesConfig.Language = LocalLang
          this.OriginalConfig.Language = LocalLang
        }
        this.Console(this.OriginalConfig)
      }, error => {
        error.CookiesError = 'Error in reading Cookies Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ChooseTheme (theme : string) {
      this.$store.state.CookiesConfig.Theme = theme
      this.SaveConfig('Theme', theme)
    },
    LanguageChanged (value :any) {
      this.LocalSave('Language', value.Code)
      this.$store.state.CookiesConfig.Language = value.Code
      this.SaveConfig('Language', value.Code)
    },
    LanguageUserSelectChanged () {
      this.$store.state.CookiesConfig.LanguageUserSelect = this.MEMRead('Config_LanguageUserSelect')
      this.SaveConfig('LanguageUserSelect', this.MEMRead('Config_LanguageUserSelect'))
    }
    // LogoChanged () {
    //   var file = event.target.files[0]
    //   this.selectedFile = file
    //   if (!file) {
    //     return
    //   }
    //   this.EditConfig('Logo', file)
    // }
  }
})
