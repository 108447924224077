
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ShowFilter: false,
      Search: ''
    }
  },
  computed: {
    Filters () :any {
      var EmployeesFilter :FilterObject = {
        Lists: [
          {
            Name: 'ssss',
            Visible: true,
            Options: [{ name: 'khaled' }, { name: 'mohammed' }],
            Selectors: ['name'],
            Res: 'Manufacturersc',
            DataPath: ['Notes'],
            ListPath: ['name']
          }
        ],
        Switches: [
          {
            Name: this.Translate('Manufacturer = 2'),
            Visible: true,
            Icon: 'Factory',
            Res: 'FinanceFilter_Manufacturers2',
            Value: 2,
            DataPath: ['ManufacturerID']
          }
        ]
      }
      return {
        Employeess: EmployeesFilter
      }
    },
    Tabs () {
      return [
        { Name: this.Translate('Employees'), Link: 'Employees' },
        { Name: this.Translate('Attendance'), Link: 'Employeesa' }
      ]
    }
  },
  methods: {
    NewEmployee () {
      if (!this.Access('HR_Employees_Create')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', this.Translate('New Employee'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  }
})
