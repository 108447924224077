<template>
    <div class="CookiesCard Shadow0">
      <div class="CookiesCardInner F1_25em BG7">
        <div class="D-Flex" style="width: 100%;">
          <span style="padding: 0.3em;" class="M-Auto">#{{DeepFetcher(Product, ['ID']) ? DeepFetcher(Product, ['ID']) : 'N/A'}}</span>
        </div>
        <div class="CardImage" :style="'background: url(' + DefaultImageHandler(Product.ImgUrl, DefaultImage) + ');'">
          <div class="" style="text-align: right; padding: 0.4em 0.2em;"><span class="BG2 Rad3" :class="DeepFetcher(DecodedUnits, ['Products', 'ID_' + Product.ID]) ? 'color2' : 'colorr1 F_75em'" style="padding: 0.2em 0.3em;">{{DeepFetcher(DecodedUnits, ['Products', 'ID_' + Product.ID]) ? DeepFetcher(DecodedUnits, ['Products', 'ID_' + Product.ID]) : Translate('Out of Stock')}}</span></div>
        </div>
        <div class="D-Flex F-Bold" style="width: 100%;">
          <span style="padding-top: 0.5em" class="M-Auto">{{DeepFetcher(Product, ['Name']) ? DeepFetcher(Product, ['Name']) : 'N/A'}}</span>
        </div>
        <div class="D-Flex colorThird" style="width: 100%;">
          <span class="M-Auto F-Bold">{{MinMaxCalculator(Product.Units, ['Price'])}} {{PriceUnit}}</span>
        </div>
        <div class="D-Flex" style="width: 100%;">
          <span style="font-size :0.85em;" class="M-Auto F-Bold">{{Translate('Cost')}}: {{MinMaxCalculator(Product.Units, ['Cost'])}} {{PriceUnit}}</span>
        </div>
      </div>
      <div class="CardButtons BG8">
        <div class="W-100 H-100"></div>
        <div class="W-100 EvenContainer" style="height: 2.5em; margin-top: -2.75em;">
          <div></div>
          <template v-for="Button in Buttons" :key="Button">
            <CookiesIcon :class="Button.Disabled && 'Disabled'" @click="$emit(Button.EventName, Product)" class="HoverUp" style="height: 100%; z-index: 50;" :ID="Button.Icon"></CookiesIcon>
          </template>
          <div></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    DecodedUnits: { default: {} },
    Product: { default: {} },
    Buttons: { default: [] }
  },
  methods: {}
}
</script>

<style scoped>
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Item */
.CookiesStockProduct{
  width: 14.8em;
  filter: drop-shadow(var(--shadow1));
  border-radius: var(--rad2);
  overflow: hidden;
  margin: 0.7em;
  height: fit-content;
  transition-property: transform;
  transition-duration: 0.2s;
  position: relative;
}
.CookiesStockProduct:hover{
  cursor: pointer;
  transform: scale(1.05) rotate(1deg);
  z-index: 5;
}
.CookiesProductImage{
  width: 100%;
  height: 11em;
  background-size: cover;
  background-position: center;
}
.CookiesProductIconsContainer{
  width: 18%;
  padding: 0%;
  margin: auto;
}
.CookiesIconsPriceContainer{
  padding: 3%;
  margin: auto;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CARD */
.CookiesCard{
  width: 15em;
  height: 21em;
  display: inline;
  /* border-radius: var(--rad2); */
  /* overflow: hidden; */
  transition-property: filter, transform;
  transition-duration: 0.2s;
  position: relative;
}
@media (max-width: 250px) {
  .CookiesCard{
    font-size: 5vw;
  }
  .TopSearchBar{
    font-size: 5vw;
  }
}
.CookiesCardInner{
  overflow: hidden;
  border-radius: var(--rad2);
  border: solid 0.01em v-bind('Theme.Shade1');
  height:100%;
  width: 100%;
  user-select: none;
}
.CardButtons{
  height: calc(100%);
  position: absolute;
  width: 100%;
  /* height: 4em; */
  bottom: 0;
  z-index: -55;
  border-radius: var(--rad1);
  transition-property: bottom, opacity, height;
  transition-duration: 0.2s;
  opacity: 0;
}
.CookiesCard:hover{
  transform: scale(1.05);
  z-index: 50;
  filter: drop-shadow(var(--shadow1));
}
.CookiesCard:hover .CardButtons{
  height: calc(100% + 3em);
  bottom: -3em;
  opacity: 1;
}
.CardImage{
  width: 100%;
  height: 8em;
  background-size: cover !important;
  background-position: center !important;
}
.CarPlate{
  background: linear-gradient(45deg, white, rgb(187, 187, 187));
  font-size: 1.2em;
  width: 37%;
  color: rgb(19, 19, 19);
  font-family: fantasy;
  /* font-weight: 900; */
  border-radius: 0.2em;
  margin-left: auto;
  margin-right: 0.5em;
  top: 0.5em;
  position: relative;
  filter: drop-shadow(var(--shadow1));
  /* opacity: 0.7; */
  border: rgb(77, 77, 77) 0.1em solid;
}
.BalanceNum{
  font-size: 1.2em;
  padding: 0.25em 0.5em;
  margin-top: 0.2em;
  background: v-bind('Theme.Light3');
  /* background: var(--shade1); */
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< CARD */
</style>
