
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    Prop: { type: Object as PropType<thing> },
    ForceEnabled: { default: false }
  },
  data () {
    return {}
  },
  computed: {},
  // ---------------------------------------------------
  methods: {},
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
