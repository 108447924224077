
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    Bar: { Default: () => { return { Min: 0, Max: 0, Values: [] } }, type: Object as PropType<CookiesBar> }
  },
  data () {
    return {}
  },
  computed: {
    Tooltip () {
      if (!this.Bar) return ''
      var tooltipString = ''
      var vals = this.Bar?.Values
      vals.forEach(element => {
        tooltipString += `${this.Translate(element.Name)}: <strong class="F1_25em">${this.DecimalFixer(element.Value)}</strong> <span class="F_75em">${this.Translate(element.Unit)}</span>
        <br>
        `
      })
      if (vals[0]) tooltipString += `${this.Translate('Max')}: <strong class="F1_25em">${this.Bar?.Max}</strong> <span class="F_75em">${this.Translate(vals[0].Unit)}</span>`
      return tooltipString
    }
  },
  // ---------------------------------------------------
  methods: {},
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {}
  // ---------------------------------------------------
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1c35b2a0": (_ctx.Theme.UserColors.fourth_25),
  "5a43f74c": (_ctx.Theme.UserColors.Dimmed),
  "1c35b2f8": (_ctx.Theme.UserColors.fourth_50)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__