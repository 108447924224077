
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Default: { default: '', type: String },
    CustomStyle: { default: '', type: String },
    type: { default: 'text', type: String },
    Res: { type: String },
    ErrorData: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Placeholder: { default: '', type: String },
    Name: { default: '', type: String },
    LimitLines: { default: 1000, type: Number }
  },
  data () {},
  methods: {
    HandleEnter (e :any) {
      var TextValue = e.target.value
      if (TextValue.split('\n').length >= this.LimitLines) {
        e.preventDefault()
      }
    }
  },
  mounted () {
    this.MEMWrite(this.Res, this.Default)
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "52d5db3f": (_ctx.Theme.color2),
  "f8d96894": (_ctx.Theme.UserColors.third_50),
  "52d5db40": (_ctx.Theme.color3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__