import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4527ce52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["CookiesButton", _ctx.Settings?.Class]),
    style: _normalizeStyle(_ctx.Settings?.Style)
  }, [
    (_ctx.Settings?.Icon)
      ? (_openBlock(), _createBlock(_component_CookiesIcon, {
          key: 0,
          class: "SQR1em",
          ID: _ctx.Settings.Icon,
          Color: _ctx.Settings.IconColor
        }, null, 8, ["ID", "Color"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 6))
}