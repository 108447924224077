
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    CookiesSettings: { type: Object as PropType<SliderComponent> }
  },
  data () {
    return {}
  },
  computed: {
    ValuePercentage () {
      console.log()
      return (this.MEMRead(this.SettingsHandler?.Res) && this.SettingsHandler?.Max && this.SettingsHandler?.Min) ? ((this.MEMRead(this.SettingsHandler?.Res) - this.SettingsHandler?.Min) / (this.SettingsHandler?.Max - this.SettingsHandler?.Min) * 100) + '%' : 0
    },
    SettingsHandler () {
      return {
        Res: '',
        Max: 100,
        Min: 0,
        PreserveMemory: true,
        ...this.CookiesSettings
      }
    }
  },
  // ---------------------------------------------------
  methods: {},
  // ---------------------------------------------------
  beforeCreate () {},
  created () {
    if (this.SettingsHandler && this.SettingsHandler.PreserveMemory && this.MEMRead(this.SettingsHandler.Res)) {
    } else if (this.SettingsHandler && this.SettingsHandler.Default) {
      this.MEMWrite(this.SettingsHandler.Res, this.SettingsHandler.Default)
    } else if (this.SettingsHandler) {
      this.MEMWrite(this.SettingsHandler.Res, this.SettingsHandler.Min)
    }
  },
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "65e1e5dc": (_ctx.Theme.InputColor),
  "ff777726": (_ctx.ValuePercentage),
  "2102666c": (_ctx.Theme.UserColors.fourth),
  "3fa95469": (_ctx.Theme.UserColors.main),
  "9431e692": (_ctx.Theme.UserColors.third)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__