import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecc22cc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "WhatsappBubble D-Flex Shadow3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (!_ctx.$store.state.UserManagment.Status && _ctx.$store.state.CookiesConfig.WhatsappContact)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CookiesIcon, {
          ToolTip: _ctx.Translate('Send a Message'),
          style: {"width":"4.5em","height":"4.5em"},
          class: "Pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.OpenWhatsapp(_ctx.$store.state.CookiesConfig.WhatsappContact))),
          ID: ('Whatsapp' as IconsIDs)
        }, null, 8, ["ToolTip"]),
        _createVNode(_component_CookiesIcon, {
          ToolTip: _ctx.Translate('Call'),
          style: {"width":"4.5em","height":"4.5em"},
          class: "Pointer",
          href: `tel:${_ctx.$store.state.CookiesConfig.WhatsappContact}`,
          ID: ('Phone' as IconsIDs)
        }, null, 8, ["ToolTip", "href"])
      ]))
    : _createCommentVNode("", true)
}