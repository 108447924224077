import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-003c97b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "D-Flex W-100 H-100" }
const _hoisted_2 = {
  key: 0,
  class: "LegendContainer P1_25em F_85em D-Flex FlexColumn",
  style: {"overflow-y":"scroll"}
}
const _hoisted_3 = ["onClick", "onPointerenter"]
const _hoisted_4 = {
  class: "P_5em D-Flex",
  style: {"gap":"0.5em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesSearchInput = _resolveComponent("CookiesSearchInput")!
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.Legend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_CookiesSearchInput, {
              Res: 'CookiesDonut_' + _ctx.ID + '_Search'
            }, null, 8, ["Res"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MyLegend, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.LegendClicked(index)),
              class: _normalizeClass([(_ctx.CurrentHover || _ctx.CurrentHover === 0) && _ctx.CurrentHover !== index ? 'O-25': '', "LegendMainDiv"]),
              key: index,
              onPointerenter: ($event: any) => (_ctx.onHover(item.index)),
              onPointerleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLeave()))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "Rad3",
                  style: _normalizeStyle([{"height":"1.2em","width":"1.5em","min-width":"1.5em"}, `background: ${item.fillStyle}`])
                }, null, 4),
                _createElementVNode("span", {
                  class: "F-Bold",
                  style: _normalizeStyle(`color: ${item.fillStyle};`)
                }, _toDisplayString(_ctx.DecimalFixer(_ctx.DeepFetcher(_ctx.SimilarityFixed[index], _ctx.DataPathCashed) / _ctx.DataTotal * 100, 1)) + "%", 5),
                _createElementVNode("span", null, _toDisplayString(_ctx.Labels[index]), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.Series && _ctx.Series[index] && _ctx.Series[index].color), 1)
              ])
            ], 42, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_DoughnutChart, {
      ref: "myChart",
      class: "V-Auto",
      onChartRender: _ctx.Generated,
      "onChart:destroy": _ctx.Generated,
      onChartUpdate: _ctx.Generated,
      style: {"height":"95%","flex":"2","aspect-ratio":"1","margin-right":"0.5em","margin-left":"0.5em"},
      chartData: _ctx.ChartData,
      options: _ctx.ChartOptions
    }, null, 8, ["onChartRender", "onChart:destroy", "onChartUpdate", "chartData", "options"])
  ]))
}