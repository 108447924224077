import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.MEMRead(_ctx.Res) ? _ctx.MEMWrite(_ctx.Res, false) : _ctx.MEMWrite(_ctx.Res, true))),
    class: "Container Pointer",
    style: {"user-select":"none","-webkit-user-select":"none"}
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["Stripe First", _ctx.MEMRead(_ctx.Res) ? 'Clicked' : ''])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["Stripe Second", _ctx.MEMRead(_ctx.Res) ? 'Clicked' : ''])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["Stripe Third", _ctx.MEMRead(_ctx.Res) ? 'Clicked' : ''])
    }, null, 2)
  ]))
}