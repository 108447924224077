
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {},
  data () {
    return {}
  },
  computed: {
    LanguagesArray () {
      var computedArray :Array<any> = []
      const _this = this
      Object.keys(this.Languages).some(function (Lang) {
        computedArray.push({
          Name: _this.Languages[Lang].Name,
          Code: Lang
        })
      })
      return computedArray
    }
  },
  // ---------------------------------------------------
  methods: {
    SaveLang (lang :any) {
      this.LocalSave('Language', lang.Code)
      this.$store.state.CookiesConfig.Language = lang.Code
      this.$router.go(-1)
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
