<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody color2">
  <div class="M-Auto DialogContainer">
    <div class="DialogBody D-Flex">
      <div class="CookiesRow W-100 F1_5em">
        <div class="CookiesRow W-100 ErrorsContiner">
          <div class=" M-Auto F_75em W-100">
            <img style="width:3em;" class="M-Auto" src="@/assets/icons/error.svg">
          </div>
          <span class=" M-Auto F1em W-100 F-Bold color2" style="padding:10px">{{DialogData.CookiesError}} </span>
          <span v-if="DialogData.status !== undefined & DialogData.status !== 200 & DialogData.status !== ''" class="color2 M-Auto F1em W-100 F-Bold" style="padding:10px">{{DialogData.status}} </span>
          <span v-if="DialogData.Description !== undefined & DialogData.Description !== ''" class="color2 M-Auto F_75em W-100" style="padding:10px"><strong>({{DialogData.Description}})</strong></span>
          <span v-if="DialogData.url !== undefined & DialogData.url !== ''" class="color2 M-Auto F_75em W-100" style="padding:10px">{{DialogData.url}}</span>
          <span v-if="DialogData.statusText !== undefined & DialogData.statusText !== 'OK'" class="color2 M-Auto F_75em W-100" style="padding:10px"><strong>({{DialogData.statusText}})</strong></span>
          <span v-if="DialogData.toString() === 'TypeError: Failed to fetch'" class="color2 M-Auto F_75em W-100" style="padding:10px"><strong>TypeError: Failed to fetch</strong></span>
          <span v-if="typeof(DialogData) == 'String'" class="color2 M-Auto F_75em W-100" style="padding:10px"><strong>{{DialogData}}</strong></span>
        </div>
        <div class="W-100" style="height:20px;"></div>
        <div class="M-Auto F-Bold">
          <button @click="CloseErrorDialog(DialogIndex)" :style="'background: ' + Theme.Shade3" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Close', 'اغلاق')}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {}
}
</script>
<style scoped>
.ErrorsContiner{
  overflow-wrap: normal;
}
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesDialogdBody{
  margin-bottom: 0px;
}
</style>
