<template>
<div v-if="Access('StockView')" class="CookiesMainView">
  <div class="BigPlaceholder P-Rel">
    <!-- Filter Block ------------------------------------------------------------------------------- -->
    <CookiesFilterPannel @CloseMe="ShowFilter = false" :Lists="FilterProps.Lists" :Checks="FilterProps.Checks" :Switches="FilterProps.Switches" :Dates="false" :ShowFilter="ShowFilter"></CookiesFilterPannel>
    <!-- Main Block ------------------------------------------------------------------------------- -->
    <div class="BigContainer">
      <div class="BigHeader O-754">
        <!-- Header ------------------------------------------------------------------------------- -->
        <div class="D-Flex StocksHeader" style="gap: 1em;">
          <div class="D-Flex">
            <CookiesIcon :ToolTip="LanguageSelector('Filter', 'فلتر')" class="FilterButton HoverUp" @click="ShowFilter = true" style="width: 2.2em; height: 2.2em; margin: auto 0.5em; padding: 0em 1em;" :ID="'Filter'"></CookiesIcon>
          </div>
          <span class="F-Bold F1_75em BigHeaderText">{{Translate('Warehouse')}}</span>
          <!-- <CookiesDropInputObject :PreserveMemory="true" :Res="'WarehousesFilter'" :Name="Translate('All Warehouses')" :Selectors="['Name']" :Options="Warehouses"></CookiesDropInputObject> -->
          <!-- <CookiesDropDown :Res="'WarehousesFilter_Value'" :Type="'Object'" :Width="'12em'" :Selectors="'Name'" :Options="Warehouses"></CookiesDropDown> -->
          <CookiesTabs @selected="ChangeView" class="M-Auto" :Router="false" :Default="{ Name: Translate('Products'), Value: 'Products' }" :Tabs="Tabs"></CookiesTabs>
          <button v-if="Access('StockAdd') && CurrentView === 'Products'" @click="NewProduct()" class="DialogHeaderButton BGMain F1_25em">+ {{Translate('New Product')}}</button>
          <button v-if="Access('StockTransfer') && CurrentView === 'Transfers'" @click="NewTransfer()" class="DialogHeaderButton BGMain F1_25em">+ {{Translate('New Transfer')}}</button>
          <button v-if="Access('WarehouseAdd') && CurrentView === 'Warehouses'" @click="NewWarehouse()" class="DialogHeaderButton BGMain F1_25em">+ {{Translate('New Warehouse')}}</button>
        </div>
        <div class="M-Auto"></div> <!-- Space Filler -->
        <!-- Search Block ------------------------------------------------------------------------------- -->
        <div class="D-Flex CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
        </div>
      </div>
        <!-- Main Area Block ------------------------------------------------------------------------------- -->
          <!-- Loading Indicatr ------------------------------------------------------------------------------- -->
        <div v-if="Loading.length > 0" class="D-Flex M-Auto W-100 P-Abs BGShade1 Shadow1" style="justify-content: center; align-items: center; min-width: 100%;">
          <div class="D-Flex" style="min-height:2.7em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
        </div>
        <div class="BigBody P-Rel">
          <div v-if="CurrentView === 'Products'" class="D-Flex W-100 FlexWrap ItemsHolder">
            <StockCard class="CookiesFade" v-for="Product in FilterdProducts" :key="Product" :Product="Product" :DecodedUnits="WareHouseUnits" :Buttons="[{Icon: 'Edit', EventName: 'EditProduct'}, {Icon: 'Delete', EventName: 'RemoveProduct', Disabled: DeepFetcher(WareHouseUnits, ['Products', 'ID_' + Product.ID]) ? true : false}]" @EditProduct="EditProduct" @RemoveProduct="RemoveProduct"></StockCard>
          </div>
          <div v-if="CurrentView === 'Transfers'" class="W-100" style="overflow-y: scroll;">
            <table class="W-100 CookiesGeneralTable" CELLSPACING=0>
              <tr class="CookiesFade">
                <th></th>
                <th>ID</th>
                <CookiesSortingTH @click="SortTable(['Date'], 'sortTransferElment')" :Name="Translate('Date')" :Value="['Date']" :sortColumn="sortTransferElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['WarehouseName'], 'sortTransferElment')" :Name="Translate('Warehouse')" :Value="['WarehouseName']" :sortColumn="sortTransferElment" ></CookiesSortingTH>
                <CookiesSortingTH :Sortable="false" :Name="Translate('Products')" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['Description'], 'sortTransferElment')" :Name="Translate('Description')" :Value="['Description']" :sortColumn="sortTransferElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['Type'], 'sortTransferElment')" :Name="Translate('Type')" :Value="['Type']" :sortColumn="sortTransferElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['IsExecuted'], 'sortTransferElment')" :Name="Translate('Status')" :Value="['IsExecuted']" :sortColumn="sortTransferElment" ></CookiesSortingTH>
                <th></th>
              </tr>
                <tr class="CookiesFade" v-if="!Loading & FilterdTransfers.length === 0"><td colspan="20" style="padding: 1.5em 0.2em;">{{Translate('No Data')}}</td></tr>
                <template v-for="(transfer, index) in FilterdTransfers" :key="(transfer, index)">
                  <tr class="CookiesGeneralTableRow CookiesFade">
                    <td class="P1em">{{index + 1}}</td>
                    <td>{{DeepFetcher(transfer, 'ID')}}</td>
                    <td>{{JsonDateParser(DeepFetcher(transfer, 'Date'))}} - {{JsonTimeParser(DeepFetcher(transfer, 'Date'))}}</td>
                    <td>{{DeepFetcher(transfer, 'WarehouseName')}}</td>
                    <td style="text-align: -webkit-center;">
                      <tr class="W-100 text-center" style="text-align: -webkit-center;" v-for="(unit, i) in transfer.Units" :key="(unit, i)">
                        <td style="padding: 0.2em"><strong>{{DeepFetcher(unit, ['ProductName'])}}</strong> ({{DeepFetcher(unit, ['Name'])}})</td>
                        <td style="padding: 0.2em;" class="D-Flex F-Bold"><div style="padding: 0 0.2em;"> X </div><div style="text-align: end;">{{DeepFetcher(unit, ['Quantity'])}}</div></td>
                      </tr>
                    </td>
                    <td>{{DeepFetcher(transfer, ServerDescription)}}</td>
                    <td><div class="D-Flex W-100"><span class="M-Auto">{{Translate(DeepFetcher(transfer, 'Type'))}}</span><CookiesUpDownArrow style="align-self: flex-end;" :NoColor="true" :Direction="transfer.Type === 'Export' ? 'up': transfer.Type === 'Import' ? 'down' : ''"></CookiesUpDownArrow></div></td>
                    <td><span @click="ChangeStatus(transfer)" class="ActiveButton F-Bold" :class="[Access('StockEdit') ? 'Pointer' : '', transfer['IsExecuted'] ? 'BGGreen' : 'BGYellow']" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;">{{transfer['IsExecuted'] ? Translate('Done'): Translate('Pending')}}</span></td>
                    <td style="padding: 0.6em 0.4em">
                      <CookiesIcon  :ToolTip="Translate('Done')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !transfer.IsExecuted" @click="CloseTransfer(transfer)" :ID="'Done'"></CookiesIcon>
                      <CookiesIcon  :ToolTip="Translate('Edit')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !transfer.IsExecuted" @click="EditTransfer(transfer)" :ID="'Edit'"></CookiesIcon>
                      <CookiesIcon  :ToolTip="Translate('Delete')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !transfer.IsExecuted" @click="RemoveTransfer(transfer)" :ID="'Delete'"></CookiesIcon>
                    </td>
                  </tr>
                </template>
            </table>
          </div>
          <div v-if="CurrentView === 'Warehouses'" class="W-100" style="overflow-y: scroll;">
            <table class="W-100 CookiesGeneralTable" CELLSPACING=0>
              <tr class="CookiesFade">
                <th></th>
                <th>ID</th>
                <CookiesSortingTH @click="SortTable(['Name'], 'sortWarehouseElment')" :Name="Translate('Warehouse')" :Value="['Name']" :sortColumn="sortWarehouseElment" ></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['NumberOfUnits'], 'sortWarehouseElment')" :Name="Translate('Products')" :Value="['NumberOfUnits']" :sortColumn="sortWarehouseElment"></CookiesSortingTH>
                <CookiesSortingTH @click="SortTable(['Description'], 'sortWarehouseElment')" :Name="Translate('Description')" :Value="['Description']" :sortColumn="sortWarehouseElment" ></CookiesSortingTH>
                <th></th>
              </tr>
                <tr class="CookiesFade" v-if="!Loading & FilterdWarehouses.length === 0"><td colspan="20" style="padding: 1.5em 0.2em;">{{Translate('No Data')}}</td></tr>
                <template v-for="(Warehouse, index) in FilterdWarehouses" :key="(Warehouse, index)">
                  <tr class="CookiesGeneralTableRow CookiesFade">
                    <td class="P1em">{{index + 1}}</td>
                    <td>{{DeepFetcher(Warehouse, 'ID')}}</td>
                    <td>{{DeepFetcher(Warehouse, 'Name')}}</td>
                    <td>{{DeepFetcher(Warehouse, 'NumberOfUnits')}}</td>
                    <td>{{DeepFetcher(Warehouse, 'Notes')}}</td>
                    <td style="padding: 0.6em 0.4em">
                      <CookiesIcon  :ToolTip="Translate('Edit')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !Warehouse.IsExecuted" @click="EditWarehouse(Warehouse)" :ID="'Edit'"></CookiesIcon>
                      <CookiesIcon  :ToolTip="Translate('Delete')" class="CookiesRowIcon HoverUp" v-if="Access('StockEdit') && !Warehouse.IsExecuted" @click="RemoveWarehouse(Warehouse)" :ID="'Delete'"></CookiesIcon>
                    </td>
                  </tr>
                </template>
            </table>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted () {
    if (!this.Access('StockView')) {
      if (this.Access('Driver')) {
        this.$router.replace('/DriverPortal')
      }
    }
    this.ReadProducts()
    this.ReadUnits()
    this.ReadWarehouses()
    this.ReadTransfers()
  },
  data () {
    return {
      ShowFilter: false,
      CurrentStockHover: '',
      BarCodeReader: '',
      ScanningSelect: false,
      CookiesDataStocks: [],
      CookiesDataArchivedStocks: [],
      Search: '',
      sortElment: {
        name: ['UPDDate'],
        value: -1
      },
      sortTransferElment: {
        name: ['UPDTime'],
        value: -1
      },
      sortWarehouseElment: {
        name: ['Name'],
        value: -1
      },
      WarehousesView: true,
      StocksHis: {},
      CurrentPage: 1,
      StocksPerPage: 100,
      Loading: [],
      ArchivedStocks: false,
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To,
      CurrentView: 'Products',
      Products: [],
      Warehouses: [],
      Branches: [],
      DecodedWarehouses: {},
      AllUnits: [],
      Transfers: []
    }
  },
  computed: {
    FilterProps () {
      var FilterLists = []
      var FilterChecks = []
      if (this.CurrentView !== 'Warehouses') {
        FilterLists.push(
          {
            Visible: this.Access('StockView'),
            Res: 'WarehousesFilter',
            Selectors: ['Name'],
            Name: this.Translate('Warehouse'),
            Options: this.Warehouses
          }
        )
      }
      this.Console('StatusMapper')
      this.Console(this.StatusMapper('', 'objlist'))
      this.StatusMapper('', 'objlist').forEach(element => {
        if (!(['DriverPaid', 'CustomerPaid'].indexOf(element.value) >= 0) && (['Done', 'Closed'].indexOf(element.value) >= 0 ? this.ArchivedStocks : !this.ArchivedStocks)) {
          element.Visible = true
        } else {
          element.Visible = false
        }
        element.Name = element[this.LanguageSelector('ENG', 'ARA')]
        element.Icon = element.value
        element.Res = 'StockFilter_' + element.value
        FilterChecks.push(element)
      })
      var FilterSwitches = [
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Only with issue', 'فقط مع تنبيهات'),
          Icon: 'HasIssue',
          Res: 'StockFilter_HasIssue'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Only assigned', 'فقط مع سائق'),
          Icon: 'Drivers',
          Res: 'StockFilter_Assigned'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Driver Paid', 'تم محاسبة السائق'),
          Icon: 'DriverPaid',
          Res: 'StockFilter_DriverPaid'
        },
        {
          Visible: !this.ArchivedStocks,
          Name: this.LanguageSelector('Customer Paid', 'تم محاسبة العميل'),
          Icon: 'CustomerPaid',
          Res: 'StockFilter_CustomerPaid'
        }
      ]
      return {
        Lists: FilterLists,
        Checks: FilterChecks,
        Switches: FilterSwitches
      }
    },
    FilterdProducts () {
      var FilteredArray = []
      this.Products.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.HashedID = '#' + item.ID
        var FilterIt = false
        try {
          if (this.MEMRead('WarehousesFilter_Value').Name.trim() !== this.Translate('All').trim()) {
            if (this.WareHouseUnits.Products['ID_' + item.ID] === undefined) {
              FilterIt = true
            }
          }
        } catch (error) {
        }
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && !FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    FilterdTransfers () {
      var FilteredArray = []
      this.sortedTransfers.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.HashedID = '#' + item.ID
        item.WarehouseName = this.DeepFetcher(this.DecodedWarehouses['Warehouse_' + item.ID], 'Name')
        var FilterIt = false
        try {
          if (this.MEMRead('WarehousesFilter_Value').Name.trim() !== this.Translate('All').trim()) {
            if (this.WareHouseUnits.Products['ID_' + item.ID] === undefined) {
              FilterIt = true
            }
          }
        } catch (error) {
        }
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && !FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    FilterdWarehouses () {
      var FilteredArray = []
      this.sortedWarehouses.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.HashedID = '#' + item.ID
        item.WarehouseName = this.DeepFetcher(this.DecodedWarehouses['Warehouse_' + item.ID], 'Name')
        item.NumberOfUnits = this.DeepFetcher(this.DecodedUnits, ['Warehouses', 'ID_' + item.ID, 'TotalUnits'])
        var FilterIt = false
        try {
          if (this.MEMRead('WarehousesFilter_Value').Name.trim() !== this.Translate('All').trim()) {
            if (this.WareHouseUnits.Products['ID_' + item.ID] === undefined) {
              FilterIt = true
            }
          }
        } catch (error) {
        }
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && !FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedStocks () {
      var Sorted = []
      if (!this.ArchivedStocks) {
        Sorted = this.CookiesDataStocks
      } else {
        Sorted = this.CookiesDataArchivedStocks
      }
      return Sorted.sort(this.Sorting)
    },
    sortedTransfers () {
      var Sorted = this.Transfers
      return Sorted.sort(this.SortingTransfers)
    },
    sortedWarehouses () {
      var Sorted = this.Warehouses
      return Sorted.sort(this.SortingWarehouses)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    },
    DecodedUnits () {
      var WarehousesUnits = {}
      var CatalogIDUnits = {}
      var ProductUnits = {}
      this.Warehouses.forEach(warehouse => {
        WarehousesUnits['ID_' + warehouse.ID] = {
          TotalUnits: 0,
          Products: {},
          UnitCatalogs: {}
        }
      })
      try {
        this.AllUnits.forEach(unit => {
          if (CatalogIDUnits['ID_' + unit.UnitCatalogID] === undefined) {
            CatalogIDUnits['ID_' + unit.UnitCatalogID] = 0
          }
          if (ProductUnits['ID_' + unit.ProductID] === undefined) {
            ProductUnits['ID_' + unit.ProductID] = 0
          }
          if (WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] === undefined) {
            WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] = 0
          }
          if (WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] === undefined) {
            WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] = 0
          }
          CatalogIDUnits['ID_' + unit.UnitCatalogID] += unit.Quantity
          ProductUnits['ID_' + unit.ProductID] += unit.Quantity
          WarehousesUnits['ID_' + unit.WarehouseID].UnitCatalogs['ID_' + unit.UnitCatalogID] += unit.Quantity
          WarehousesUnits['ID_' + unit.WarehouseID].Products['ID_' + unit.ProductID] += unit.Quantity
          WarehousesUnits['ID_' + unit.WarehouseID].TotalUnits += unit.Quantity
        })
      } catch (error) {
      }
      return {
        UnitCatalogs: CatalogIDUnits,
        Products: ProductUnits,
        Warehouses: WarehousesUnits
      }
    },
    WareHouseUnits () {
      this.Console('this.MEMRead(WarehousesFilter_Value)')
      this.Console(this.MEMRead('WarehousesFilter_Value'))
      this.Console('this.DecodedUnits')
      this.Console(this.DecodedUnits)
      if (this.MEMRead('WarehousesFilter_Value') === undefined) {
        return this.DecodedUnits
      } else {
        return this.DecodedUnits.Warehouses['ID_' + this.MEMRead(['WarehousesFilter_Value', 'ID'])]
      }
    },
    Tabs () {
      return [
        { Name: this.Translate('Products'), Value: 'Products' },
        { Name: this.Translate('Transfers'), Value: 'Transfers' },
        { Name: this.Translate('Warehouses'), Value: 'Warehouses' }
      ]
    }
  },
  methods: {
    ReadProducts () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadProducts']).then(response => {
        this.Products = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Stocks'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadUnits () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadUnits']).then(response => {
        this.AllUnits = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Stocks'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadWarehouses () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadWarehouses']).then(response => {
        this.Warehouses = response
        response.forEach(warehouse => {
          this.DecodedWarehouses['Warehouse_' + warehouse.ID] = warehouse
        })
        this.Console(this.DecodedWarehouses)
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransfers () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get(['ReadTransfers']).then(response => {
        this.Transfers = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewProduct () {
      if (!this.Access('StockAdd')) {
        return
      }
      this.OpenDialog('Inventory_Product_Dialog', this.Translate('New Product'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewTransfer () {
      if (!this.Access('StockTransfer')) {
        return
      }
      this.OpenDialog('Inventory_Transfers_Dialog', this.Translate('New Transfer'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewWarehouse () {
      if (!this.Access('WarehouseAdd')) {
        return
      }
      this.OpenDialog('Inventory_Warehouses_Dialog', this.Translate('New Warehouse'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditTransfer (Transfer) {
      if (!this.Access('StockTransfer')) {
        return
      }
      this.OpenDialog('Inventory_Transfers_Dialog', this.Translate('Edit Transfer') + ' : ' + '#' + Transfer.ID, { Data: Transfer, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditProduct (Stock) {
      if (!this.Access('StockEdit')) {
        return
      }
      this.OpenDialog('Inventory_Product_Dialog', this.Translate('Edit Product') + ' : ' + '#' + Stock.ID, { Data: Stock, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditWarehouse (Warehouse) {
      if (!this.Access('WarehouseEdit')) {
        return
      }
      this.OpenDialog('Inventory_Warehouses_Dialog', this.Translate('Edit Warehouse') + ' : ' + '#' + Warehouse.Name, { Data: Warehouse, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Recover (Stock) {
      if (!this.Access('StockEdit')) {
        return
      }
      this.Console(Stock)
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortingTransfers (a, b) {
      var SortElement = this.sortTransferElment.name
      var SortDirection = this.sortTransferElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortingWarehouses (a, b) {
      var SortElement = this.sortWarehouseElment.name
      var SortDirection = this.sortWarehouseElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    StockHisSorting (a, b) {
      var SortElement = ['Date']
      var SortDirection = 1
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortStocks (name) {
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        }
      } catch (error) {
      }
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    RemoveProduct (Product) {
      this.OpenDialog('Delete_Dialog', this.Translate('Deleting Product'), [['Products/Delete', '?Id=' + Product.ID], Product.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveTransfer (Transfer) {
      this.OpenDialog('Delete_Dialog', this.Translate('Cancelling Transfer'), [['RemoveTransfer', '?Id=' + Transfer.ID], this.Translate('Transfer') + ' : ' + '#' + Transfer.ID]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveWarehouse (Warehouse) {
      this.OpenDialog('Delete_Dialog', this.Translate('Removing Warehouse'), [['StockTransactions/Delete', '?Id=' + Warehouse.ID], this.Translate('Warehouse') + ' : ' + Warehouse.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintBadge (Stock) {
      this.OpenDialog('StockBagde', this.LanguageSelector('', ''), Stock, { header: false, background: 'none' }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    BarCodeKeyPressed () {
      if ((event.which) === 13 & this.BarCode !== '') {
        var ReadNow = this.ObjectFinder(this.CookiesDataStocks, ['BarCode'], this.BarCodeReader.toString())
        if (ReadNow !== undefined) {
          this.$store.state.CookiesMemory['O_' + ReadNow.ID + '_Select'] = true
          this.BarCodeReader = ''
        } else {
          this.BarCodeReader = ''
        }
      }
    },
    BarCodeClicked () {
      this.BarCodeReader = ''
    },
    BarCodeBlurred () {
      this.$refs.BarCodeReader.focus()
    },
    OpenSelectScanner () {
      this.ScanningSelect = true
      setTimeout(() => {
        this.$refs.BarCodeReader.focus()
      }, 250)
      this.$refs.BarCodeReader.focus()
    },
    AllFilterSelector (value) {
      this.StatusMapper('', 'list').forEach(status => {
        ['Done', 'Closed', 'DriverPaid', 'CustomerPaid'].indexOf(status) >= 0 ? this.ArchivedStocks && (this.$store.state.CookiesMemory['StockFilter_' + status] = value) : !this.ArchivedStocks && (this.$store.state.CookiesMemory['StockFilter_' + status] = value)
      })
    },
    ChangePage (PageNumber) {
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            this.CurrentPage = PageNumber
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        } else {
          this.CurrentPage = PageNumber
        }
      } catch (error) {
        this.CurrentPage = PageNumber
      }
    },
    ChangeView (View) {
      this.CurrentView = View.Value
    },
    SortTable (name, element) {
      if (JSON.stringify(this[element].name) === JSON.stringify(name)) {
        this[element].value = this[element].value * -1
      } else {
        this[element].name = name
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadProducts()
      }
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85em;
  height: 80vh;
}
.CookiesMainView{
  padding-top: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
}
.StatusBar{
  top: 5.2em;
}
@media (max-width: 500px) {
  .BigHeader{
    font-size: 1.6em !important;
  }
  .StatusBar{
    font-size: 1em !important;
  }
  .StatusBar{
    top: 18.5em;
  }
  .BigPlaceholder{
    font-size: 0.7vw;
  }
}
@media (max-width: 2000px) {
  .BigPlaceholder{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder{
    font-size: 0.7vw;
  }
}
.FilterButton{
  display: none;
}
@media (max-width: 800px){
  .FilterButton{
    display: block;
  }
  .BigPlaceholder{
    font-size: 3vw;
  }
  .StocksHeader{
    font-size: 3vw;
    width: 100%;
    margin-top: 0.5em;
  }
  .CookiesSearchInputContainer{
    font-size: 3vw;
  }
}

.ItemsHolder{
  justify-content: center;
  width: 100%;
  gap: 1em;
  padding: 0.6em;
  overflow-y: scroll;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Item */

</style>
