
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  setup () {},
  components: {},
  name: 'Test',
  created () {},
  data () {
    return {
      Saving: false,
      Loading: [],
      NewPassWord: '',
      ConfirmNewPassWord: '',
      ErrorsVisibilty: false,
      DialogMapper: [
        { Path: 'Email', Value: this.DialogData },
        { Path: 'Password', Res: 'D_ChangePassword' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ChangePassword () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Security_ChangePassword'
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  }
})
