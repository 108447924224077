
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    this.ReadCookiesData()
  },
  data () {
    return {
      CookiesData: [] as Array<InventoryTransfer>,
      Loading: [] as Array<string>,
      Categories: [] as Array<Category>,
      Brands: [] as Array<Category>,
      Warehouses: [] as Array<Warehouse>,
      InfiniteScroller: true
    }
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'R_InventoryExports_Table',
        ResID: 'ID',
        MultiLevel: true,
        InfiniteScroller: this.InfiniteScroller,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: ['Name'],
            Sort: ['Name'],
            IsSearchable: true
          },
          {
            Name: 'Amount',
            Type: 'normal',
            Value: ['Amount'],
            Sort: ['Amount'],
            Bold: true,
            Total: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectMemory: true,
        PrintControls: true,
        TableSelectDefaults: true,
        DefaultSort: {
          Direction: -1,
          Path: 'Amount'
        }
      } as CookiesTable
      return Table
    },
    DecodedSpecs () {
      var SpecsMap :any = []
      this.CookiesData.forEach((item :any) => {
        if (!item.Specifications) return
        item.Specifications.forEach((spec :any) => {
          var ModifiedName = spec.Name
          ModifiedName = ModifiedName.replace(' ', '')
          ModifiedName = ModifiedName.replace('&nbsp;', '').trim()
          if (ModifiedName.indexOf('ال') === 0) {
            ModifiedName = ModifiedName.replace('ال', '')
          }
          var SpecFound = SpecsMap.find((MappedSpec :any) => {
            return MappedSpec.Name === ModifiedName
          })
          if (!SpecFound) {
            console.log()
            SpecsMap[ModifiedName] = []
            SpecsMap.push({
              Name: ModifiedName,
              Description: [
                spec.Description
              ]
            })
          } else {
            var DesFound = SpecFound.Description.find((description :string) => {
              return description === spec.Description
            })
            if (!DesFound) {
              SpecFound.Description.push(spec.Description)
            }
          }
        })
      })
      return SpecsMap
    },
    FilteredData () {
      var Search = this.MEMRead('R_Items_Search')
      var Brand = this.MEMRead('R_Items_Brand_Value')
      var Category = this.MEMRead('R_Items_Category_Value')
      return this.CookiesData.filter((element :any) => {
        if (this.MEMRead('R_Items_AvailableOnly') && element.AvailableQuantity === 0) return false
        if (Brand && Brand !== '' && Brand.ID && element.BrandID !== Brand.ID) return false
        if (Category && Category !== '' && Category.ID && element.CategoryID !== Category.ID) return false
        var Searched = this.CookiesSearcher(element, Search)
        return Searched
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.ReadWarehouses()
      this.ReadCategories()
      this.ReadBrands()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Transfers_Reports_CategoriesExports', `?${this.DateAPIURL()}` + (this.MEMRead('CurrentWarehouse') ? '&WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')).then(response => {
        this.CookiesData = response?.Result
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadWarehouses () {
      this.Loading.push('Loading')
      this.Get('Inventory_Warehouses').then(response => {
        this.Warehouses = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      this.InfiniteScroller = false
      setTimeout(() => {
        var bodd = document.getElementById('TableToPrint')
        if (!bodd) return
        this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
      }, 200)
      setTimeout(() => {
        this.InfiniteScroller = true
      }, 500)
    },
    DownloadExcel () {
      var DatatoExport :any = this.MEMRead(this.TableSettings.CookiesID).map((element :any) => {
        var addedspecs :any = {}
        // if (element.Specifications && element.Specifications[0] && element.Specifications[0].Name) addedspecs[element.Specifications[0].Name] = element.Specifications[0].Description
        // if (element.Specifications && element.Specifications[1] && element.Specifications[1].Name) addedspecs[element.Specifications[1].Name] = element.Specifications[1].Description
        return {
          ID: element.ID,
          AddedDate: this.DateFormatter(element.AddedDate),
          LastEdit: this.DateFormatter(element.UPDTime),
          Code: element.Code,
          PackSize: element.PackSize,
          CategoryName: element.CategoryName,
          BrandName: element.BrandName,
          ItemName: element.Name,
          ...addedspecs,
          QuantityMax: element.QuantityMax,
          AvailableQuantity: element.AvailableQuantity
        }
      })
      var Total = 0
      DatatoExport.forEach((element :any) => {
        Total += element.AvailableQuantity
      })
      DatatoExport.push({ ID: 'Total', AvailableQuantity: Total })
      this.ExportFromJSON(DatatoExport, 'Items Report')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
    }
  }
})
