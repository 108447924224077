
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      OriginalData: {} as Section,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Branches: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Code', Res: 'D_Section_Code', Default: '' },
        { Path: 'Capacity', Res: 'D_Section_Capacity', Default: 0 },
        { Path: 'Volume', Res: 'D_Section_Volume', Default: 0 },
        { Path: 'Name', Res: 'D_Section_Name', Default: '' },
        { Path: 'WarehouseID', Value: this.DialogData.WarehouseID },
        { Path: 'SectionLevel1ID', Value: this.DialogData.SectionLevel1ID },
        { Path: 'SectionLevel2ID', Value: this.DialogData.SectionLevel2ID },
        { Path: 'Description', Res: 'D_Section_Description', Default: '' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      // this.ReadWarehouse()
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Inventory_Sections',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Sections',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?ID=' + this.DialogData.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  created () {
  },
  mounted () {
    if (this.DialogData.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data) as Section
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Section')
  },
  computed: {}
})
