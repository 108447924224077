
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Default: { default: '', type: String },
    type: { default: 'text', type: String },
    Res: { type: String },
    ErrorData: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Placeholder: { default: '', type: String },
    Name: { default: '', type: String }
  },
  data () {
    return {
      Search: ''
    }
  },
  mounted () {
    this.Search = this.Default
    this.MEMWrite(this.Res, this.Default)
  },
  methods: {
    SearchChanged () {
      this.MEMWrite(this.Res, this.Search)
      this.$emit('changed')
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1b4a09ae": (_ctx.$store.getters.DefaultTextAlign),
  "5eea8bd8": (_ctx.Theme.color2),
  "2cbfef01": (_ctx.Theme.UserColors.third_50),
  "5eea8bd6": (_ctx.Theme.color3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__